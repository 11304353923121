import React from "react";
import "./loader.scss";
import MoonLoader from "react-spinners/MoonLoader";
import PropTypes from "prop-types";
import clsx from "clsx";

// Default values for the Loader props
const defaults = {
    size: 150,
    color: "#63c19f",
};

const Loader = (props) => {
    return (
        <div className={clsx("loader-container", props.isInline && "inline")}>
            {props.text ? (
                <span>
                    <h3 className="fw-bold px-4 pb-2 text-center loading-text">{props.text}</h3>
                </span>
            ) : null}

            <MoonLoader
                data-cy="loader"
                color={props.isInline ? "white" : defaults.color}
                loading={props.loading}
                size={props.size || defaults.size}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div>
    );
};

Loader.propTypes = {
    text: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    size: PropTypes.number,
    color: PropTypes.string,
    isInline: PropTypes.bool,
};

export default Loader;
