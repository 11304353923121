import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

/**
 * Reddit Pixel header code
 *
 * @returns {JSX.Element}
 */
const RedditPixel = (
    <script>
        {`!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','a2_enj6oiwwrpp8');rdt('track', 'PageVisit');`}
    </script>
);

const MetaPixel = ({ isUserRegistered = false }) => {
    return (
        <Helmet>
            <script>
                {`!function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '339133702296501');
                    fbq('track', 'PageView');`}
            </script>

            {isUserRegistered && <script>{`fbq('track', 'Register');`}</script>}

            <noscript>
                {`<img 
                    height="1" 
                    width="1" 
                    style="display:none"
                    src="https://www.facebook.com/tr?id=339133702296501&ev=PageView&noscript=1"
                />`}
            </noscript>

            {RedditPixel}

            <link
                rel="canonical"
                href={`https://www.priceme.game${window.location.pathname === "/" ? "" : window.location.pathname}`}
            />
        </Helmet>
    );
};

MetaPixel.propTypes = {
    isUserRegistered: PropTypes.bool,
};

export default MetaPixel;
