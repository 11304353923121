var priceTagIcon = require("../assets/icons-v2/price-tag-icon.png");
var challengedIcon = require("../assets/icons-v2/challenged.png");
var unlockIcon = require("../assets/icons-v2/padlock.png");
var graphIcon = require("../assets/icons-v2/ranking-graph-icon.png");

module.exports = {
    STARTUP_MODAL_CONTENTS: [
        {
            header: "Let’s get started",
            text: "Choose your location and guess the price of 3 properties.",
            icon: priceTagIcon,
            alt: "price-tag-icon",
        },
        {
            header: "See where you rank",
            text: "Check your score on the leaderboard. Every day is a new chance to improve your rank.",
            icon: graphIcon,
            alt: "graph-icon",
        },
        {
            header: "Challenge a friend",
            text: `After each property, you can challenge friends to beat your score. Tap to share by text, DM, or to the <a href="https://www.facebook.com/groups/pricemegame" style="color: var(--purple-heart) !important;" title="Priceme Facebook Group" target="_blank" rel="noreferrer">Facebook group.</a>`,
            icon: challengedIcon,
            alt: "challenged-icon",
        },
        {
            header: "Unlock bonus properties",
            text: "Whoever wins the challenge unlocks a bonus property. Unlock up to 3 extra properties each day.",
            icon: unlockIcon,
            alt: "unlock-icon",
        },
    ],
};
