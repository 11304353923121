import { get, isNil, isNull } from "lodash";
import PropTypes from "prop-types";
import { hasSelectLocationTimestampToday } from "../helpers/helpers";
import { getDailyProperties, getUserGuessesPerPeriod } from "../services/UserService";

/**
 * Checks if the user has made a daily guess.
 *
 * This function retrieves the user's guesses for the current period and checks if there is at least one daily guess.
 *
 * @param {string} userId - The ID of the user.
 *
 * @returns {Promise<boolean>} A promise that resolves to `true` if the user has made a daily guess, `false` otherwise.
 */
const hasDailyUserGuess = async (userId) => {
    const userGuesses = await getUserGuessesPerPeriod({
        user_id: userId,
    });

    return get(userGuesses, ["data", "count", "daily"]) !== 0;
};

/**
 * Checks if the user already has daily properties.
 *
 * This function retrieves the user's daily properties and checks if there is at least one daily property.
 *
 * @param {string} userId - The ID of the user.
 *
 * @returns {Promise<boolean>} A promise that resolves to `true` if the user has daily properties, `false` otherwise.
 */
const hasDailyPropertiesAlready = async (userId) => {
    const userProperties = await getDailyProperties(
        !isNil(userId) && !isNull(userId) ? userId : "null",
        true
    );

    return get(userProperties, ["data", "properties"]).length !== 0;
};

const HasSelectedLocation = async (isAuthenticated, userContext) => {
    const { userState } = userContext;

    // No need to check if there's a selected location for guest users
    if (!isAuthenticated) {
        return true;
    }

    // If there is no saved user state, return false
    if (isNil(userState) || isNull(userState)) {
        return false;
    }

    const hasDailyGuess = await hasDailyUserGuess(userState?.id);
    const hasUserProperty = await hasDailyPropertiesAlready(userState?.id);

    // Check the user already has guessed for the daily properties OR they already have saved user properties for the day
    if (hasDailyGuess || hasUserProperty) {
        return true;
    }

    return hasSelectLocationTimestampToday();
};

HasSelectedLocation.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    userContext: PropTypes.object.isRequired,
};

export default HasSelectedLocation;
