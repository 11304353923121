import React from "react";
import "./bonus-earned.scss";
import Modal from "react-bootstrap/Modal";
import useModalState from "../../../hooks/useModalState";
import AppButton from "../../common/buttons/app-button";
import joinedFriendIcon from "../../../assets/icons-v2/joined-friend.png";
import joinedFriendsIcon from "../../../assets/icons-v2/joined-friends.png";
import { useReferralBonus } from "../../../context/ReferralBonusContext";
import { useUser } from "../../../context/UserContext";
import { useMutation } from "@tanstack/react-query";
import { updateReferredUsers } from "../../../services/ReferralService";
import { useAuth0 } from "@auth0/auth0-react";

const BonusEarnedModal = () => {
    const { isOpen, setModalId } = useModalState();
    const { referredUsers, refetchRefferedUsers, resetReferredUsers } = useReferralBonus();
    const headUser = referredUsers.data[0];

    const { userState } = useUser();
    const { isAuthenticated } = useAuth0();
    const referralIds = referredUsers.data?.map((el) => el.id);

    const updateUsers = useMutation({
        mutationFn: (referralIds) => updateReferredUsers({ referralIds, userId: userState?.id }),
        onSuccess: () => {
            // Reset referred users data
            resetReferredUsers();

            // Refetch referred users
            refetchRefferedUsers();

            setModalId("");
        },
    });

    const handleGetStarted = async () => {
        if (!isAuthenticated) return;

        updateUsers.mutate(referralIds);
    };

    return (
        <Modal
            show={isOpen("BONUS_EARNED_MODAL")}
            onHide={() => {
                setModalId("");
            }}
            backdrop="static"
            centered
            className="bonus-earned-modal"
        >
            <Modal.Body className="pb-0">
                <div>
                    <h5 className="mb-0 app-text-title text-center">
                        {referredUsers.data.length > 1
                            ? "New friends joined you on PriceMe!"
                            : "Your friend joined you on PriceMe!"}
                    </h5>
                    <div className="bonus-guess-icon py-2">
                        <img
                            src={referredUsers.data.length > 1 ? joinedFriendsIcon : joinedFriendIcon}
                            alt="bonus-guess-icon"
                        />
                    </div>
                    {referredUsers.data.length > 1 ? (
                        <p className="mb-0">
                            {headUser?.referred_user_nickname} and {referredUsers.data.length - 1} others just
                            signed up for PriceMe - all thanks to you 🥰 That means you get a 50pt bonus guess
                            today and the next {referredUsers.data.length - 1} times you play. Thanks for
                            spreading the word!
                        </p>
                    ) : (
                        <p className="mb-0">
                            {headUser?.referred_user_nickname} just signed up for PriceMe - all thanks to you
                            🥰 That means you’ve earned a bonus guess - up to 50pts - on today’s PriceMe!
                        </p>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer className="pb-4 pt-3">
                <AppButton onClick={handleGetStarted} className="w-75 mx-auto" variant="solid" theme="purple">
                    Get started
                </AppButton>
            </Modal.Footer>
        </Modal>
    );
};

export default BonusEarnedModal;
