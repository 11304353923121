import React from "react";
import "./footer.scss";

export default function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer text-center mx-auto">
            <div className="container">
                <div className="d-flex gap-2 flex-wrap mb-1">
                    <div className="privacy-policy">
                        <span className="text-muted">
                            <a href="/privacy-policy">Privacy Policy</a>
                        </span>
                    </div>

                    <div className="terms-of-service">
                        <span className="text-muted">
                            <a href="/terms-of-service">Terms of Service</a>
                        </span>
                    </div>

                    <div className="terms-of-service">
                        <span className="text-muted">
                            <a href="/credits">Credits</a>
                        </span>
                    </div>
                </div>

                <div>
                    <div>
                        <span className="text-muted ps-2">© PriceMe {currentYear}. All rights reserved.</span>
                    </div>
                </div>
            </div>
        </footer>
    );
}
