import React from "react";
import "./not-found.scss";
import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <div className="not-found">
            <h2 className="title">404 Not Found</h2>
            <p className="message">The page you are looking for does not exist.</p>
            <Link to="/">Return to home</Link>
        </div>
    );
};

export default NotFound;
