import React from "react";
import "./switch.scss";

import PropTypes from "prop-types";

/**
 * @param {boolean} checked - Sets the checked state of the switch.
 * @param {func} onChange - Function to change the checked state of the switch.
 */

function Switch({ checked = false, onChange = () => {} }) {
    return (
        <label className="toggle-switch">
            <input type="checkbox" checked={checked} onChange={onChange} />
            <span className="switch" />
        </label>
    );
}

Switch.propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default Switch;
