import React, { createContext, useState, useContext } from "react";
import { getUserScore } from "../services/UserService";
import { useGuestContext } from "./GuestContext";

const ScoreContext = createContext();

export const ScoreProvider = ({ children }) => {
    const [totalScore, setTotalScore] = useState(0);
    const [scores, setScores] = useState([]);
    const { totalGuestPlayScoreDaily } = useGuestContext();
    const [hasFetchedDataState, setHasFetchedDataState] = useState(false);

    let hasFetchedData = false;

    /**
     * Add the given score to the total score and update the state.
     *
     * @param {number} addedScore - The score to be added to the total score.
     */
    const addTotalScore = (addedScore) => {
        setTotalScore((prevTotalScore) => prevTotalScore + addedScore);
    };

    /**
     * Fetches the daily score for a user, considering authentication status and area information.
     *
     * @param {boolean} isAuthenticated - Indicates whether the user is authenticated.
     * @param {string} userId - The ID of the user for whom to fetch the daily score.
     * @param {string} areaId - The ID of the area for which to fetch the daily score.
     *
     * @returns {Promise<void>} - Resolves once the user's daily score is fetched and processed.
     *
     * Note: The function ensures that data is fetched only once to avoid redundant API calls.
     *
     * @throws {Error} Throws an error if there's an issue fetching the user score.
     */
    const fetchUserDailyScore = async (isAuthenticated, userId, areaId) => {
        if (!hasFetchedData) {
            try {
                hasFetchedData = true;

                if (isAuthenticated) {
                    const { daily_score } = await getUserScore(userId, areaId, true);

                    setScores(daily_score.scores);
                    setTotalScore(daily_score.total_score);
                } else {
                    setTotalScore(totalGuestPlayScoreDaily());
                }

                setHasFetchedDataState(true);
            } catch (error) {
                hasFetchedData = false;
                setHasFetchedDataState(false);

                // Handle error if the API call fails
                console.error("Error fetching user score:", error);
            }
        }
    };

    return (
        <ScoreContext.Provider
            value={{ hasFetchedDataState, totalScore, scores, addTotalScore, fetchUserDailyScore }}
        >
            {children}
        </ScoreContext.Provider>
    );
};

export const useScore = () => useContext(ScoreContext);
