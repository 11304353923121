import React from "react";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import { useUser } from "../../../context/UserContext";
import "./user-avatar.scss";
import useRegisterCtaModal from "../../../hooks/useRegisterCtaModal";
import useCustomLoginWithRedirect from "../../../hooks/useCustomLoginWithRedirect";

/**
 * UserAvatar component displays user's avatar or login prompt.
 * @param {Object} props - Component props.
 * @param {Function} props.onClickProfile - Function to handle profile click.
 * @returns {JSX.Element} UserAvatar component.
 */
const UserAvatar = ({ onClickProfile }) => {
    const { isAuthenticated, user } = useAuth0();
    const { userState } = useUser();
    const { handleRegister } = useRegisterCtaModal({ delay: null });
    const { customLoginWithRedirect } = useCustomLoginWithRedirect();

    let userInitials = "";

    // Calculate user initials for avatar
    if (isAuthenticated) {
        if (userState?.first_name && userState?.last_name) {
            userInitials = `${userState.first_name[0]}${userState.last_name[0]}`.toUpperCase();
        } else if (userState?.nickname) {
            userInitials = userState.nickname.slice(0, 2).toUpperCase();
        }
    }

    /**
     * Handles click on user profile section.
     */
    const handleUserProfileClicked = () => {
        if (isAuthenticated) {
            onClickProfile();
        } else {
            handleRegister(() =>
                customLoginWithRedirect({
                    authorizationParams: {
                        redirect_uri: window.location.origin + "/",
                    },
                })
            );
        }
    };

    return (
        <div className="user-login" onClick={handleUserProfileClicked}>
            {isAuthenticated ? (
                <>
                    {userState?.use_email_account_picture && user?.picture ? (
                        <img className="user-profile" src={user?.picture} alt="user-profile" />
                    ) : (
                        <div className="user-profile default-avatar">
                            <span>{userInitials}</span>
                        </div>
                    )}
                </>
            ) : (
                <span>Log in</span>
            )}
        </div>
    );
};

UserAvatar.propTypes = {
    onClickProfile: PropTypes.func.isRequired,
};

UserAvatar.defaultProps = {
    onClickProfile: () => {},
};

export default UserAvatar;
