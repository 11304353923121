import React, { useState, useEffect } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { Modal } from "react-bootstrap";
import "./register-reminder-modal.scss";
import RankIcon from "../../../assets/icons-v2/ranking-graph-icon.svg";
import AppButton from "../../common/buttons/app-button";
import { Link } from "react-router-dom";
import { XLg } from "react-bootstrap-icons";

const RegisterReminderModal = ({ open, handleClick, handleClose }) => {
    const [showModal] = useState(open);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const delay = 5000; // 5 seconds in milliseconds

        const showComponent = () => {
            setIsVisible(true);
        };

        const timeoutId = setTimeout(showComponent, delay);

        // Cleanup the timeout if the component is unmounted before it shows up
        return () => clearTimeout(timeoutId);
    }, []); // Empty dependency array ensures the effect runs only once on mount

    return (
        <Modal
            show={showModal && isVisible}
            onHide={handleClose}
            centered
            className="modal-container-reminder"
        >
            <XLg className="x-btn" onClick={handleClose} />
            <Modal.Body className="pt-3 pb-3 px-2">
                <div className="modal-headers mb-3">Want to guess homes near you?</div>
                <img className="rank-icon" src={RankIcon} alt="rank-icon" />
                <div className="modal-body px-2">
                    Choose your area, get notified when new properties arrive, and see where you rank when you
                    register
                </div>
                <AppButton className="register-button" theme="purple" onClick={handleClick}>
                    Register
                </AppButton>
                <div className="mt-3">
                    <Link className="close-btn" onClick={handleClose}>
                        Remind me later
                    </Link>
                </div>
            </Modal.Body>
        </Modal>
    );
};

RegisterReminderModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClick: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default RegisterReminderModal;
