import axios from "axios";

/**
 * Fetches awards for a user based on specified criteria.
 *
 * @param {Object} options - The options for fetching awards.
 * @param {string} options.date - The date filter for awards (optional).
 * @param {string} options.type - The type filter for awards (optional).
 * @param {string} options.userId - The ID of the user for whom to fetch awards.
 *
 * @returns {Promise<Array>} - A promise that resolves to an array of awards.
 *
 */
export const getAwards = async ({ type, userId, isViewed }) => {
    try {
        const query = new URLSearchParams({
            type,
            isViewed,
        }).toString();

        const awards = await axios.get(`/api/awards/users/${userId}?${query}`);
        return awards.data;
    } catch (error) {
        console.log(error);
    }
};

/**
 * Update the viewed status of a specific award.
 *
 * @param {Object} params - The parameters for the award update.
 * @param {string} params.awardId - The unique identifier of the award to be updated.
 * @param {boolean} params.isViewed - The new viewed status to be set for the award.
 * @returns {Promise<Object>} - A promise that resolves with the updated award data.
 */
export const updateAward = async ({ awardId, isViewed }) => {
    try {
        const award = await axios.put(`/api/awards/${awardId}`, { isViewed });
        return award.data;
    } catch (error) {
        console.log(error);
    }
};
