import axios from "axios";

/**
 * Handles fetching of activity feed scores
 *
 * @param {number} pageParam The current query page
 *
 * @returns {array} Returns the array of activity feed scores
 */
export const getActivityFeedScores = async ({ pageParam }) => {
    return (await axios.get(`/api/scores/activity-feed/all-scores?page=${pageParam ?? 1}&limit=30`))?.data;
};

/**
 * Handles fetching of top activity feed scores
 *
 * @returns {array} Returns the array of top activity feed scores
 */
export const getTopActivityFeedScores = async () => {
    return await axios.get("/api/scores/activity-feed/top-scores");
};

/**
 * Retrieves the total practice score for a guest user.
 * @param {string} userId - The ID of the guest user.
 * @returns {Promise<Object>} - A promise that resolves with an object containing the total score and completion status.
 */

export const getGuestPracticeScore = async ({ userId, showViewed = null }) => {
    return await axios.get(`/api/scores/guest-practice/scores/${userId}`, {
        params: {
            show_viewed: showViewed,
        },
    });
};

/**
 * Update guest practice scores for a user.
 * @param {Object} options - Options for updating guest practice scores.
 * @param {string} options.userId - The ID of the user whose scores are to be updated.
 * @param {Array<number>} options.scoreIds - An array of score IDs to be updated for the user.
 * @returns {Promise} A Promise representing the update operation.
 */
export const updateGuestPracticeScore = async ({ userId, scoreIds }) => {
    return await axios.put(`/api/scores/guest-practice/scores/${userId}`, {
        ids: scoreIds,
    });
};
