import React from "react";
import { useUser } from "./UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import { getReferralBonus, getReferredUsers } from "../services/ReferralService";

import { useQuery, useQueryClient } from "@tanstack/react-query";

export const ReferralBonusContext = React.createContext();

export const ReferralBonusProvider = ({ children }) => {
    const { userState, isLoading: isUseUserLoading } = useUser();
    const { isAuthenticated, isLoading: isAuth0Loading } = useAuth0();
    const queryClient = useQueryClient();

    // Function that handles fetching of referred users
    const referredUsersData = useQuery({
        queryKey: ["refferedUsersData", userState?.id, isUseUserLoading, isAuth0Loading],
        queryFn: () => getReferredUsers({ userId: userState?.id }),
        enabled: isAuthenticated && !isUseUserLoading && !isAuth0Loading,
    });

    // Function that handles fetching of referral bonus
    const referralBonusData = useQuery({
        queryKey: ["referralBonusData", userState?.id, isUseUserLoading, isAuth0Loading],
        queryFn: () => getReferralBonus({ userId: userState?.id }),
        enabled: isAuthenticated && !isUseUserLoading && !isAuth0Loading,
    });

    // Function that resets the referred users
    const resetReferredUsers = async () => {
        await queryClient.setQueryData(
            ["refferedUsersData", userState?.id, isUseUserLoading, isAuth0Loading],
            {
                data: { referredUsers: [] },
            }
        );
    };

    return (
        <ReferralBonusContext.Provider
            value={{
                refetchBonusProperties: referralBonusData.refetch,
                bonusProperties: { data: referralBonusData?.data?.data?.bonusProperties ?? [] },
                refetchRefferedUsers: referredUsersData.refetch,
                referredUsers: { data: referredUsersData?.data?.data?.referredUsers ?? [] },
                resetReferredUsers,
            }}
        >
            {children}
        </ReferralBonusContext.Provider>
    );
};

export const useReferralBonus = () => React.useContext(ReferralBonusContext);
