import React from "react";
import { useUser } from "../../../context/UserContext";
import { useQuery } from "@tanstack/react-query";
import { isNil } from "lodash";
import { getWeeklyRank, getWeeklyScore, getRankings } from "../../../services/UserService";
import { getStartAndEndOfWeek } from "../../../helpers/helpers";
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment-timezone";
import StatsTable from "./stats-table";
import MoonLoader from "react-spinners/MoonLoader";

const WeeklyStats = ({ selectedArea, weeklyUserScores }) => {
    const { isAuthenticated } = useAuth0();
    const currentDate = moment().tz("America/Chicago").format("M/DD/YYYY");
    const { startOfWeek } = getStartAndEndOfWeek(currentDate);
    const { userState } = useUser();

    const weeklyRankings = useQuery({
        queryKey: [
            "weekly_rankings",
            userState?.id,
            selectedArea.value,
            currentDate,
            selectedArea.value,
            selectedArea.scope,
        ],
        queryFn: () =>
            getRankings(
                userState?.id,
                selectedArea.value ?? selectedArea.value,
                "weekly",
                currentDate,
                selectedArea.scope
            ),
        enabled: !!userState?.id && !!selectedArea.value && !!selectedArea.scope,
        staleTime: 100,
    });

    const weeklyUserRank = useQuery({
        queryKey: [
            "weekly_user_rank",
            userState?.id,
            userState?.current_area_id,
            selectedArea.value,
            selectedArea.scope,
        ],
        queryFn: () =>
            getWeeklyRank(
                userState?.id,
                selectedArea.value ?? userState?.current_area_id,
                selectedArea.scope
            ),
        enabled:
            isAuthenticated &&
            !isNil(userState?.id) &&
            !isNil(userState?.current_area_id) &&
            !!selectedArea.value &&
            !isNil(userState?.show_score_leaderboard) &&
            userState.show_score_leaderboard,
        placeholderData: () => {
            return {
                current_rank: null,
                total_rank: 0,
            };
        },
        staleTime: 100,
    });

    const weeklyScore = useQuery({
        queryKey: ["weekly_score", userState?.id, selectedArea.value, selectedArea.scope, currentDate],
        queryFn: () => getWeeklyScore(userState?.id, selectedArea.value, currentDate, selectedArea.scope),
        enabled: !isNil(userState?.id) && !isNil(selectedArea.value) && isAuthenticated,
        placeholderData: () => {
            return {
                weekly_score: 0,
            };
        },
        staleTime: 100,
    });

    const isFetchingApi =
        (weeklyRankings?.isFetching && !weeklyRankings?.isError) ||
        (weeklyUserRank?.isFetching && !weeklyUserRank?.isError) ||
        (weeklyUserScores?.isFetching && !weeklyUserScores?.isError);   

    return (
        <div>
            {isFetchingApi ? (
                <div className="d-flex justify-content-center py-4">
                    <MoonLoader size={40} color="#63c19f" />
                </div>
            ) : (
                <div className="position-relative">
                    <div>
                        <StatsTable
                            type="weekly"
                            tableTitle={`Week of ${moment(startOfWeek).format("MMMM DD")}`}
                            rankings={weeklyRankings?.data?.data?.rankings ?? []}
                            userRanking={{
                                current_rank: weeklyUserRank?.data?.current_rank,
                                total_rank: weeklyUserRank?.data?.total_rank,
                                total_score: weeklyScore?.data?.weekly_score,
                            }}
                            scores={weeklyUserScores?.data?.data?.scores ?? []}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

WeeklyStats.propTypes = {};

export default WeeklyStats;
