import axios from "axios";

import { LocalStorageCache } from "@auth0/auth0-react";

/**
 * All auth0k eys
 */
const allKeys = new LocalStorageCache().allKeys();

/**
 * Find auth0 access token from local storage
 */
const getAccessToken = () => {
    let accessToken = null;

    if (!allKeys?.length) return null;

    for (let i = 0; i < allKeys?.length; i++) {
        const key = localStorage.getItem(allKeys[i]);
        const checkToken = JSON.parse(key)?.body?.access_token;
        if (checkToken) {
            accessToken = checkToken;
            break;
        }
    }

    return accessToken;
};

// Access token
const accessToken = getAccessToken();

// Use this instance of axios for authenticated requests
const axiosInstance = axios.create(
    accessToken
        ? {
              headers: {
                  Authorization: `Bearer ${accessToken}`,
              },
          }
        : {}
);

export default axiosInstance;
