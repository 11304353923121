import React from "react";
import "./share-button.scss";
import PropTypes from "prop-types";
import { isEmpty, isNil, isNull } from "lodash";
import { useQuery } from "@tanstack/react-query";
import { useUser } from "../../../../context/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import { getDailyProperties, getShareText } from "../../../../services/UserService";
import { useGuestContext } from "../../../../context/GuestContext";
import { getScoreFeedback } from "../../../../helpers/getScoreFeedback";
import config from "../../../../config";
import AppButton from "../app-button";
import useGAEvent from "../../../../hooks/useGAEvent";
import { triggerShare } from "../../../../helpers/triggerShare";
import moment from "moment";

const ShareButton = ({ className, from, totalScore, rank }) => {
    const { userState } = useUser();
    const { isAuthenticated } = useAuth0();
    const { getGuestPlaysToday } = useGuestContext();
    const { sendEvent } = useGAEvent();

    /**
     * Fetch daily properties based on user's current area and authentication status.
     *
     * This function queries the server to fetch daily properties based on the user's
     * current area and authentication status.
     *
     * @param {string | null} currentAreaId - The user's current area ID or "null" if not available.
     *
     * @returns {Promise<Array>} A promise that resolves to an array of daily properties.
     */
    const dailyProperties = useQuery({
        queryKey: ["daily-properties", userState?.id, isAuthenticated],
        queryFn: () =>
            getDailyProperties(
                !isNil(userState?.id) && !isNull(userState?.id) && !isEmpty(userState?.id)
                    ? userState?.id
                    : "null",
                isAuthenticated
            ),
        enabled: !isNil(userState?.id) && !isEmpty(userState?.id),
    });

    /**
     * Query to fetch the share text for the user.
     *
     * This query retrieves the share text that the user can use to invite friends or share content.
     *
     * @const {QueryResult} shareText
     * @async
     * @param {Array} queryKey - An array representing the query key.
     * @param {Function} queryFn - A function to execute the query.
     * @param {boolean} enabled - A flag indicating whether the query should be enabled.
     */
    const shareText = useQuery({
        queryKey: ["share-text", userState?.id],
        queryFn: () => getShareText(userState?.id),
        enabled: !!userState?.id && isAuthenticated,
        placeholderData: () => {
            const currentDate = moment().tz("America/Chicago").format("MMM D, YYYY");
            const dailyPropertiesArray = dailyProperties?.data?.data?.properties;
            const guestPlaysToday = getGuestPlaysToday() ?? [];

            let shareText = `PriceMe Total Score: ${
                guestPlaysToday.reduce((a, b) => a + b.score, 0) ?? 0
            }\n${currentDate}\n\n`;

            // Generate the property share text if there are current area and daily properties
            if (
                !isNil(dailyPropertiesArray) &&
                guestPlaysToday.length !== 0 &&
                dailyPropertiesArray.length !== 0
            ) {
                dailyPropertiesArray.forEach((dailyProperty) => {
                    // Extract the Property object from the daily properties
                    const property = dailyProperty.Property;

                    let userGuessData = guestPlaysToday.filter(
                        (userGuess) => userGuess.address === property.street_address
                    );

                    if (userGuessData.length !== 0) {
                        const feedback = getScoreFeedback(
                            userGuessData[0].guess,
                            property.price,
                            false,
                            true
                        );
                        const emojis =
                            feedback.feedback.match(
                                /[\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF]/g
                            ) || [];

                        // Append the price difference of the user guess and its corresponding score and feedback emoji
                        shareText += `${emojis ?? ""} +${userGuessData[0].score}pts\n`;
                    }
                });

                shareText += "\n";
            }

            return {
                share: shareText,
            };
        },
    });

    /**
     * Handles the click event when the user wants to share content, either by copying to the clipboard or using native mobile share functionality.
     *
     * This function checks the user's device type (desktop or mobile) and attempts to share content accordingly.
     *
     * @async
     * @throws {Error} Throws an error if sharing fails.
     */
    const handleShareButtonClick = async () => {
        // Fire google tag event the user clicks on the share score on the leaderboard
        sendEvent(`share_score_${from}`, {
            isAuthenticated,
            referralCode: localStorage.getItem("referral_code"),
            finalScore: totalScore,
            userRank: rank,
        });

        await triggerShare({
            web: {
                text: shareText.data.share + `\n${config.BASE_URL}/?ref=${userState?.referral_code}`,
            },
            mobile: {
                title: shareText.data.share,
                text: shareText.data.share,
                url: `${config.BASE_URL}/?ref=${userState?.referral_code}`,
            },
        });
    };

    const loading = shareText.isFetching && shareText.isPlaceholderData;

    return (
        <>
            <AppButton
                id="share-button"
                className="share-buttons"
                variant="solid"
                theme="gold"
                onClick={() => handleShareButtonClick()}
                isDisabled={loading}
                showLoader={loading}
            >
                Share Score
            </AppButton>
        </>
    );
};

ShareButton.propTypes = {
    from: PropTypes.string.isRequired,
    className: PropTypes.string,
    handleClick: PropTypes.func,
};

export default ShareButton;
