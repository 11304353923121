import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import useIsWebview from "./useIsWebview";
import useModalState from "./useModalState";

// Handles opening and closing of the registration cta modal
const useRegisterCtaModal = ({ delay = 1500 }) => {
    const [openRegCta, setOpenRegCta] = useState(false);
    const { isAuthenticated } = useAuth0();

    const { isWebview } = useIsWebview();
    const { setModalId, setModalContent } = useModalState();

    /**
     * Handles registration cta
     */
    const handleRegister = (cb) => {
        if (isWebview) {
            setModalId("WARNING_MODAL");
            setModalContent({
                title: "Heads up...",
                message:
                    "You are accessing PriceMe via the browser on another application (like a social media app), so some features might not work as expected. We suggest opening this site in your native device browser (Chrome, Safari, etc.)",
                ctaTitle: "Got it",
                cb: cb ? cb : () => {},
                icon: "",
            });
        } else {
            if (cb) {
                cb();
            }
        }
    };

    useEffect(() => {
        if (!isAuthenticated) {
            const timeout = setTimeout(() => setOpenRegCta(true), delay);
            return () => clearTimeout(timeout);
        } else {
            setOpenRegCta(false);
        }
    }, [isAuthenticated, delay]);

    return { openRegCta, setOpenRegCta, handleRegister };
};

export default useRegisterCtaModal;
