/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import CustomDatePicker from "../../../components/common/custom-date-picker/custom-date-picker";
import ProgressIndicator from "../../../components/common/progress-indicator/progress-indicator";
import { getDayNumber, getStartAndEndOfWeek, groupScoresByDate } from "../../../helpers/helpers";
import { useUser } from "../../../context/UserContext";
import { useQuery } from "@tanstack/react-query";
import { isEmpty, isNil, isString } from "lodash";
import { getWeeklyScore, getScores, getStreaks, getAwards } from "../../../services/UserService";
import moment from "moment-timezone";
import { useAuth0 } from "@auth0/auth0-react";
import MoonLoader from "react-spinners/MoonLoader";
import { AWARDS } from "../../../components/constants/awards.constants";
import { useGuestContext } from "../../../context/GuestContext";
import clsx from "clsx";

const MyStats = ({ openRegCta, handleRegisterClick }) => {
    const { getGuestPlaysWeek, totalGuestPlayScoreWeek } = useGuestContext();
    const { isAuthenticated } = useAuth0();
    const currentDate = moment().tz("America/Chicago").format("M/DD/YYYY");
    const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(currentDate);
    const [dateQuery, setDateQuery] = useState({
        startOfWeek,
        endOfWeek,
    });
    const { userState } = useUser();
    const [selectedDate, setSelectedDate] = useState(moment(startOfWeek).format("M/DD/YYYY"));
    const isThisWeek = startOfWeek === dateQuery.startOfWeek;
    const [awards, setAwards] = useState([]);
    const isMonday = moment(selectedDate).weekday() === 1;
    let dateParam = selectedDate;

    if (isMonday) {
        // Set the date 1 day ahead if selectedDate is Monday to properly inlcude and sum the overall weekly score
        dateParam = moment(selectedDate).add(1, "days").format("M/DD/YYYY");
    }

    const overallWeeklyScore = useQuery({
        queryKey: ["weekly_score", userState?.id, userState?.current_area_id, dateParam],
        queryFn: () => getWeeklyScore(userState?.id, userState?.current_area_id, dateParam, "all"),
        enabled: !isNil(userState?.id) && !isNil(userState?.current_area_id) && isAuthenticated,
        placeholderData: () => {
            // TODO: Add a weekly score for guest users
            return {
                weekly_score: totalGuestPlayScoreWeek(selectedDate) ?? 0,
            };
        },
        staleTime: 100,
    });

    const overallWeeklyUserScores = useQuery({
        queryKey: [
            "weekly_user_scores",
            userState?.id,
            userState?.current_area_id,
            dateQuery.endOfWeek,
            dateQuery.startOfWeek,
        ],
        queryFn: () =>
            getScores(
                userState?.id,
                userState?.current_area_id,
                dateQuery.endOfWeek,
                dateQuery.startOfWeek,
                "all"
            ),
        enabled: !!userState?.id && !!userState?.current_area_id && isAuthenticated,
        placeholderData: () => {
            return {
                data: {
                    scores: getGuestPlaysWeek(selectedDate),
                },
            };
        },
        staleTime: 100,
    });

    const currentStreak = useQuery({
        queryKey: ["current_streak", userState?.id],
        queryFn: () => getStreaks(userState?.id),
        enabled: !!userState?.id && isAuthenticated,
        placeholderData: () => {
            return {
                streaks: {
                    count: 0,
                },
            };
        },
        staleTime: 100,
    });

    /**
     * Query hook to fetch a user's awards data.
     */
    const userAwards = useQuery({
        queryKey: ["user_awards", userState?.id],
        queryFn: () => getAwards(userState?.id),
        enabled: !!userState?.id && isAuthenticated,
        placeholderData: () => {
            return {
                awards: [],
            };
        },
    });

    /**
     * A boolean indicating whether any of the API calls related to user statistics and awards are currently being fetched.
     *
     * This flag is set to `true` if any of the following API calls are currently in progress: fetching overall weekly score, fetching overall weekly user scores, fetching current streak, or fetching user awards. It is used to manage loading states in the UI.
     *
     * @type {boolean}
     */
    const isFetchingApi =
        (overallWeeklyScore?.isFetching && !overallWeeklyScore?.isError) ||
        (overallWeeklyUserScores?.isFetching && !overallWeeklyUserScores?.isError) ||
        (currentStreak?.isFetching && !currentStreak?.isError) ||
        (userAwards?.isFetching && !userAwards?.isError);

    // Sets the date query
    useEffect(() => {
        const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(selectedDate);
        setDateQuery({ startOfWeek, endOfWeek });
    }, [selectedDate]);

    /**
     * Sorts an array of awards based on type and placement, updating the state with the sorted awards.
     */
    useEffect(() => {
        function sortAwards(awards) {
            return Object.values(awards).sort((award1, award2) => {
                // First, compare by type
                if (award1.type !== award2.type) {
                    return award1.type === "trophy" ? -1 : 1;
                } else {
                    // If types are the same, then compare by placement
                    return award1.placement - award2.placement;
                }
            });
        }

        if (!userAwards.isFetching && !userAwards.isError && !isEmpty(userAwards.data.awards)) {
            setAwards(() => {
                const mappedAwards = userAwards.data.awards.map((userAward) => {
                    return {
                        ...AWARDS[userAward.trophy_name],
                        ...userAward,
                    };
                });

                return sortAwards(mappedAwards);
            });
        }
    }, [userAwards.isFetching, userAwards.isError]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="my-stats">
            <div className="dropdown-row flex-column align-items-start gap-2 my-stats-row">
                <span>Select week: </span>
                <CustomDatePicker
                    selected={`Week of ${moment(dateQuery.startOfWeek).format("MMMM DD")}`}
                    onSelect={(value) => {
                        setSelectedDate(moment(value).format("M/DD/YYYY"));
                    }}
                />
            </div>
            {isFetchingApi ? (
                <div className="d-flex justify-content-center py-4">
                    <MoonLoader size={40} color="#63c19f" />
                </div>
            ) : (
                <div className="position-relative">
                    <div className={openRegCta ? "filter-blur" : ""}>
                        <ProgressIndicator
                            type="days"
                            index={isThisWeek ? getDayNumber() : 7}
                            latestIndex={isThisWeek ? getDayNumber() : 7}
                            progressLength={7}
                            progressContent={["M", "Tu", "W", "Th", "F", "Sa", "Su"]}
                            daysScored={groupScoresByDate(overallWeeklyUserScores?.data?.data?.scores)}
                            hasBottomPoints={true}
                        />
                        <div className="pt-2 overall-weekly-score-row">
                            <span className="text-center title-text">Overall Weekly Score:</span>
                            <span className="text-center fw-bold">
                                {overallWeeklyScore?.data?.weekly_score}
                            </span>
                        </div>
                        <div className="py-5 current-streak-row">
                            <h3 className="text-center title-text">Current Streak</h3>

                            {isAuthenticated ? (
                                <h5 className="text-center days-text">
                                    {currentStreak?.data?.data?.streaks?.count ?? 0} days
                                </h5>
                            ) : (
                                <div className="text-center guest-register-helper-text">
                                    <span onClick={() => handleRegisterClick()}>
                                        Register to track your streak
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className="pb-3">
                            <h3 className="text-center pb-1 title-text">Awards Case</h3>
                            <h5 className="text-center container">
                                <div className="row trophy-row gx-1 align-items-center">
                                    {isAuthenticated ? (
                                        !isEmpty(awards) ? (
                                            awards.map((UserAward) => (
                                                <div className="col" key={UserAward.name}>
                                                    {isString(UserAward?.icon) ? (
                                                        <i
                                                            className={clsx(
                                                                "custom-award-icon",
                                                                UserAward.type,
                                                                `placement-${UserAward.placement}`
                                                            )}
                                                            style={{
                                                                backgroundImage: `url("icons/awards/${UserAward.icon}")`,
                                                            }}
                                                        ></i>
                                                    ) : (
                                                        <>
                                                            <span className="award-text">
                                                                {UserAward.placement}
                                                            </span>
                                                            <UserAward.icon
                                                                className={UserAward.name.toLowerCase()}
                                                            />
                                                        </>
                                                    )}

                                                    {UserAward.trophy_count > 1 ? (
                                                        <span
                                                            className={clsx(
                                                                "counter-badge position-absolute p-1 border border-light rounded-circle",
                                                                UserAward.type,
                                                                `placement-${UserAward.placement}`,
                                                                isString(UserAward?.icon) && "custom-counter"
                                                            )}
                                                        >
                                                            {UserAward.trophy_count}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            ))
                                        ) : (
                                            <div className="col">You have no awards yet.</div>
                                        )
                                    ) : (
                                        <div className="col guest-register-helper-text">
                                            <span onClick={() => handleRegisterClick()}>
                                                Register to earn awards
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </h5>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MyStats;
