import React, { useEffect } from "react";
import { range, isNil } from "lodash";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import AppButton from "../../common/buttons/app-button";
import CustomSvg from "../../common/custom-svg/custom-svg";
import useGAEvent from "../../../hooks/useGAEvent";
import Balloon from "../../../assets/icons-v2/result-popup/balloon.png";
import BustedIcon from "../../../assets/icons-v2/busted.png";
import GuessScores from "../finished-modal/components/GuessScores";
import { PLUS_MINUS_VERSIONS } from "../../../constants/plus-minus-versions";
import "./plus-minus-result-modal.scss";

/**
 * PlusMinusResultModal displays the result modal for the PlusMinus game,
 * showing the user's score, the actual price of the property, and providing an option to try again.
 *
 * @param {object} props - The component props.
 * @param {boolean} props.show - Controls the visibility of the modal.
 * @param {number} props.score - The score to display in the modal.
 * @param {number} props.actualPrice - The actual price of the property guessed.
 * @param {function} props.handleClose - Function to handle closing the modal.
 * @param {string} props.version - The version of the game or modal being displayed.
 * @param {number} props.maxScore - The maximum possible score for the game.
 */
export default function PlusMinusResultModal({
    show,
    score,
    actualPrice,
    handleClose,
    version,
    maxScore,
    busted,
}) {
    const { sendEvent } = useGAEvent();
    const iconSize = { height: 60, width: 60 };
    const currentVersion = PLUS_MINUS_VERSIONS[version];
    let icon = Balloon;
    let pointsText = maxScore ? `${score} out of ${maxScore}` : `+${score}`;

    if (!isNil(busted)) {
        if (busted) icon = BustedIcon;
        pointsText = busted ? "Busted!" : `Solved in ${score}`;
    }

    useEffect(() => {
        sendEvent("plus_minus_final_score", {
            userScore: score,
            plusMinusVersion: version,
        });
    }, [score, version, sendEvent]);

    const handleClickTryAgain = () => {
        sendEvent("plus_minus_try_again", {
            userScore: score,
            plusMinusVersion: version,
        });
        handleClose();

        window.location.reload();
    };

    return (
        <Modal show={show} onHide={handleClose} centered backdrop className="modal-container-finished">
            <Modal.Body className="px-4">
                <div className="modal-feedback">
                    {currentVersion?.modal?.price_hidden
                        ? score
                            ? "Strong performance!"
                            : "We all gotta start somewhere!"
                        : !isNil(busted)
                          ? `Actual Price: ${actualPrice}`
                          : "You got it!"}
                </div>
                <div className="tw-flex tw-justify-center">
                    <CustomSvg src={icon} size={iconSize} />
                </div>
                {maxScore ? (
                    <GuessScores
                        scores={range(1, maxScore + 1)}
                        score={score}
                        justifyPosition="justify-center"
                        hideScore
                    />
                ) : null}
                <div className="points">{pointsText}</div>
                <AppButton onClick={handleClickTryAgain} className="share-buttons">
                    Try Again
                </AppButton>
            </Modal.Body>
        </Modal>
    );
}

PlusMinusResultModal.propTypes = {
    show: PropTypes.bool.isRequired,
    score: PropTypes.number.isRequired,
    actualPrice: PropTypes.number.isRequired,
    handleClose: PropTypes.func.isRequired,
    version: PropTypes.string.isRequired,
    maxScore: PropTypes.number,
    busted: PropTypes.bool,
};
