// =================== CONSTANT VALUES  ========================

export const NOTIFICATION_FREQUENCIES = [{ name: "daily" }, { name: "weekly" }, { name: "never" }];

// constants to map message type and label
export const SUPPORTED_MESSAGE_TYPES = [
    {
        message_type: "new_listings_available",
        action_type: "dropdown",
        label: "When a new property set is available",
        support_push: true,
        support_email: true,
        push_frequency: "never",
        email_frequency: "daily",
        has_frequency_label: false,
        supported_frequencies: [{ name: "daily" }, { name: "weekly" }, { name: "never" }],
    },
    {
        message_type: "new_user_tips",
        action_type: "dropdown",
        label: "New user tips (First week)",
        support_push: false,
        support_email: true,
        push_frequency: "never",
        email_frequency: "daily",
        has_frequency_label: false,
        supported_frequencies: [{ name: "daily" }, { name: "never" }],
    },
    {
        message_type: "user_referral",
        action_type: "dropdown",
        label: "When someone I told about PriceMe signs up",
        support_push: true,
        support_email: true,
        push_frequency: "never",
        email_frequency: "daily",
        supported_frequencies: [{ name: "daily" }, { name: "never" }],
    },
    {
        message_type: "user_awards",
        action_type: "switch",
        label: "Send an email when I win a trophy",
        support_push: false,
        support_email: true,
        push_frequency: "never",
        email_frequency: "daily",
        has_frequency_label: true,
        supported_frequencies: [
            { name: "On", value: "daily" },
            { name: "Off", value: "never" },
        ],
    },
    {
        message_type: "challenge_accept",
        action_type: "switch",
        label: "When someone accepts my challenge",
        support_push: true,
        support_email: true,
        push_frequency: "never",
        email_frequency: "daily",
        has_frequency_label: true,
        supported_frequencies: [
            { name: "On", value: "daily" },
            { name: "Off", value: "never" },
        ],
    },
];

export const getDefaultSetting = (notify_daily = true) => {
    return [
        {
            message_type: "new_listings_available",
            action_type: "dropdown",
            push_frequency: "never",
            email_frequency: notify_daily ? "daily" : "never",
        },
        {
            message_type: "new_user_tips",
            action_type: "dropdown",
            push_frequency: "never",
            email_frequency: "daily",
        },
        {
            message_type: "user_referral",
            action_type: "dropdown",
            push_frequency: "never",
            email_frequency: "daily",
        },
        {
            message_type: "user_awards",
            action_type: "switch",
            push_frequency: "never",
            email_frequency: "daily",
        },
        {
            message_type: "challenge_accept",
            action_type: "switch",
            push_frequency: "never",
            email_frequency: "daily",
        },
        // add more default notification settings
    ];
};

// This is the sorting order for the notifications based on the SUPPORTED_MESSAGE_TYPES constant
export const NOTIF_TYPE_SORT_ORDER = SUPPORTED_MESSAGE_TYPES.reduce((acc, curr, index) => {
    return {
        ...acc,
        [curr["message_type"]]: index + 1,
    };
}, {});

// ============================================================
