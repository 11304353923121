import React from "react";
import { getChallengePropertyData } from "../services/ChallengeService";
import { useQuery } from "@tanstack/react-query";
import { get, isNil } from "lodash";
import { useSearchParams } from "react-router-dom";
import { useUser } from "./UserContext";
import { useAuth0 } from "@auth0/auth0-react";

export const ChallengeContext = React.createContext();

export const ChallengeProvider = ({ children }) => {
    const [searchParams] = useSearchParams();
    const { userState } = useUser();
    const { isAuthenticated } = useAuth0();
    const userId = get(userState, "id", null);
    // Get challenge_id from search param
    let challenge_id = searchParams.get("challenge_id") ?? null;
    const isChallengeURL = searchParams.has("challenge_id");

    if (!challenge_id) {
        // if it doesn't exist in query param, find in local storage
        challenge_id = localStorage.getItem("challenge_id") ?? null;
        // get the referral code using the challenge id
    } else {
        // we store it to localstorage, in case user closes the browser
        localStorage.setItem("challenge_id", challenge_id);
    }

    const challengePropertiesData = useQuery({
        queryKey: ["challengePropertiesData", challenge_id, userId, isAuthenticated],
        queryFn: () => getChallengePropertyData(challenge_id, userId, isAuthenticated),
        enabled: challenge_id !== null,
    });

    const challengeData = get(challengePropertiesData, "data.data", null);

    if (!challengeData) {
        // challenge do not exist remove in local storage
        localStorage.removeItem("challenge_id");
    } else {
        const referral_code = get(challengeData, "challenger.referral_code", "");
        if (referral_code && referral_code.trim() !== "") {
            // referral code exists
            localStorage.setItem("referral_code", referral_code);
        }
    }

    const isFetching = challengePropertiesData?.isFetching
    const isSuccess = challengePropertiesData?.isSuccess

    return (
        <ChallengeContext.Provider
            value={{
                isChallenged: challenge_id !== null && !isNil(challengeData), // do not show modal when challenge do not exist
                challengeProperties: challenge_id !== null ? challengeData : {},
                challengeId: challenge_id,
                challengeQuery: challengePropertiesData,
                isChallengeURL,
                isInvalidChallengURL: !isFetching && !isSuccess,
                isOwnChallenge: isAuthenticated && userId === challengeData?.challenger.user_id
            }}
        >
            {children}
        </ChallengeContext.Provider>
    );
};

export const useChallenge = () => React.useContext(ChallengeContext);
