import React, { useEffect, useState } from "react";
import "./activity-feed.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";
import { useInfiniteQuery } from "@tanstack/react-query";
import MoonLoader from "react-spinners/MoonLoader";
import { getActivityFeedScores } from "../../../services/ScoreService";
import { dataDuplicater } from "../../../helpers/helpers";
import CustomSvg from "../custom-svg/custom-svg";
import awardTrophy from "../../../assets/icons-v2/award-trophy-icon.svg";

const ActivityFeed = () => {
    const [currentSlideIdx, setCurrentSlideIdx] = useState(0);
    const [swiper, setSwiper] = useState();

    // Get all activity feed scores for the day
    const dailyScores = useInfiniteQuery({
        queryKey: ["get-daily-scores"],
        queryFn: ({ pageParam }) => getActivityFeedScores({ pageParam }),
        getNextPageParam: (lastPage) => {
            if (lastPage?.totalPages > parseInt(lastPage?.currentPage)) {
                return parseInt(lastPage?.currentPage) + 1;
            }
            return false;
        },
    });

    const hasPages = dailyScores?.data?.pages[0]?.totalPages > 0;

    const duplicatedScores = dataDuplicater({
        data: dailyScores?.data?.pages
            ?.reduce((prev, curr) => prev.concat(curr), [])
            ?.map((score) => score.scores)
            ?.reduce((prev, curr) => prev.concat(curr), []),
        count: 10,
    });

    const topScorer = Math.max.apply(
        Math,
        duplicatedScores?.map((el) => parseInt(el.total_score))
    );

    // Fetches next page if current slide index is last page
    useEffect(() => {
        if (dailyScores.hasNextPage && dailyScores?.data?.pages?.length === currentSlideIdx + 1) {
            dailyScores.fetchNextPage();
        }
    }, [currentSlideIdx, dailyScores]);

    // Executes only on first Swiper render
    useEffect(() => {
        if (swiper && !swiper.destroyed) {
            swiper.setProgress(0);
        }
    }, [swiper]);

    // Resumes auto play after fetching of new page
    useEffect(() => {
        if (!dailyScores.isFetching && swiper && !swiper.destroyed) {
            swiper.autoplay.start();
        }
    }, [dailyScores.isFetching, swiper]);

    return (
        <div className="activity-feed-section mb-4">
            <div className="activity-feed-container pt-4 pb-2 px-2">
                <h5 className="app-text-title text-center mb-2 fw-bold">Today on PriceMe</h5>
                <div className="acitvity-feed-body">
                    <div className="scores-container">
                        {!dailyScores.isLoading && !dailyScores.isError ? (
                            <>
                                {hasPages ? (
                                    <Swiper
                                        autoplay={{
                                            delay: 1200,
                                            disableOnInteraction: true,
                                        }}
                                        navigation={false}
                                        modules={[Pagination, Navigation, Autoplay]}
                                        className="scores-feed py-3"
                                        loop
                                        slidesPerView="auto"
                                        onRealIndexChange={(swiper) => {
                                            setCurrentSlideIdx(swiper?.realIndex);
                                        }}
                                        speed="300"
                                        onSwiper={(swiper) => setSwiper(swiper)}
                                        spaceBetween="15"
                                        onTouchEnd={() => {
                                            // Force start autoplay with 4s delay after swiper interaction
                                            if (swiper && !swiper.destroyed) {
                                                setTimeout(() => swiper.autoplay.start(), 4000);
                                            }
                                        }}
                                    >
                                        {duplicatedScores?.map((score, i) => (
                                            <SwiperSlide
                                                key={"property-image-slide-" + i}
                                                className="score-card"
                                            >
                                                {parseInt(score.total_score) === topScorer ? (
                                                    <>
                                                        <div className="top-score-badge">
                                                            <span className="fw-bold">Top Score</span>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div>
                                                                <CustomSvg
                                                                    src={awardTrophy}
                                                                    size={{ height: 45, width: "auto" }}
                                                                />
                                                            </div>
                                                            <div className="w-100 overflow-hidden">
                                                                <div className="overflow-hidden d-flex justify-content-between gap-1 ">
                                                                    <p className="truncate-text score-title mb-0 fw-bold">
                                                                        {score?.User?.nickname}
                                                                    </p>
                                                                    <p className="mb-0 score-title score-text fw-bold">
                                                                        {score?.total_score} pts
                                                                    </p>
                                                                </div>
                                                                <p
                                                                    style={{
                                                                        overflow: "hidden",
                                                                        width: "100%",
                                                                    }}
                                                                    className="score-subtitle mb-0"
                                                                >
                                                                     {`Properties guessed today: ${score?.guess_count}`}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="overflow-hidden d-flex justify-content-between gap-1 ">
                                                            <p className="truncate-text score-title mb-0 fw-bold">
                                                                {score?.User?.nickname}
                                                            </p>
                                                            <p className="mb-0 score-title score-text fw-bold">
                                                                {score?.total_score} pts
                                                            </p>
                                                        </div>
                                                        <p
                                                            style={{ overflow: "hidden", width: "100%" }}
                                                            className="score-subtitle mb-0"
                                                        >
                                                           {`Properties guessed today: ${score?.guess_count}`}
                                                        </p>
                                                    </>
                                                )}
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                ) : (
                                    <p className="mb-0 text-center">No scores today yet.</p>
                                )}
                            </>
                        ) : (
                            <div className="w-100 d-flex justify-content-center">
                                <MoonLoader size={30} color="#63c19f" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActivityFeed;
