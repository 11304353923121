import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useUser } from "../../../context/UserContext";
import { isNil, isNull, get } from "lodash";
import { getDailyProperties } from "../../../services/UserService";
import AppButton from "../../../components/common/buttons/app-button";
import ShareButton from "../../../components/common/buttons/share-button/share-button";
import PropTypes from "prop-types";

function LeaderboardMainButton({ totalScore }) {
    const { userState, setUserState } = useUser();
    let dailyPropertiesGuessesCount = 0;
    let dailyPropertiesUnlockedCount = 3;
    const activeTabIndex = userState?.leaderboard_active_tab?.tab;
    const challengeHistorySelectedUser = userState?.challenge_history_selected_user;

    const getDailyPropertiesQuery = useQuery({
        queryKey: ["daily-properties", userState?.id],
        queryFn: () =>
            getDailyProperties(
                !isNil(userState?.id) && !isNull(userState?.id) ? userState?.id : "null",
                true
            ),
        enabled: !isNil(userState?.id),
        cacheTime: 0,
    });

    const getDailyPropertiesQueryLoading =
        getDailyPropertiesQuery.isFetching ||
        getDailyPropertiesQuery.isLoading ||
        getDailyPropertiesQuery.isPlaceholderData;

    // After the query is loaded, count the number of daily properties with user guesses and the number of unlocked daily properties.
    if (!getDailyPropertiesQueryLoading && getDailyPropertiesQuery?.data?.data) {
        const dailyProperties = get(getDailyPropertiesQuery, "data.data.properties", null);

        if (dailyProperties.length !== 0) {
            dailyPropertiesGuessesCount = dailyProperties.filter(
                (dailyProperty) => get(dailyProperty, "Property.UserGuesses", []).length !== 0
            ).length;

            dailyPropertiesUnlockedCount = dailyProperties.filter(
                (dailyProperty) => !dailyProperty.is_locked
            ).length;
        }
    }

    // Show the share button if all available properties (daily + unlocked challenge) have been answered
    const showShareButton = dailyPropertiesGuessesCount === dailyPropertiesUnlockedCount;

    /**
     * Handles the play button click, navigating to the Daily Properties page.
     *
     * @returns {void}
     */
    const handlePlayButton = (isChallenge = false) => {
        if (activeTabIndex === 3 && isChallenge) {
            setUserState({ ...userState, show_scrollability_tooltip: true });
        }
        window.location.href = "/daily-properties";
    };

    return (
        <>
            {showShareButton ? (
                <ShareButton from="leaderboard" totalScore={totalScore ?? 0} />
            ) : (
                <AppButton
                    variant="solid"
                    theme="gold"
                    onClick={() => handlePlayButton()}
                    isDisabled={getDailyPropertiesQueryLoading}
                    showLoader={getDailyPropertiesQueryLoading}
                >
                    {dailyPropertiesGuessesCount === 0 ? "Play Today" : "Keep Playing"}
                </AppButton>
            )}
            {activeTabIndex === 3 && (
                <AppButton
                    variant="bordered"
                    theme="purple"
                    className="w-100"
                    onClick={() => handlePlayButton(true)}
                >
                    {`Send ${challengeHistorySelectedUser?.id ? challengeHistorySelectedUser?.nickname : "someone"} a Challenge`}
                </AppButton>
            )}
        </>
    );
}

LeaderboardMainButton.propTypes = {
    totalScore: PropTypes.number.isRequired,
};

export default LeaderboardMainButton;
