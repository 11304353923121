import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { X } from "react-bootstrap-icons";
import ShareButton from "../../common/buttons/share-button/share-button";
import style from "./finished-first-play-modal.module.scss";
import PropTypes from "prop-types";
import useGAEvent from "../../../hooks/useGAEvent";

const FinishedFirstPlayModal = ({
    show,
    handleClose,
    feedback,
    icon,
    practiceScores,
    userScores,
    totalScore,
    rank,
}) => {
    const { sendEvent } = useGAEvent();

    useEffect(() => {
        sendEvent("final_score_first_play_view", {});
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            backdrop
            data-cy="finished-modal"
            className={style.finished_first_play_modal_container}
            contentClassName={style.modal_content}
        >
            <div className={style.close_btn}>
                <X className="close" size={30} onClick={() => handleClose()} />
            </div>
            <Modal.Body className={style.modal_body + " px-4"}>
                <h5 className="app-text-title text-center">{feedback}</h5>
                <div className={style.icon_container}>{icon}</div>
                <div className={style.scores_row}>
                    <div className={style.scores_col}>
                        <p className={style.col_title}>Warm-Up</p>
                        <ul>
                            {practiceScores?.scores?.map((score, i) => (
                                <li key={i}>{score?.Score?.points}</li>
                            ))}
                        </ul>
                        <div className={style.total}>+{practiceScores?.totalScore}</div>
                    </div>
                    <div className={style.scores_col}>
                        <p className={style.col_title}>Today's Game</p>
                        <ul>
                            {userScores?.daily_score?.scores?.map((score, i) => (
                                <li key={i}>{score.points}</li>
                            ))}
                        </ul>
                        <div className={style.total}>+{userScores?.daily_score?.total_score}</div>
                    </div>
                </div>
                <div className={style.reminder}>
                    <p>5 more days to improve your rank!</p>
                </div>
                <div className={style.share_button_container}>
                    <ShareButton
                        className={style.share_button}
                        from="fpopup"
                        totalScore={totalScore}
                        rank={rank}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
};

FinishedFirstPlayModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    feedback: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    practiceScores: PropTypes.object.isRequired,
    userScores: PropTypes.object.isRequired,
    totalScore: PropTypes.number.isRequired,
    rank: PropTypes.number.isRequired,
};

export default FinishedFirstPlayModal;
