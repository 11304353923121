import React from "react";
import "./custom-dropdown.scss";
import { motion, AnimatePresence } from "framer-motion";
import MoonLoader from "react-spinners/MoonLoader";
import PropTypes from "prop-types";
import { FaAngleDown, FaAngleUp, FaStar } from "react-icons/fa6";
import { capitalize } from "lodash";
import clsx from "clsx";

/**
 * Custom Dropdown props.
 *
 * @param {string} selected - Current selected will be the title.
 * @param {boolean} open - Shows or hides dropdown.
 * @param {array} items - Items array, must be of type Array<{ name: string | number; value: string | number }>.
 * @param {func} handleSelect - Handles selection of items.
 * @param {func} handleOpen - Handles opening/closing of dropdown.
 * @param {ref} dropdownRef - Dropdown element ref.
 * @param {boolean} fullWidth - Whether the dropdown should take the full width of its container.
 * @param {string} textAlign - Text alignment for the selected option.
 */

const CustomDropdown = ({
    selected = "",
    open = false,
    items = [],
    handleSelect = (value) => {},
    handleOpen = () => {},
    dropdownRef = null,
    hasSections = false,
    sectionItems = [],
    sectionTitles = [],
    isLoading,
    fullWidth = false,
    textAlign = "center",
}) => {
    return (
        <div className={clsx("dropdown-container", { "full-width": fullWidth })} ref={dropdownRef}>
            <div className={clsx("dropdown-input", { "full-width": fullWidth })} onClick={handleOpen}>
                <div className={clsx("dropdown-text", open && "dropdown-active")}>
                    <h5 className={clsx("fw-bold", textAlign, { "full-width": fullWidth })}>
                        {capitalize(selected)}
                    </h5>
                </div>
                <div className="dropdown-button">
                    {open ? (
                        <FaAngleUp fontSize={19} className="dropdown-icon" />
                    ) : (
                        <FaAngleDown fontSize={19} className="dropdown-icon" />
                    )}
                </div>
            </div>
            <AnimatePresence>
                {open && (
                    <>
                        {isLoading ? (
                            <div className="dropdown">
                                <div className="d-flex justify-content-center py-4">
                                    <MoonLoader size={40} color="#63c19f" />
                                </div>
                            </div>
                        ) : (
                            <>
                                {!hasSections && (
                                    <motion.div
                                        initial={{ opacity: 0, scale: 0.98 }}
                                        animate={{ opacity: 1, scale: 1 }}
                                        exit={{ opacity: 0, scale: 0.98 }}
                                        className="dropdown"
                                    >
                                        <ul>
                                            {items.map((el, i) => (
                                                <li key={i} onClick={() => handleSelect(el)}>
                                                    {el.name}
                                                </li>
                                            ))}
                                        </ul>
                                    </motion.div>
                                )}
                                {hasSections && (
                                    <motion.div
                                        initial={{ opacity: 0, scale: 0.98 }}
                                        animate={{ opacity: 1, scale: 1 }}
                                        exit={{ opacity: 0, scale: 0.98 }}
                                        className="dropdown"
                                    >
                                        <ul>
                                            {sectionItems?.map((section, sectionkey) => (
                                                <React.Fragment key={sectionkey}>
                                                    {sectionTitles?.map((title, titleKey) => {
                                                        if (titleKey === sectionkey) {
                                                            return (
                                                                <li
                                                                    key={titleKey}
                                                                    className={clsx(
                                                                        "dropdown-divider",
                                                                        sectionkey === 0 && "header-divider"
                                                                    )}
                                                                >
                                                                    {title}
                                                                </li>
                                                            );
                                                        }
                                                        return (
                                                            <React.Fragment key={titleKey}></React.Fragment>
                                                        );
                                                    })}
                                                    {section.map((item, i) => (
                                                        <li key={i} onClick={() => handleSelect(item)}>
                                                            <span className="item-name">{item.name}</span>
                                                            <span className="item-star">
                                                                {item.isPlayed ? (
                                                                    <FaStar fontSize={16} />
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </span>
                                                        </li>
                                                    ))}
                                                </React.Fragment>
                                            ))}
                                        </ul>
                                    </motion.div>
                                )}
                            </>
                        )}
                    </>
                )}
            </AnimatePresence>
        </div>
    );
};

CustomDropdown.propTypes = {
    selected: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    handleOpen: PropTypes.func.isRequired,
    handleSelect: PropTypes.func.isRequired,
    items: PropTypes.array,
    dropdownRef: PropTypes.any,
    hasSections: PropTypes.bool,
    sectionTitles: PropTypes.array,
    sectionItems: PropTypes.array,
    isLoading: PropTypes.bool,
    fullWidth: PropTypes.bool,
    textAlign: PropTypes.string,
};

export default CustomDropdown;
