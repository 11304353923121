/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { filter, find, get, isEmpty, isNil } from "lodash";
import toast from "react-hot-toast";
import { useAuth0 } from "@auth0/auth0-react";
import Sticky from "react-sticky-el";

import { getUserGuessesPerPeriod, getDailyProperties, submitGuess } from "../../services/UserService";
import { unlockProperty } from "../../services/ChallengeService";
import { getAreaById } from "../../services/AreaService";
import { getScoreFeedback } from "../../helpers/getScoreFeedback";
import { unmaskValue } from "../../helpers/helpers";

import { useChallenge } from "../../context/ChallengeContext";
import { useGuestContext } from "../../context/GuestContext";
import { useScore } from "../../context/ScoreContext";
import { useUser } from "../../context/UserContext";
import useGAEvent from "../../hooks/useGAEvent";
import config from "../../config";

import ChallengeResultModal from "../../components/modals/ChallengeResultModal";
import FinishedModal from "../../components/modals/finished-modal/finished-modal";
import SoldoutModal from "../../components/modals/soldout-modal/soldout-modal";
import CustomButton from "../../components/common/buttons/custom-button/custom-button";
import GuessPriceInput from "../../components/common/guess-price-input/guess-price-input";
import PropertyInfo from "../../components/common/PropertyInfo";

import { INIT_PROPERTY_VALUES } from "../../constants/initial-property-values";
import { TOAST_MESSAGES } from "../../lang/toast-messages.lang";
import { toastAndRedirect } from "../../helpers/toastAndRedirect";
import { setChallengeLinkMetadata } from "../../helpers/setChallengeLinkMetadata";

/**
 * Component for displaying and handling the challenge mode.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
export default function ChallengeMode() {
    const navigate = useNavigate();
    const { userState } = useUser();
    const { isAuthenticated } = useAuth0();
    const { addTotalScore, totalScore } = useScore();
    const { guestPlay, setGuestPlay } = useGuestContext();
    const { sendEvent } = useGAEvent();
    const { challengeProperties, isInvalidChallengURL, isOwnChallenge, challengeId } = useChallenge();

    const [property, setProperty] = useState(INIT_PROPERTY_VALUES);
    const [inputDisable, setInputDisable] = useState(false);
    const [guessPrice, setGuessPrice] = useState("");
    const [showChallengeResultModal, setShowChallengeResultModal] = useState(false);
    const [showFinishedModal, setShowFinishedModal] = useState(false);
    const [showSoldoutModal, setShowSoldoutModal] = useState(false);
    const [scores, setScores] = useState([]);
    const [userGuesses, setUserGuesses] = useState([]);
    const [dailyProperties, setDailyProperties] = useState([]);
    const unlockedProperties = filter(
        dailyProperties,
        (prpty) => !isNil(prpty?.is_locked) && prpty.is_locked === false
    );
    const isAllPropertiesUnlocked = unlockedProperties.length === config.MAXIMUM_GUESS;

    const bodyRef = useRef(null);
    const inputRef = useRef(null);

    let dailyScore = 0;
    const challengerData = challengeProperties?.challenger;
    const challengerPoints = challengerData?.points;
    const challengerUserId = challengerData?.user_id;

    const currentArea = useQuery({
        queryKey: ["current-area", userState?.current_area_id],
        queryFn: () => getAreaById(userState?.current_area_id),
        enabled: !!userState?.current_area_id,
    });

    const userGuessScoreMutation = useMutation({
        mutationFn: (userGuess) => {
            return submitGuess(userState?.id, property.id, userGuess, challengeId);
        },
    });

    /**
     *
     * Query to get the user guesses of the user for challenge mode
     *
     * @param {string} userId - The ID of the user
     *
     * @returns {Promise}
     */
    const userGuessQuery = (userId) => getUserGuessesPerPeriod({ user_id: userId, is_challenge: true });

    const unlockPropertyMutation = useMutation({
        mutationFn: async (challengeParam) => {
            const { winnerId, opponentId, propertyId, propertyAddress, winnerScore, opponentScore, isTied } =
                challengeParam;
            await unlockProperty(
                winnerId,
                opponentId,
                propertyId,
                propertyAddress,
                winnerScore,
                opponentScore,
                challengeId,
                isTied
            );
        },
    });

    useEffect(() => {
        const handleGuessedProperty = async () => {
            if (!isAuthenticated) {
                const guessedProperty = find(guestPlay, ["id", property?.id]);
                if (guessedProperty && guessedProperty.guess) {
                    setInputDisable(true);
                    setGuessPrice(guessedProperty.guess);
                }
            } else {
                const userGuesses = await userGuessQuery(userState?.id);
                const challengeGuessed = find(get(userGuesses, "data.userGuesses", []), [
                    "property_id",
                    property.id,
                ]);
                if (challengeGuessed) {
                    setInputDisable(true);
                    setGuessPrice(challengeGuessed.guess);
                }
            }
        };

        if (!inputDisable) handleGuessedProperty();
    }, [isAuthenticated, guestPlay, property, userState?.id]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setChallengeLinkMetadata(challengeProperties?.property, challengeProperties?.id, true);
    }, [challengeProperties?.property, challengeProperties?.id]);

    useMemo(() => {
        if (challengeProperties) setProperty(challengeProperties?.property);
    }, [challengeProperties]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isAuthenticated && isEmpty(scores) && !isEmpty(userGuesses)) {
            const userScores = userGuesses.map((guess) => guess.Score.points);
            setScores(userScores);
        }
    }, [userGuesses, scores, isAuthenticated]);

    useEffect(() => {
        const fetchUserGuessesAndProperties = async () => {
            const userGuesses = await userGuessQuery(userState?.id);
            const properties = await getDailyProperties(userState?.id, isAuthenticated);
            setUserGuesses(get(userGuesses, "data.userGuesses", []));
            setDailyProperties(get(properties, "data.properties", []));
        };

        if (isAuthenticated && isEmpty(userGuesses)) {
            fetchUserGuessesAndProperties();
        }
    }, [isAuthenticated, userState?.id]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Handle the change event of the input field for guessing the price.
     * Updates the guessPrice state with the new value.
     *
     * @param {Object} e - The event object generated by the input field.
     */
    const handleGuessPriceChange = (e) => {
        setGuessPrice(e.target.value);
    };

    /**
     * Handle closing of ChallengeResultModal and showing next modal based on nextToShow param.
     * Updates the guessPrice state with the new value.
     *
     * @param {String} nextToShow - The string name prop to be based on what modal to show next.
     */
    const handleChallengeResultModalClose = (nextToShow = null) => {
        if (nextToShow === "finished_modal") setShowFinishedModal(true);

        setShowChallengeResultModal(false);
    };

    /**
     * Handle the form submission event.
     * Performs various actions such as showing results, calculating scores, and updating the total score.
     */
    const handleSubmit = async () => {
        const guessPriceVal = unmaskValue(guessPrice);

        const guess = parseInt(guessPriceVal === "" ? 0 : guessPriceVal);
        const realPrice = parseInt(property?.price);

        if (guessPriceVal === "") {
            setGuessPrice("0");
        }

        const result = getScoreFeedback(guess, realPrice);

        dailyScore += result.score;
        addTotalScore(dailyScore);

        let challengeWinner = "challenger";

        if (result.score > challengerPoints) {
            challengeWinner = "challengee";
        } else if (result.score === challengerPoints) {
            challengeWinner = "tie";
        }

        const challengeProperty = {
            propertyId: property?.id,
            propertyAddress: property?.street_address?.split(",")[0],
        };

        // Determine challengee id whether its a user id or a guest id ("0")
        const challengeeId = isAuthenticated ? userState?.id : "0";

        const unlockPropertyForTie = async () => {
            const unlockParams = {
                winnerScore: challengerPoints,
                opponentScore: result.score,
                ...challengeProperty,
            };

            unlockPropertyMutation.mutateAsync({
                winnerId: challengerUserId,
                opponentId: challengeeId,
                isTied: true,
                ...unlockParams,
            });
        };

        const unlockProperty = async () => {
            const challengerWon = challengeWinner === "challenger";
            const winnerId = challengerWon ? challengerUserId : challengeeId;
            const opponentId = challengerWon ? challengeeId : challengerUserId;
            const winnerScore = challengerWon ? challengerPoints : result.score;
            const opponentScore = challengerWon ? result.score : challengerPoints;

            await unlockPropertyMutation.mutateAsync({
                winnerId,
                opponentId,
                winnerScore,
                opponentScore,
                ...challengeProperty,
            });
        };

        if (isAuthenticated) {
            userGuessScoreMutation.mutate(
                {
                    guess,
                    score: result.score,
                    area_id: userState?.current_area_id,
                    exclude_score: isAllPropertiesUnlocked,
                },
                {
                    onSuccess: async () => {
                        setInputDisable(true);

                        const userGuesses = await userGuessQuery(userState?.id);

                        if (userGuesses?.data?.count?.total === 3) {
                            sendEvent("completion", {
                                isAuthenticated,
                                userArea: currentArea?.data?.name,
                                finalScore: totalScore + dailyScore,
                            });
                        }

                        if (challengeWinner === "tie") {
                            await unlockPropertyForTie();
                        } else {
                            await unlockProperty();
                        }
                    },
                    onError: () => {
                        toast.error(TOAST_MESSAGES.DUPLICATE_GUESS, { id: "DUPLICATE_GUESS" });
                        setInputDisable(true);
                    },
                }
            );
        } else {
            const guestGuessedProperty = find(guestPlay, ["id", property?.id]);

            // The guest user has not guessed this property yet
            if (!guestGuessedProperty) {
                if (challengeWinner === "tie") {
                    unlockPropertyForTie();
                } else {
                    unlockProperty();
                }
            }

            const filteredGuestPlay = filter(
                guestPlay,
                (guessScore) =>
                    guessScore.address === property?.street_address?.split(",")[0] &&
                    guessScore.area_id === userState?.current_area_id
            );

            if (filteredGuestPlay.length === 0) {
                const updatedGuestPlay = [
                    ...(guestPlay ?? []),
                    {
                        guess,
                        id: property?.id,
                        score: result.score,
                        address: property?.street_address?.split(",")[0],
                        created_at: new Date().toISOString(),
                        area_id: userState?.current_area_id,
                        isChallengeGuess: true,
                        challenge_id: challengeId,
                    },
                ];

                setGuestPlay(updatedGuestPlay);
            }

            setInputDisable(true);
        }

        setShowChallengeResultModal(true);
    };

    if (isOwnChallenge || isInvalidChallengURL) {
        toastAndRedirect(
            "error",
            isOwnChallenge ? "OWN_CHALLENGE_LINK" : "INVALID_CHALLENGE_URL",
            3000,
            4000
        );
        return null;
    }

    return (
        <div className="property-container" ref={bodyRef}>
            <PropertyInfo property={property} />
            <Sticky className="guess-input-sticky" mode="bottom" positionRecheckInterval={50}>
                <div className="guess-input-container overflow-hidden px-3 py-3 guess-input-container-shadow">
                    <div className="flex-wrap d-flex align-items-center justify-content-center">
                        <div className="score-to-beat">
                            <span>Score to beat: </span>
                            <span className="app-text-title score">{challengerPoints}</span>
                        </div>
                    </div>

                    <div className="input-btn-container d-flex gap-2 w-100 mx-auto my-0 mt-2">
                        <div className="input px-0">
                            <GuessPriceInput
                                onClear={() => {
                                    setGuessPrice("");
                                }}
                                isPreviousProperty={false}
                                inputDisable={inputDisable}
                                inputRef={inputRef}
                                handleGuessPriceChange={handleGuessPriceChange}
                                prices={{
                                    guess: unmaskValue(guessPrice ?? ""),
                                    actual: property?.price,
                                }}
                                isChallengeMode
                            />
                        </div>
                        <div className="btn p-0">
                            {inputDisable ? (
                                <CustomButton
                                    className="gold-solid"
                                    handleClick={() => setShowChallengeResultModal(true)}
                                    id="next-property-main"
                                    data-cy="next-property-button"
                                    text="See Results"
                                />
                            ) : (
                                <CustomButton
                                    className="gold-solid d-flex align-items-center justify-content-center"
                                    handleClick={handleSubmit}
                                    id="submit-guess"
                                    dataCy="submit-button"
                                    text="Submit"
                                    isLoading={userGuessScoreMutation.isLoading}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </Sticky>
            {showChallengeResultModal && (
                <ChallengeResultModal
                    show={showChallengeResultModal}
                    guessPrice={unmaskValue(guessPrice ?? "")}
                    realPrice={property?.price}
                    challengeId={challengeId}
                    challenger={challengerData}
                    handleClose={handleChallengeResultModalClose}
                    dailyProperties={dailyProperties}
                />
            )}
            {showFinishedModal && (
                <FinishedModal
                    show={showFinishedModal}
                    handleClose={() => {
                        setShowFinishedModal(false);
                        window.location.href = "/leaderboard";
                    }}
                    orderedScores={scores}
                />
            )}
            {showSoldoutModal && (
                <SoldoutModal
                    show={showSoldoutModal}
                    handleClose={(donefornow = true) => {
                        if (donefornow) {
                            setShowFinishedModal(true);
                        } else {
                            navigate("/daily-properties");
                        }
                        setShowSoldoutModal(false);
                    }}
                    orderedScores={scores}
                />
            )}
        </div>
    );
}
