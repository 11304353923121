import React from "react";
import { useUser } from "../../../context/UserContext";
import { useQuery } from "@tanstack/react-query";
import { getRankings } from "../../../services/UserService";
import { getDailyRank } from "../../../services/UserService";
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment-timezone";
import StatsTable from "./stats-table";
import MoonLoader from "react-spinners/MoonLoader";

const DailyStats = ({ selectedArea, weeklyUserScores }) => {
    const { isAuthenticated } = useAuth0();
    const currentDate = moment().tz("America/Chicago").format("M/DD/YYYY");
    const { userState } = useUser();

    const dailyRankings = useQuery({
        queryKey: ["daily_rankings", userState?.id, selectedArea.value, currentDate, selectedArea.scope],
        queryFn: () =>
            getRankings(userState?.id, selectedArea.value, "daily", currentDate, selectedArea.scope),
        enabled: !!userState?.id && !!selectedArea.value && !!selectedArea.scope, // && isAuthenticated,
        staleTime: 100,
    });

    const dailyUserRank = useQuery({
        queryKey: ["daily_user_rank", userState?.id, selectedArea.value, selectedArea.scope],
        queryFn: () => getDailyRank(userState?.id, selectedArea.value, selectedArea.scope),
        enabled: !!userState?.id && !!selectedArea.value && isAuthenticated,
        placeholderData: () => {
            // TODO: Add a weekly rank for guest users
            return {
                current_rank: null,
                total_rank: 0,
                total_score: 0,
            };
        },
        staleTime: 100,
    });

    const isFetchingApi =
        (dailyRankings?.isFetching && !dailyRankings?.isError) ||
        (dailyUserRank?.isFetching && !dailyUserRank?.isError) ||
        (weeklyUserScores?.isFetching && !weeklyUserScores?.isError) ||
        (dailyUserRank?.isFetching && !dailyUserRank?.isError);

    return (
        <div>
            {isFetchingApi ? (
                <div className="d-flex justify-content-center py-4">
                    <MoonLoader size={40} color="#63c19f" />
                </div>
            ) : (
                <div className="position-relative">
                    <div>
                        <StatsTable
                            type="daily"
                            rankings={dailyRankings?.data?.data?.rankings ?? []}
                            userRanking={{
                                current_rank: dailyUserRank?.data?.current_rank,
                                total_rank: dailyUserRank?.data?.total_rank,
                                total_score: dailyUserRank?.data?.total_score,
                            }}
                            scores={weeklyUserScores?.data?.data?.scores ?? []}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

DailyStats.propTypes = {};

export default DailyStats;
