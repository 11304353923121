import axios from "axios";
import { get } from "lodash";
import { toastAndRedirect } from "../helpers/toastAndRedirect";

/**
 * Creates a challenge.
 * @async
 * @param {object} params - The parameters for creating the challenge.
 * @param {string} params.challenger_score_id - The ID of the challenger's score.
 * @returns {Promise<object>} - A promise that resolves with the created challenge data.
 */
export const createChallenge = async ({ challenger_score_id }) => {
    return await axios.post(`/api/challenge/create`, {
        challenger_score_id,
    });
};

/**
 * Retrieves property data for a given challenge.
 * @async
 * @param {string} challenge_id - The ID of the challenge.
 * @returns {Promise<object>} - A promise that resolves with the challenge's property data.
 */
export const getChallengePropertyData = async (challenge_id, userId, isAuthenticated) => {
    let result = await axios.get(`/api/challenge/${challenge_id}/property`);
    let challengeData = get(result, "data", null);

    if (!challengeData) {
        // if no challenge data is returned, the challenge id has expired or do not exist
        toastAndRedirect("error", "CHALLENGE_EXPIRED", 2000, 3000);
    } else {
        const propertyId = get(challengeData, "property.id", null);
        if (challenge_id && propertyId && userId && isAuthenticated) {
            let guess = await axios.get(`/api/users/${userId}/guess`, {
                params: {
                    property_id: propertyId,
                },
            });
            const guessData = get(guess, "data.guess", null);
            if (guessData) {
                toastAndRedirect("error", "CHALLENGE_ACCEPTED", 2000, 3000);
                challengeData = null;
            }
        }
    }
    return result;
};

/**
 * Unlocks a property for the user once they have won a challenge.
 *
 * @async
 * @param {string} winnerId - The ID of the user who has won the challenge.
 * @param {string} opponentId - The ID of the user's opponent in the challenge.
 * @param {string} propertyId - The ID of the property being challenged.
 * @param {string} challengeId - The ID of the challenge.
 * @param {string} propertyAddress - The address of the property being challenged.
 * @param {number} winnerScore - The score of the user who has won the challenge.
 * @param {number} opponentScore - The score of the user's opponent in the challenge.
 * @param {boolean} [isTied=false] - The score of the user's opponent in the challenge.
 *
 * @returns {Promise<object>} A promise that resolves to the response from the API.
 * @throws {Error} Throws an error if the request fails.
 */
export async function unlockProperty(
    winnerId,
    opponentId,
    propertyId,
    propertyAddress,
    winnerScore,
    opponentScore,
    challengeId,
    isTied = false
) {
    try {
        const response = await axios.post("/api/challenge/unlock_property", {
            winner_id: winnerId,
            opponent_id: opponentId,
            property_id: propertyId,
            property_address: propertyAddress,
            winner_score: winnerScore,
            opponent_score: opponentScore,
            challenge_id: challengeId,
            isTied,
        });
        return response.data;
    } catch (error) {
        throw new Error("Failed to unlock property: " + error.message);
    }
}
