import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import CustomDropdown from "../custom-dropdown/custom-dropdown";
import useDropdownDisclosure from "../custom-dropdown/hooks/useDropdownDisclosure";
import { findIndex, find, get, isEqual } from "lodash";
import OneSignal from "react-onesignal";
import {
    SUPPORTED_MESSAGE_TYPES,
    NOTIF_TYPE_SORT_ORDER,
    getDefaultSetting,
} from "./../../constants/notification-settings.constants";
import Switch from "../switch/switch";
import { clsx } from "clsx";

const NotifAction = (props) => {
    const {
        frequency_selected,
        notif_type,
        index,
        message_type,
        handleSetting,
        notification_frequencies,
        has_frequency_label,
        action_type = "dropdown", // dropdown, switch
    } = props;

    const { dropdownRef, openDropdown, handleOpen } = useDropdownDisclosure();

    /**
     * Get the notification label based on the frequency name.
     *
     * @param {string} frequency_name - The name of the frequency.
     * @returns {string} - The corresponding notification label.
     */

    const getNotifLabel = (frequency_name) => {
        if (has_frequency_label) {
            if (frequency_name === "daily") return "On";
            if (frequency_name === "never") return "Off";
        } else {
            return frequency_name;
        }
    };

    // Return dropdown component if action type is dropdown
    if (action_type === "dropdown") {
        return (
            <div className="grid-item" key={`${index}-${notif_type}`}>
                <CustomDropdown
                    key={index}
                    dropdownRef={dropdownRef}
                    open={openDropdown}
                    selected={getNotifLabel(frequency_selected)}
                    handleOpen={handleOpen}
                    items={notification_frequencies}
                    handleSelect={(frequency) => {
                        let frequency_name = has_frequency_label ? frequency.value : frequency.name;
                        // Open OneSignal subscribe prompt if frequence is weekly or daily and notif type is push
                        if (
                            (frequency_name === "weekly" || frequency_name === "daily") &&
                            notif_type === "push_frequency"
                        ) {
                            OneSignal.Slidedown.promptPush(); // Does not prompt if user is already subscribed
                            OneSignal.User.addTag("frequency", frequency_name); // Adds frequency tag to user
                        }
                        handleSetting({ message_type, notif_type, frequency_selected: frequency_name });
                        handleOpen();
                    }}
                />
            </div>
        );
    }

    // Return switch component if action type is dropdown
    if (action_type === "switch") {
        return (
            <div className="grid-item p-0 lh-1" key={`${index}-${notif_type}`}>
                <Switch
                    checked={frequency_selected === "daily"}
                    onChange={(e) => {
                        const selected = e.target.checked ? "daily" : "never";
                        handleSetting({ message_type, notif_type, frequency_selected: selected });
                    }}
                />
            </div>
        );
    }

    return null;
};

/**
 * Adds missing settings for supported message types.
 *
 * @param {Array} settings - The array of existing settings.
 * @returns {Array} - The updated array of settings with missing ones added.
 */
const addMissingSetting = (settings) => {
    const missingSettings = SUPPORTED_MESSAGE_TYPES.filter((type) => {
        return !find(settings, { message_type: type.message_type });
    });

    // Add additional setting values to the user settings (like the setting's action type)
    const addedSettings = settings.map((setting) => {
        const supportedMessageTypeDefinition = find(SUPPORTED_MESSAGE_TYPES, {
            message_type: setting.message_type,
        });

        return {
            ...setting,
            ...(supportedMessageTypeDefinition.action_type && {
                action_type: supportedMessageTypeDefinition.action_type,
            }),
        };
    });

    const completedSettings = [...addedSettings, ...missingSettings];

    return completedSettings.sort(
        (a, b) => NOTIF_TYPE_SORT_ORDER[a.message_type] - NOTIF_TYPE_SORT_ORDER[b.message_type]
    );
};

const NotificationSetting = ({ notificationSetting, setProfileValues, notifyDailyProperty }) => {
    // if initially, no user notificationsetting is set, we create a default setting based on notify_daily_property, so no need to swithc areas
    const settings =
        notificationSetting.length > 0 ? notificationSetting : getDefaultSetting(notifyDailyProperty);

    // check if user has missing setting, we append those settings.
    const initialSetting = addMissingSetting(settings);

    const [notificationSettings, setNotificationSettings] = useState(initialSetting);

    useEffect(() => {
        if (!isEqual(initialSetting, notificationSettings)) {
            setNotificationSettings(initialSetting);
        }
    }, [initialSetting, notificationSettings]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Handles updating user notification settings based on the provided parameters.
     *
     * @param {string} settings.message_type - The type of the message.
     * @param {string} settings.notif_type - The type of the notification.
     * @param {string} settings.frequency_selected - The selected frequency for the notification.
     */

    const handleSetting = ({ message_type, notif_type, frequency_selected }) => {
        // pass by value
        const clonedSetting = [...notificationSettings];
        // find message type
        const foundSettingIndex = findIndex(clonedSetting, { message_type });

        if (foundSettingIndex > -1) {
            clonedSetting[foundSettingIndex][notif_type] = frequency_selected;
        }
        setProfileValues((prev) => ({
            ...prev,
            submit: true,
            data: {
                ...prev.data,
                notificationSetting: clonedSetting,
            },
        }));
    };
    return (
        <Fragment>
            <div className="section-group pb-2">
                <div className="d-flex justify-content-between gap-2">
                    <div className="section-label fw-bold">Notifications</div>
                </div>
            </div>

            {notificationSettings.map((el, i) => {
                const message_type = get(el, "message_type", "");
                const foundNotificationType = find(SUPPORTED_MESSAGE_TYPES, { message_type });
                const message_type_label = get(foundNotificationType, "label", "");
                const email_frequency = get(el, "email_frequency", "daily");
                const notification_frequencies = get(foundNotificationType, "supported_frequencies", "");
                const support_email = get(foundNotificationType, "support_email", false);
                const has_frequency_label = get(foundNotificationType, "has_frequency_label", false);
                const support_push = get(foundNotificationType, "support_push", false);
                const push_frequency = get(el, "push_frequency", "daily");
                const action_type = get(el, "action_type", "");

                return (
                    <React.Fragment key={`fragment ${message_type_label + i}`}>
                        {action_type === "dropdown" ? (
                            <div className="mb-4" key={message_type_label + i}>
                                <div className="mb-2">{message_type_label}</div>
                                <div className="d-flex gap-3 ms-1 flex-wrap">
                                    {support_email && (
                                        <div className="d-flex align-items-center gap-2 flex-wrap">
                                            <span>Email:</span>
                                            <NotifAction
                                                notif_type="email_frequency"
                                                index={`${i}-email`}
                                                frequency_selected={email_frequency}
                                                message_type={message_type}
                                                notification_frequencies={notification_frequencies}
                                                has_frequency_label={has_frequency_label}
                                                handleSetting={handleSetting}
                                                action_type={action_type}
                                            />
                                        </div>
                                    )}

                                    {support_push && (
                                        <div className="d-flex align-items-center gap-2">
                                            <span>Push:</span>
                                            <NotifAction
                                                notif_type="push_frequency"
                                                index={`${i}-push`}
                                                frequency_selected={push_frequency}
                                                message_type={message_type}
                                                notification_frequencies={notification_frequencies}
                                                has_frequency_label={has_frequency_label}
                                                handleSetting={handleSetting}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div
                                className="mb-3 d-flex flex-wrap align-items-center justify-content-between gap-3"
                                key={message_type_label + i}
                            >
                                <div className="mb-2">{message_type_label}</div>
                                <div className="d-flex justify-content-between gap-5">
                                    {support_email && (
                                        <div
                                            className={clsx(
                                                support_push && "ps-2 ps-sm-4",
                                                "d-flex align-items-center gap-3 lh-1"
                                            )}
                                        >
                                            {support_push && <span>Email:</span>}
                                            <NotifAction
                                                notif_type="email_frequency"
                                                index={`${i}-email`}
                                                frequency_selected={email_frequency}
                                                message_type={message_type}
                                                notification_frequencies={notification_frequencies}
                                                has_frequency_label={has_frequency_label}
                                                handleSetting={handleSetting}
                                                action_type={action_type}
                                            />
                                        </div>
                                    )}

                                    {support_push && (
                                        <div
                                            className={clsx(
                                                support_email && "ps-4",
                                                "d-flex align-items-center gap-3 lh-1"
                                            )}
                                        >
                                            {support_email && <span>Push:</span>}
                                            <NotifAction
                                                notif_type="push_frequency"
                                                index={`${i}-push`}
                                                frequency_selected={push_frequency}
                                                message_type={message_type}
                                                notification_frequencies={notification_frequencies}
                                                has_frequency_label={has_frequency_label}
                                                handleSetting={handleSetting}
                                                action_type={action_type}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                );
            })}
        </Fragment>
    );
};

NotificationSetting.propTypes = {
    frequency_selected: PropTypes.string,
    notif_type: PropTypes.string,
    index: PropTypes.number,
    message_type: PropTypes.string,
    handleSetting: PropTypes.func,
};

export default NotificationSetting;
