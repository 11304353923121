import { useRef, useState } from "react";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";

// Handles close and open state of custom dropdown

const useDropdownDisclosure = () => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const dropdownRef = useRef(null);

  useOnClickOutside(dropdownRef, () => setOpenDropdown(false));

  const handleOpen = () => setOpenDropdown(!openDropdown);

  return { dropdownRef, openDropdown, setOpenDropdown, handleOpen };
};

export default useDropdownDisclosure;
