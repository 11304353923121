let maxPoints = 100;
let bonusMaxPoints= 50;
let difficultyLevel = 3;

module.exports = {
    /**
     * Calculates the total score of the player and returns the feedback
     *
     * @param {Number} guess Guess price of the player
     * @param {Number} realPrice Real price of the property
     *
     * @returns {object} Object containing the score and feedback message
     */
    getScoreFeedback: function (guess, realPrice, isBonusGuess = false, showEmoji = false) {
        let userScore;
        
        if (guess - realPrice > realPrice) {
            userScore = 0;
        } else {
            userScore = maxPoints * (1 - Math.abs(realPrice - guess) / realPrice) ** difficultyLevel;
        }

        // We need to round the percentage to the nearest whole number
        userScore = parseFloat(userScore.toFixed(0));
        
        if(isBonusGuess && userScore > bonusMaxPoints){
            userScore = bonusMaxPoints
        }

        if (userScore >= 95) {
            return { score: userScore, feedback: `Bullseye!${showEmoji ? " 🎯" : ""}` };
        } else if (userScore >= 90) {
            return { score: userScore, feedback: `Ya nailed it!${showEmoji ? " 🥳" : ""}` };
        } else if (userScore >= 80) {
            return { score: userScore, feedback: `Not bad!${showEmoji ? " 😎" : ""}` };
        } else if (userScore >= 60) {
            return { score: userScore, feedback: `In range!${showEmoji ? " 👍" : ""}` };
        } else if (userScore >= 35) {
            return { score: userScore, feedback: `You're on the board!${showEmoji ? " ✅" : ""}` };
        } else {
            return { score: userScore, feedback: `A for effort!${showEmoji ? " 🤗" : ""}` };
        }
    }
};
