import { useEffect, useState } from "react";

const useIsWebview = () => {
    const [isWebview, setIsWebview] = useState(false);

    // Check if site is access through web view
    useEffect(() => {
        if (typeof window !== "undefined") {
            const standalone = window.navigator.standalone;
            const userAgent = window.navigator.userAgent.toLowerCase();
            const safari = /safari/.test(userAgent);
            const ios = /iphone|ipod|ipad/.test(userAgent);

            if (ios) {
                if (!standalone && !safari) {
                    // iOS webview
                    setIsWebview(true);
                } else {
                    setIsWebview(false);
                }
            } else {
                if (userAgent.includes("wv")) {
                    // Android webview
                    setIsWebview(true);
                } else {
                    setIsWebview(false);
                }
            }
        }
    }, []);

    return { isWebview, setIsWebview };
};

export default useIsWebview;
