module.exports = {
    TOAST_MESSAGES: {
        CHOOSE_AREA: "First, choose a neighborhood or town to play in",
        SELECT_AREA_FIRST: "You must choose an Area first. Redirecting to Area Selection screen...",
        ONE_AREA_PER_DAY: "You can only play one area per day",
        INVALID_PROPERTY_GUESS:
            "This property is not part of the current daily property set. Please refresh to get the latest set of properties.",
        SHARE_CLIPBOARD_SUCCESS: "Share text copied to clipboard",
        SHARE_CLIPBOARD_FAIL: "There was an error when copying share text to clipboard",
        SHARE_MOBILE_FAIL: "There was an error when trying to share text",
        NEW_VERSION: {
            TITLE: "A new version of PriceMe is available",
            DESCRIPTION: "Refresh to get the latest version.",
        },
        GUEST_PROFILE_ACCESS: "You must be logged in to access your profile. Redirecting to login page",
        DUPLICATE_GUESS: "You can only submit a guess once per property.",
        LOCATION: {
            INVALID: "Not enough properties! Try switching up your location.",
            INSUFFICIENT_PROPERTIES_BY_YEAR:
                "Couldn’t find enough properties sold within the last year 😕 Try switching up your location!",
            NO_SELECTED: "Please select a location first before you play.",
        },
        CHALLENGE_EXPIRED: "Challenge expired! Back to start.",
        CHALLENGE_ACCEPTED: "You've already accepted this challenge.",
        OWN_CHALLENGE_LINK:
            "Oops! You can't challenge yourself. How about sending the challenge to a friend instead?",
        INVALID_CHALLENGE_URL:
            "Oops! The challenge URL seems to be incorrect. Please review your challenge and try again.",
    },
};
