import { isMobile } from "react-device-detect";
import toast from "react-hot-toast";
import { TOAST_MESSAGES } from "../lang/toast-messages.lang";
import { isNil } from "lodash";

/**
 * Triggers the share functionality.
 *
 * This function copies the share text to the clipboard for desktop users and uses the native mobile
 * share functionality for mobile users.
 *
 * @param {Object} shareData - The share data containing web and mobile properties.
 * @param {Object} shareData.web - The share data for web platforms.
 * @param {string} shareData.web.text - The text to be copied to the clipboard for web platforms.
 * @param {Object} shareData.mobile - The share data for mobile platforms.
 * @throws {Error} Throws an error if there is an issue with the share functionality.
 * 
 * @example
 * // Example usage:
 * const shareData = {
 *   web: {
 *     text: "Check out this amazing content!",
 *   },
 *   mobile: {
 *     title: "Share Content",
 *     text: "Check out this amazing content!",
 *     url: "https://example.com",
 *   },
 * };
 * await triggerShare(shareData);
 */
export const triggerShare = async (shareData) => {
    const { web, mobile } = shareData;

    // Copy the share text to clipboard for desktop users
    if (!isMobile) {
        await navigator.clipboard.writeText(web.text);

        toast(TOAST_MESSAGES.SHARE_CLIPBOARD_SUCCESS, {
            id: "SHARE_CLIPBOARD_SUCCESS",
        });
    } else {
        try {
            // Use native mobile share functionality for mobile users
            if (navigator.share) {
                await navigator.share({ ...mobile });
            }
        } catch (err) {
            // Only show the error message when its not a share abort error (the user cancelled sharing)
            if (!isNil(err?.name) && err.name !== "AbortError") {
                toast.error(`${TOAST_MESSAGES.SHARE_MOBILE_FAIL}: ${err}`, {
                    id: "SHARE_MOBILE_FAIL",
                });
            }
        }
    }
};
