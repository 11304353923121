import { useQuery } from "@tanstack/react-query";
import Modal from "react-bootstrap/Modal";
import Inputmask from "inputmask";
import { Tooltip } from "react-tooltip";
import { useEffect } from "react";
import "./results-modal.scss";
import AppButton from "../../common/buttons/app-button";
import FeedbackIcons from "../../../helpers/FeedbackIcons";
import { formatPrice } from "../../../helpers/helpers";
import { X } from "react-bootstrap-icons";
import { get } from "lodash";
import { createChallengeLink } from "../../../helpers/helpers";
import { getUserGuessesPerPeriod } from "../../../services/UserService";
import { useUser } from "../../../context/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import { TOOLTIP_TEXT } from "../../../lang/tooltip-text.lang";
import config from "../../../config";
import useGAEvent from "../../../hooks/useGAEvent";

export default function ResultsModal(props) {
    const { userState } = useUser();
    const { isAuthenticated } = useAuth0();
    const { sendEvent } = useGAEvent();

    const userGuesses = useQuery({
        queryKey: ["user-guesses-result", userState?.id],
        queryFn: () =>
            getUserGuessesPerPeriod({
                user_id: userState?.id,
            }),
        cacheTime: 0,
        enabled: isAuthenticated && !!userState?.id,
    });

    const guessesCount = userGuesses?.data?.data?.count?.total ?? 0;

    useEffect(() => {
        // Apply input mask when the modal is show and the real price has value
        if (props.show && props.realPrice !== "") {
            Inputmask({
                alias: "numeric",
                groupSeparator: ",",
                autoGroup: true,
                digits: 0,
                radixPoint: "",
                prefix: "$",
            }).mask("real-price-result");
        }
    }, [props.realPrice, props.show]);

    let realPrice = get(props, "realPrice", 0);
    realPrice = formatPrice(realPrice)

    return (
        <Modal
            show={props.show}
            onHide={() => props.handleClose(false)}
            centered
            keyboard={false}
            backdrop
            data-cy="modal-container-result"
            className="modal-container-result"
        >
            <div className="close-icon-header">
                <div className="header-text">{props.results.feedback}</div>
                <X className="close" size={30} onClick={() => props.handleClose(false)} />
            </div>
            <Modal.Body className="pt-0 pb-0">
                <FeedbackIcons userScore={props.results.score} />
                <div className="guess">
                    <p>You Guessed:</p>
                    <p>{formatPrice(props.guessPrice)}</p>
                </div>
                <div className="guess">
                    <p>Real Price:</p>
                    <p>{realPrice}</p>
                </div>
                <div className="points">+{props.results.score} points</div>
                {isAuthenticated && (
                    <div>
                        <p>
                            <span className="has-tooltip" data-tooltip-id="challenge-friend">
                                <AppButton
                                    theme="gold"
                                    className="w-75 mx-auto"
                                    onClick={async () => {
                                        sendEvent("challenge_friend_button_click", {
                                            isAuthenticated,
                                            mlsId: props.mlsId ?? null
                                        });
                                        await createChallengeLink(props.currentScoreId, props.results.score);
                                        props.handleClose(true);
                                    }}
                                >
                                    Challenge a Friend
                                </AppButton>
                            </span>
                        </p>
                        {props.propertyIndex === 0 && (
                            <Tooltip
                                id="challenge-friend"
                                anchorSelect="[data-tooltip-id='challenge-friend']"
                                content={TOOLTIP_TEXT.CHALLENGE_FRIEND}
                                place="bottom"
                            />
                        )}
                    </div>
                )}
                <AppButton className="results-button" theme="purple" onClick={() => props.handleClose(true)}>
                    {guessesCount === props.totalProperties || guessesCount === config.MAXIMUM_GUESS
                        ? "Finished"
                        : "Keep Going"}
                </AppButton>
            </Modal.Body>
        </Modal>
    );
}
