import "./terms-of-service.scss";

export default function TermsOfService() {
    return (
        <div className="container terms-of-service pb-4">
            <h1 className="px-0 pt-4 app-text-title text-center">Terms of Use for PriceMe</h1>
            <p>Effective Date: July 1, 2024</p>
            <p>
                These Terms of Use ("Terms") govern your use of the PriceMe application ("Application")
                provided by PriceMe ("we", "us", or "our").
            </p>

            <h3>1. Acceptance of Terms</h3>
            <p>
                By accessing and using the Application, you accept and agree to be bound by these Terms. If
                you do not agree to these Terms, you may not access or use the Application.
            </p>

            <h3>2. Changes to Terms</h3>
            <p>
                We reserve the right to modify these Terms at any time. Any changes will be effective upon
                posting of the revisions on the Application. Your continued use of the Application after any
                changes constitutes your acceptance of the new Terms.
            </p>

            <h3>3. User Account</h3>
            <p>
                In order to use certain features of the Application, you may need to register for an account.
                You agree to provide accurate and complete information during the registration process and to
                update such information as it changes. You are responsible for maintaining the confidentiality
                of your account password.
            </p>

            <h3>4. User Conduct</h3>
            <p>
                You agree to not use the Application in any way that is illegal, harmful, or infringes upon
                others' rights. You are solely responsible for your conduct while using the Application.
            </p>

            <h3>5. Intellectual Property Rights</h3>
            <p>
                The Application and its entire contents, features, and functionality (including all
                information, software, text, displays, images, video, and audio, and the design, selection,
                and arrangement thereof) are owned by PriceMe, its licensors, or other providers of such
                material and are protected by international copyright, trademark, patent, trade secret, and
                other intellectual property or proprietary rights laws.
            </p>

            <h3>6. Termination</h3>
            <p>
                We reserve the right, in our sole discretion, to terminate your access to all or part of the
                Application, with or without notice.
            </p>

            <h3>7. Limitation of Liability</h3>
            <p>
                IN NO EVENT WILL PRICEME BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
                PUNITIVE DAMAGES RESULTING FROM YOUR ACCESS TO OR USE OF, OR INABILITY TO ACCESS OR USE, THE
                APPLICATION, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, OR ANY
                OTHER LEGAL THEORY.
            </p>

            <h3>8. Indemnification</h3>
            <p>
                You agree to indemnify, defend, and hold harmless PriceMe, its officers, directors, employees,
                agents, and affiliates from and against any and all claims, liabilities, damages, losses,
                costs, expenses, fees (including reasonable attorneys' fees) that we may incur as a result of
                or arising from your violation of these Terms of Use, your use or misuse of the Application,
                or your violation of any rights of another. This obligation will survive the termination or
                expiration of these Terms of Use and your use of the Application.
            </p>

            <h3>9. Disclaimer of Warranties</h3>
            <p>
                THE APPLICATION IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. PRICEME EXPRESSLY
                DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO
                THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                NON-INFRINGEMENT. PRICEME MAKES NO WARRANTY THAT (I) THE APPLICATION WILL MEET YOUR
                REQUIREMENTS; (II) THE APPLICATION WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE; (III)
                THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE APPLICATION WILL BE ACCURATE OR RELIABLE;
                OR (IV) ANY ERRORS IN THE APPLICATION WILL BE CORRECTED.
            </p>
            <h3>10. Governing Law</h3>
            <p>
                These Terms shall be governed by and interpreted in accordance with the laws of the state of
                Delaware.
            </p>
            <h3>11. User-Generated Content</h3>
            <p>
                You retain all rights, title, and interest in and to any content that you create, submit,
                post, or display on or through the Application ("User-Generated Content"). By submitting,
                posting, or displaying User-Generated Content on or through the Application, you grant PriceMe
                a worldwide, non-exclusive, royalty-free, transferable, sublicensable license to use,
                reproduce, modify, adapt, publish, translate, create derivative works from, distribute,
                perform, and display such content in connection with the operation, promotion, and improvement
                of the Application or for such other purposes as we may see fit. This license continues even
                if you stop using our Application. You represent and warrant that you have all the rights,
                power, and authority necessary to grant the rights granted herein to any User-Generated
                Content that you submit.
            </p>
            <h3>12. Contact Us</h3>
            <p>
                If you have any questions or concerns about these Terms, please contact us at{" "}
                <a href="mailto:admin@priceme.game">admin@priceme.game</a>.
            </p>
            <h3>13. DMCA Notice</h3>
            <p>
                The Digital Millennium Copyright Act of 1998, 17 U.S.C. § 512 (the “DMCA”) provides recourse
                for copyright owners who believe that material appearing on the Internet infringes their
                rights under U.S. copyright law. If you believe in good faith that any content or material
                made available in connection with our website or services infringes your copyright, you (or
                your agent) may send us a notice requesting that the content or material be removed, or access
                to it blocked. Notices must be sent in writing by email to:{" "}
                <a href="mailto:feedback@priceme.game">feedback@priceme.game</a> complaint. The DMCA requires
                that your notice of alleged copyright infringement include the following information: (1)
                description of the copyrighted work that is the subject of claimed infringement; (2)
                description of the alleged infringing content and information sufficient to permit us to
                locate the content; (3) contact information for you, including your address, telephone number
                and email address; (4) a statement by you that you have a good faith belief that the content
                in the manner complained of is not authorized by the copyright owner, or its agent, or by the
                operation of any law; (5) a statement by you, signed under penalty of perjury, that the
                information in the notification is accurate and that you have the authority to enforce the
                copyrights that are claimed to be infringed; and (6) a physical or electronic signature of the
                copyright owner or a person authorized to act on the copyright owner’s behalf. Failure to
                include all of the above information may result in the delay of the processing of your
                request.
            </p>
        </div>
    );
}
