/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./home-screen-cta.scss";
import { IoCloseSharp } from "react-icons/io5";
import { isIOS } from "react-device-detect";
import { AnimatePresence, motion } from "framer-motion";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import priceMeFinger from "../../../../assets/icons/price-me-finger.svg";
import priceMeShare from "../../../../assets/icons/price-me-share.svg";
import priceMeAdd from "../../../../assets/icons/price-me-add.svg";
import priceMeThreedots from "../../../../assets/icons/price-me-three-dots.svg";
import priceMeAndroid from "../../../../assets/icons/price-me-android.svg";
import useModalState from "../../../../hooks/useModalState";
import AppButton from "../../../../components/common/buttons/app-button";

// Animation variants for the motion component.
const variants = {
    drawer: {
        closed: {
            bottom: "-50%",
            opacity: 0,
            position: "fixed",
            width: "100%",
            zIndex: "99",
        },
        open: {
            bottom: "0%",
            opacity: 1,
            position: "fixed",
            width: "100%",
            zIndex: "100",
        },
    },
    overlay: {
        closed: {
            opacity: 0,
            position: "fixed",
            width: "100%",
            zIndex: "98",
        },
        open: {
            opacity: 0.15,
            position: "fixed",
            width: "100%",
            zIndex: "99",
        },
    },
};

const AddToHomeScreenCta = () => {
    const [, updateValue] = useLocalStorage("isAddedToHomeScreen");
    const { setModalId, isOpen } = useModalState();

    const onClose = () => setModalId("");
    return (
        <>
            <AnimatePresence mode="sync">
                {isOpen("ADD_TO_HOME_SCREEN_MODAL") && (
                    <>
                        <motion.div
                            initial="closed"
                            animate="open"
                            exit="closed"
                            variants={variants.overlay}
                            key="overlay"
                            className="overlay"
                            onClick={onClose}
                            transition={{ delay: 0.25 }}
                        />
                        <motion.div
                            key="drawer"
                            initial="closed"
                            animate="open"
                            exit="closed"
                            variants={variants.drawer}
                            className="cta-container"
                        >
                            <div className="header">
                                <h5 className="mb-0 app-text-title">Make it easy to find PriceMe</h5>
                                <button onClick={onClose}>
                                    <IoCloseSharp fontSize={26} />
                                </button>
                            </div>
                            <div className="body">
                                <p className="instruction">
                                    Add a bookmark to your home screen to quickly get back to your game
                                </p>
                                <div className="steps-container">
                                    <div className="step-item">
                                        <div>
                                            {isIOS ? (
                                                <div className="share-icon">
                                                    <div className="content">
                                                        <img
                                                            className="share"
                                                            src={priceMeShare}
                                                            alt="price-me-share"
                                                        />
                                                        <img
                                                            className="ios-pointer"
                                                            src={priceMeFinger}
                                                            alt="price-me-pointer"
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="share-icon">
                                                    <div className="content">
                                                        <img
                                                            className="share"
                                                            src={priceMeThreedots}
                                                            alt="price-me-three-dots"
                                                        />
                                                        <img
                                                            className="android-pointer"
                                                            src={priceMeFinger}
                                                            alt="price-me-pointer"
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <p className="my-0 mt-3">
                                            {isIOS ? "Tap your share icon" : "Tap your Menu icon"}
                                        </p>
                                    </div>
                                    <div className="step-item">
                                        <div>
                                            {isIOS ? (
                                                <div className="add-icon">
                                                    <div className="content">
                                                        <img
                                                            className="share"
                                                            src={priceMeAdd}
                                                            alt="price-me-add"
                                                        />
                                                        <img
                                                            className="ios-pointer"
                                                            src={priceMeFinger}
                                                            alt="price-me-pointer"
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="add-icon">
                                                    <div className="content">
                                                        <img
                                                            className="share"
                                                            src={priceMeAndroid}
                                                            alt="price-me-android"
                                                        />
                                                        <img
                                                            className="android-pointer"
                                                            src={priceMeFinger}
                                                            alt="price-me-pointer"
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <p className="my-0 mt-3">Tap 'Add to Home Screen'</p>
                                    </div>
                                    <div className="step-item">
                                        <div>
                                            <div className="finish-icon">
                                                <div className="content">
                                                    <div className="finish-btn">
                                                        <p className="mb-0">Add</p>
                                                    </div>
                                                    <img
                                                        className="pointer"
                                                        src={priceMeFinger}
                                                        alt="price-me-pointer"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <p className="my-0 mt-3">Tap 'Add' to finish</p>
                                    </div>
                                </div>
                                <div className="mt-4 d-flex flex-column align-items-center justify-content-center mx-auto gap-4">
                                    <AppButton
                                        onClick={onClose}
                                        theme="purple"
                                        variant="solid"
                                        className="remind-me-btn"
                                    >
                                        Remind me later
                                    </AppButton>

                                    <button
                                        className="align-self-center app-text-link fw-normal"
                                        onClick={() => {
                                            updateValue(true);
                                            onClose();
                                        }}
                                    >
                                        Don't ask again
                                    </button>
                                </div>
                            </div>
                        </motion.div>
                    </>
                )}
            </AnimatePresence>
        </>
    );
};

export default AddToHomeScreenCta;
