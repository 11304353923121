import React from "react";

/**
 * Combines React components into a single composite component.
 *
 * This function takes an array of React components and composes them into a single component.
 * The components are applied sequentially, wrapping each subsequent component around the previous one.
 *
 * @param {...React.Component} components - An array of React components to be combined.
 * 
 * @returns {React.Component} A composite React component.
 */
export const combineComponents = (...components) => {
    return components.reduce(
        (AccumulatedComponents, CurrentComponent) => {
            return ({ children }) => {
                return (
                    <AccumulatedComponents>
                        <CurrentComponent>{children}</CurrentComponent>
                    </AccumulatedComponents>
                );
            };
        },
        ({ children }) => <>{children}</>
    );
};
