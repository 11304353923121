import React from "react";

import Modal from "react-bootstrap/Modal";
import "./warning-modal.scss";
import { PiWarningBold } from "react-icons/pi";
import useModalState from "../../../hooks/useModalState";

const WarningModal = () => {
    const { setModalId, modalContent, isOpen } = useModalState();

    const handleContinue = () => {
        if (!modalContent?.cb) {
            setModalId("");
        } else {
            modalContent.cb();
        }
    };

    return (
        <Modal
            show={isOpen("WARNING_MODAL")}
            onHide={() => setModalId("")}
            centered
            className="warning-modal"
            id="warning-modal"
        >
            <Modal.Body className="px-3 py-4 d-flex justify-content-center flex-column align-items-center">
                <PiWarningBold fontSize={72} className="mb-2" />
                <div className="text-center">
                    <h3 className="fw-bold">{modalContent.title}</h3>
                    <p>{modalContent.message}</p>
                </div>
                <button onClick={handleContinue}>{modalContent.ctaTitle}</button>
            </Modal.Body>
        </Modal>
    );
};

export default WarningModal;
