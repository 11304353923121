import React from "react";
import "./invite-friends-cta.scss";
import { isMobile } from "react-device-detect";
import { getAreaById } from "../../../services/AreaService";
import { useQuery } from "@tanstack/react-query";
import { useUser } from "../../../context/UserContext";
import { Card } from "react-bootstrap";
import Mailto from "../mail-to/mail-to";
import { getMobileOperatingSystem } from "../../../helpers/helpers";
import { useAuth0 } from "@auth0/auth0-react";
import useGAEvent from "../../../hooks/useGAEvent";
import PropTypes from "prop-types";
import { useScore } from "../../../context/ScoreContext";
import config from "../../../config";

const InviteFriendsCTA = ({ rank }) => {
    const { isAuthenticated } = useAuth0();
    const { userState } = useUser();
    const { totalScore } = useScore();
    const { isAndroid } = getMobileOperatingSystem();
    const { sendEvent } = useGAEvent();

    /**
     * A React Query hook to fetch the current area information based on the user's current_area_id.
     *
     * @constant {Object} currentArea
     * @property {Array} queryKey - An array containing the query key with the user's current_area_id.
     * @property {Function} queryFn - The function to fetch the area data based on the user's current_area_id.
     * @property {boolean} enabled - A flag to enable or disable the query based on the presence of user's current_area_id.
     */
    const currentArea = useQuery({
        queryKey: ["current-area", userState?.current_area_id],
        queryFn: () => getAreaById(userState?.current_area_id),
        enabled: !!userState?.current_area_id,
    });

    /**
     * The message body template for sharing information about the PriceMe game.
     *
     * @constant {string} messageBody
     * @description This template is used to compose a message inviting others to play PriceMe. It includes the current area's name and a link to the game's website.
     * @example
     * "Have you played PriceMe? It's a home price guessing game. It shows you homes that recently sold near you, and you guess the price. I'm playing the Naperville area. Check it out! \n\n ${config.BASE_URL}"
     */
    const messageBody = `Have you played PriceMe? It's a home price guessing game. It shows you homes that recently sold near you, and you guess the price. I'm playing the ${
        currentArea?.data?.name ?? ""
    } area. Check it out! \n\n ${config.BASE_URL} \n\n`;

    /**
     * Handles the click event when inviting friends via the leaderboard.
     * This function tracks an event when users click to invite friends to the leaderboard, providing information about the user's status (registered or guest).
     */
    const onInviteFriendsCardClick = () => {
        sendEvent("invite_friend_leaderboard", {
            isAuthenticated,
            referral_code: localStorage.getItem("referral_code"),
            finalScore: totalScore,
            userRank: rank ?? 0,
        });
    };

    return (
        <>
            <Card className="invite-friends-card" onClick={onInviteFriendsCardClick}>
                <Card.Body>
                    <Card.Text as="div">
                        {isMobile ? (
                            <a
                                className="invite-button-mobile"
                                disabled={currentArea.isFetching}
                                href={isAndroid ? `sms:?body=${messageBody}` : `sms:&body=${messageBody}`}
                            >
                                <div>
                                    It's quiet in here... <span className="link-text">invite</span> a friend!
                                </div>
                            </a>
                        ) : (
                            <Mailto className="w-full d-block" subject="PriceMe" body={messageBody}>
                                <div>
                                    It's quiet in here... <span className="link-text">invite</span> a friend!
                                </div>
                            </Mailto>
                        )}
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    );
};

InviteFriendsCTA.propTypes = {
    rank: PropTypes.number,
};

export default InviteFriendsCTA;
