module.exports = {
    INIT_PROPERTY_VALUES: {
        id: "",
        street_address: "",
        city: "",
        state: "",
        zip_code: "",
        neighborhood: null,
        public_remarks: null,
        lot_dimensions: null,
        price: 0,
        bedrooms: 0,
        bathrooms: "0",
        property_type: "",
        square_footage: 0,
        parking_spots: 0,
        parking_type: "",
        year_built: 0,
        exterior_features: "",
        interior_features: "",
        heating: "",
        cooling: "",
        laundry_features: "",
        image_urls: [],
        created_at: "",
        updated_at: "",
        deleted_at: null,
        area_id: "",
        AreaId: "",
    },
};
