import Trophy from "../../assets/icons-v2/trophy.svg";
import CustomSvg from "../common/custom-svg/custom-svg";
import Email from "../../assets/icons-v2/email.svg";
import Location from "../../assets/icons-v2/location.svg";
import Profile from "../../assets/icons-v2/profile.svg";
import Logout from "../../assets/icons-v2/signout.svg";
import Help from "../../assets/icons-v2/help.svg";
import Share from "../../assets/icons-v2/share.svg";

const iconSize = { height: 25, width: 25 };

export const SIDEMENU_ITEMS = [
    {
        label: "My Profile",
        url: "",
        icon: <CustomSvg src={Profile} size={iconSize} />,
        isRegistered: true,
    },
    {
        label: "Sign In",
        url: "",
        icon: <CustomSvg src={Profile} size={iconSize} />,
        isRegistered: false,
    },
    {
        label: "Leaderboard",
        url: "/leaderboard",
        icon: <CustomSvg src={Trophy} size={iconSize} />,
    },
    {
        label: "Switch Location",
        url: "",
        icon: <CustomSvg src={Location} size={iconSize} />,
        isRegistered: true,
    },
    {
        label: "Invite a friend",
        url: ``,
        icon: <CustomSvg src={Share} size={iconSize} />,
    },
    {
        label: "Send Feedback",
        url: `mailto:feedback@priceme.game?subject=${encodeURIComponent("Feedback for PriceMe")}`,
        icon: <CustomSvg src={Email} size={iconSize} />,
    },
    {
        label: "FAQ",
        url: "/faq",
        icon: <CustomSvg src={Help} size={iconSize} />,
    },
    {
        label: "Sign Out",
        url: "",
        icon: <CustomSvg src={Logout} size={iconSize} />,
        isRegistered: true,
    },
];
