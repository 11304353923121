import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

const ReferralWrapper = () => {
    const [searchParams] = useSearchParams();
    // Get referral code from url query
    const referralCode = searchParams.get("ref") ?? null;

    useEffect(() => {
        /**
         * Set referral code in local storage if exists in url query
         */
        if (referralCode) {
            localStorage.setItem("referral_code", referralCode);
        }
    }, [referralCode]);

    return null;
};

export default ReferralWrapper;
