import React, { useRef, useEffect, useState } from "react";
import "./progress-indicator.scss";
import PropTypes from "prop-types";
import { FaStar, FaTimes } from "react-icons/fa";
import { isEmpty, isNil, union } from "lodash";
import moment from "moment";
import { IoIosArrowBack, IoIosArrowForward, IoIosLock } from "react-icons/io";
import StepPulseAnimation from "./components/StepPulseAnimation";
import clsx from "clsx";

const ProgressIndicator = (props) => {
    const hasBottomPoints = props?.hasBottomPoints;
    const daysScored = props.daysScored?.map((score) => ({
        ...score,
        totalPoints: score.scores
            .map((score) => score.points ?? score.score)
            .reduce((prev, curr) => prev + curr, 0),
        isPracticeScore: score.scores.filter((score) => score?.is_practice_point ?? false).length !== 0,
    }));
    const activeStepMapping = isNil(props?.activeStepMapping)
        ? Array(props.progressLength).fill(false)
        : props.activeStepMapping;

    const isPlusMinus = props.type === "plus-minus";
    const isScrollable = props.progressLength > 3 && (props.type === "properties" || isPlusMinus);
    const containerRef = useRef(null);
    const [showPulse, setShowPulse] = useState(true);
    const [scrollPosition, setScrollPosition] = useState(0);

    /**
     * Event handler to simulate scrolling upon mousedown or dragging.
     *
     * @param {Object} e - The event object generated by mouse down event for dragging.
     *
     * @returns {void}
     */
    const handleDragScroll = (e) => {
        containerRef.current.classList.add("grabbing"); // Add grabbing class when dragging starts

        const startX = e.pageX;
        const startScrollLeft = containerRef.current.scrollLeft;

        const mouseMoveHandler = (e) => {
            const deltaX = e.pageX - startX;
            containerRef.current.scrollLeft = startScrollLeft - deltaX;
        };

        const mouseUpHandler = () => {
            containerRef.current.classList.remove("grabbing"); // Remove grabbing class when dragging ends
            window.removeEventListener("mousemove", mouseMoveHandler);
            window.removeEventListener("mouseup", mouseUpHandler);

            setScrollPosition(containerRef.current.scrollLeft);
        };

        window.addEventListener("mousemove", mouseMoveHandler);
        window.addEventListener("mouseup", mouseUpHandler);
    };

    /**
     * Event handler to simulate scrolling upon mouse click on the arrow icon.
     *
     * @param {string} direction - The direction to where the scroll goes upon clicking the arrow icon.
     *
     * @returns {void}
     */
    const handleScroll = (direction) => {
        if (containerRef) {
            const scrollOptions = {
                left: direction === "left" ? 0 : containerRef.current.scrollWidth,
                behavior: "smooth",
            };
            containerRef.current.scrollTo(scrollOptions);
            setScrollPosition(scrollOptions.left);
        }
    };

    /**
     * Checks if the given day name corresponds to the provided day index.
     *
     * @param {string} dayName - The name or index of the day.
     * @param {number} dayIndex - The index of the day to compare with.
     *
     * @returns {boolean} Returns true if the day name corresponds to the provided day index, false otherwise.
     */
    const isCurrentDay = (dayName, dayIndex) => parseInt(dayName === "0" ? "7" : dayName) === dayIndex + 1;

    /**
     * Handle a step (property) click.
     *
     * @param {number} intIndex - The index of the clicked step.
     */
    const handleStepClick = (intIndex, unlockedIndex) => {
        if (props.onStepClick) {
            props.onStepClick(intIndex, unlockedIndex);
        }
    };

    /**
     * Check if a specific day is missed based on the scores and the index.
     *
     * @param {number} i - The index of the day to check.
     *
     * @returns {boolean} True if the day is missed, otherwise false.
     */
    const isMissedDay = (i) => {
        if (!!props?.daysScored) {
            const combinedScores = union(
                daysScored
                    ?.map((el) => el.scores)
                    ?.reduce((prev, curr) => prev.concat(curr), [])
                    .map((score) => moment(score.created_at).format("d"))
            );
            return !combinedScores?.includes((i + 1).toString());
        }
        return false;
    };

    /**
     * Checks if a given day is a practice day based on the provided parameters.
     *
     * @param {number} i - The index or identifier of the day.
     *
     * @returns {boolean} Returns true if the day is a practice day, false otherwise.
     */
    const isPracticeDay = (i) => {
        if (!!props?.daysScored && !isEmpty(daysScored) && !isMissedDay(i)) {
            return (
                daysScored?.filter(
                    (dayScore) => isCurrentDay(dayScore.dayName, i) && dayScore.isPracticeScore
                )?.length !== 0 ?? false
            );
        }
        return false;
    };

    /**
     * Render progress steps for a component.
     *
     * @returns {ReactNode} An array of React elements representing the progress steps.
     */
    const renderProgressSteps = () => {
        let arProgressSteps = [];

        for (let i = 0; i < props.progressLength; i++) {
            const isLocked = props?.arrProperties && props.arrProperties[i]?.is_locked;

            arProgressSteps.push(
                <React.Fragment key={"progress-indicators" + i}>
                    <div
                        className={clsx("step", i === props.index && "current", isScrollable && "scrollable")}
                        onClick={(e) => (isLocked ? handleStepClick(null, i) : handleStepClick(i))}
                        data-cy="progress-indicator-step"
                        data-tooltip-id={`unlock-property-tooltip-${i}`}
                    >
                        {isMissedDay(i) && i < props.latestIndex && (
                            <div className="step-missed">
                                <FaTimes />
                            </div>
                        )}

                        {isPracticeDay(i) && i < props.latestIndex ? (
                            <div className="practice-day">
                                <FaStar />
                            </div>
                        ) : null}

                        <div
                            className={clsx(
                                "step-icon",
                                {
                                    active:
                                        (props.type === "days" &&
                                            i <= props.latestIndex &&
                                            !isMissedDay(i)) ||
                                        (props.type === "properties" && activeStepMapping[i]) ||
                                        (isPlusMinus && props.latestIndex > i),
                                },
                                isScrollable && "scrollable"
                            )}
                            data-cy="progress-indicator-step-icon"
                        >
                            {props.progressContent ? (
                                props.progressContent[i]
                            ) : isLocked ? (
                                <IoIosLock />
                            ) : (
                                i + 1
                            )}
                        </div>
                        {showPulse && isScrollable && !isPlusMinus && (
                            <StepPulseAnimation
                                stepStatus={
                                    !activeStepMapping[i] && i !== props.index
                                        ? "inactive"
                                        : i === props.index
                                          ? "active"
                                          : null
                                }
                            />
                        )}

                        {hasBottomPoints &&
                            daysScored?.map(
                                (score, dayScoreIndex) =>
                                    isCurrentDay(score.dayName, i) && (
                                        <span
                                            className="step-total-points"
                                            key={"step-total-points-" + dayScoreIndex}
                                        >
                                            {score.totalPoints}
                                        </span>
                                    )
                            )}
                    </div>
                    {i + 1 !== props.progressLength ? (
                        <div className={clsx("indicator-line", i < props.latestIndex && "active")}></div>
                    ) : null}
                </React.Fragment>
            );
        }

        return arProgressSteps;
    };

    /**
     * Render dynamic arrow direction based on the direction property provided.
     *
     * @param {string} direction - The direction of arrow that will be rendered either left or right.
     *
     * @returns {ReactNode} Returns a react element with arrow icon facing either left or right based on direction prop.
     */
    const renderScrollArrows = (direction) => {
        const isLeftArrow = direction === "left";
        const isRightArrow = direction === "right";
        const shouldRenderArrow = isScrollable && containerRef.current && !isPlusMinus;

        if (isLeftArrow && scrollPosition <= 0) {
            return null; // Hide left arrow if at the beginning
        }

        if (
            isRightArrow &&
            scrollPosition >= containerRef.current?.scrollWidth - containerRef.current?.clientWidth
        ) {
            return null; // Hide right arrow if at the end
        }

        return (
            shouldRenderArrow && (
                <span className="scroll-arrow" onClick={() => handleScroll(direction)}>
                    {direction === "left" ? <IoIosArrowBack /> : <IoIosArrowForward />}
                </span>
            )
        );
    };

    useEffect(() => {
        if (isScrollable && containerRef.current) {
            const nextStepPosition = props.index * (containerRef.current.scrollWidth / props.progressLength);
            const scrollOptions = {
                left: nextStepPosition,
                behavior: "smooth",
            };
            containerRef.current.scrollTo(scrollOptions);
        }
    }, [activeStepMapping]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let timeout;
        if (showPulse) {
            timeout = setTimeout(() => {
                setShowPulse(false);
            }, 3000); // stop animation after 3 seconds
        }
        return () => clearTimeout(timeout);
    }, [showPulse]);

    return (
        <>
            {renderScrollArrows("left")}
            <div
                data-cy="progress-indicator"
                className={clsx("progress-indicators", isScrollable && "scrollable")}
                id={props.type}
                ref={containerRef}
                onMouseDown={(e) => isScrollable && handleDragScroll(e)}
                data-tooltip-id="scrollability-tooltip"
            >
                {renderProgressSteps()}
            </div>
            {renderScrollArrows("right")}
        </>
    );
};

ProgressIndicator.propTypes = {
    index: PropTypes.number,
    latestIndex: PropTypes.number,
    onStepClick: PropTypes.func,
    progressContent: PropTypes.array,
    activeStepMapping: PropTypes.array,
    progressLength: PropTypes.number.isRequired,
    arrProperties: PropTypes.array,
};

export default ProgressIndicator;
