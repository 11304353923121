import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import { get, random } from "lodash";
import Sticky from "react-sticky-el";
import MoonLoader from "react-spinners/MoonLoader";

import PropertyInfo from "../../components/common/PropertyInfo";
import CustomButton from "../../components/common/buttons/custom-button/custom-button";
import CustomSvg from "../../components/common/custom-svg/custom-svg";
import ProgressIndicator from "../../components/common/progress-indicator/progress-indicator";
import PlusMinusResultModal from "../../components/modals/PlusMinusResultModal";

import { PLUS_MINUS_VERSIONS } from "../../constants/plus-minus-versions";
import { getRandomProperty } from "../../services/UserService";
import { formatPrice } from "../../helpers/helpers";
import useGAEvent from "../../hooks/useGAEvent";

import DownArrow from "../../assets/icons-v2/thick-down-arrow.png";
import UpArrow from "../../assets/icons-v2/thick-up-arrow.png";

/**
 * PlusMinus game component that allows users to guess whether the price is higher or lower.
 *
 * @component
 * @param {object} props - The component props.
 * @param {string} props.version - The version of the PlusMinus game.
 * @returns {JSX.Element} The PlusMinus game interface.
 */
export default function PlusMinus({ version }) {
    const bodyRef = useRef(null);
    const [guessPrice, setGuessPrice] = useState(null);
    const [points, setPoints] = useState(0);
    const [variance, setVariance] = useState(null);
    const [property, setProperty] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showNextButton, setShowNextButton] = useState(false);
    const { sendEvent } = useGAEvent();
    const currentVersion = PLUS_MINUS_VERSIONS[version];
    const VARIANCE_RANGE = currentVersion?.variance;
    const maxScore = currentVersion?.max_score ?? null;
    const unlimitedScore = currentVersion?.unlimited_score;
    const hasNextButton = currentVersion?.has_next_button;
    const nextProperty = hasNextButton && showNextButton;

    const dailyProperties = useQuery({
        queryKey: ["plus-minus-random-property"],
        queryFn: getRandomProperty,
        enabled: true,
        cacheTime: 0,
        onSuccess: (data) => {
            const property = get(data, "data.property", null);
            if (property?.price) {
                setProperty(property);
                sendEvent("plus_minus_start", { plusMinusVersion: version });

                const actualPrice = property.price;
                const initialVariance = variance || random(VARIANCE_RANGE?.start, VARIANCE_RANGE?.end);
                const initialGuessPrice =
                    Math.random() < 0.5
                        ? (1 - initialVariance) * actualPrice
                        : (1 + initialVariance) * actualPrice;

                setGuessPrice(initialGuessPrice);
                setVariance(initialVariance);
            }
        },
    });

    const isLoading = dailyProperties?.isLoading || dailyProperties?.isFetching || !property;
    const actualPrice = property?.price;

    /**
     * Handles the user's guess for whether the price is higher or lower.
     *
     * @param {string} buttonType - The type of guess ("lower" or "higher").
     */
    const handleClick = (buttonType) => {
        if (!actualPrice || !guessPrice || !variance) {
            console.error("Missing values in calculations!");
            return;
        }

        let correct = true;
        let newVariance = variance * random(VARIANCE_RANGE.start, VARIANCE_RANGE.end);
        const minVariance = currentVersion?.min_variance;

        if (minVariance && newVariance < minVariance.end) {
            newVariance = variance * random(minVariance.start, minVariance.end);
        }

        const newGuessPrice =
            Math.random() < 0.5 ? (1 - newVariance) * actualPrice : (1 + newVariance) * actualPrice;

        const newPoints = points + 1;

        if (buttonType === "lower" && actualPrice < guessPrice) {
            setPoints(newPoints);
            if (hasNextButton) setShowNextButton(true);
        } else if (buttonType === "higher" && actualPrice > guessPrice) {
            setPoints(newPoints);
            if (hasNextButton) setShowNextButton(true);
        } else {
            correct = false;
        }

        sendEvent("plus_minus_guess", {
            plusMinusGuess: buttonType,
            plusMinusCorrect: correct,
            plusMinusVersion: version,
        });

        if (correct && newPoints !== maxScore) {
            setGuessPrice(newGuessPrice);
            setVariance(newVariance);
        } else {
            setShowModal(true);
        }
    };

    const handleNext = () => {
        dailyProperties.refetch();
        if (hasNextButton) setShowNextButton(false);
    };

    return isLoading ? (
        <div className="d-flex align-items-center justify-content-center h-75">
            <MoonLoader size={40} color="#63c19f" />
        </div>
    ) : (
        <div className="property-container" ref={bodyRef}>
            <PropertyInfo property={property} />
            <Sticky className="guess-input-sticky" mode="bottom" positionRecheckInterval={50}>
                <div className="guess-input-container overflow-hidden px-3 py-3 guess-input-container-shadow">
                    <div className="flex-wrap d-flex align-items-center justify-content-center">
                        <div className="score-to-beat mw-100 mb-3 px-4">
                            {maxScore || unlimitedScore ? (
                                <ProgressIndicator
                                    type="plus-minus"
                                    index={points}
                                    latestIndex={points}
                                    progressLength={unlimitedScore ? points + 1 : maxScore}
                                />
                            ) : (
                                <>
                                    <span>Points: </span>
                                    <span className="app-text-title score">{points}</span>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="flex-wrap d-flex align-items-center justify-content-between gap-1 border rounded">
                        {nextProperty ? (
                            <div className="score-to-beat">
                                <span
                                    className="app-text-title score ml-2"
                                    style={{ fontSize: 18, marginLeft: 10 }}
                                >
                                    Actual Price:
                                </span>
                            </div>
                        ) : (
                            <div className="w-30">
                                <CustomButton
                                    className="d-flex align-items-center justify-content-center red-solid rounded"
                                    handleClick={() => handleClick("lower")}
                                    text="Lower"
                                    leftIcon={<CustomSvg src={DownArrow} size={{ height: 30, width: 30 }} />}
                                />
                            </div>
                        )}
                        <div className="score-to-beat">
                            {nextProperty ? (
                                <span className="app-text-title score" style={{ fontSize: 18 }}>
                                    {formatPrice(actualPrice)}
                                </span>
                            ) : (
                                <span className="app-text-title score">{formatPrice(guessPrice)}</span>
                            )}
                        </div>
                        <div style={{ width: "27%" }}>
                            {nextProperty ? (
                                <CustomButton
                                    className="w-100 d-flex align-items-center justify-content-center gold-solid rounded"
                                    handleClick={handleNext}
                                    text="Next"
                                />
                            ) : (
                                <CustomButton
                                    className="d-flex align-items-center justify-content-center green-solid rounded"
                                    handleClick={() => handleClick("higher")}
                                    text="Higher"
                                    rightIcon={<CustomSvg src={UpArrow} size={{ height: 30, width: 30 }} />}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </Sticky>
            {showModal && (
                <PlusMinusResultModal
                    show={showModal}
                    handleClose={() => setShowModal(false)}
                    score={points}
                    actualPrice={formatPrice(actualPrice)}
                    version={version}
                    maxScore={maxScore}
                />
            )}
        </div>
    );
}

PlusMinus.propTypes = {
    version: PropTypes.string.isRequired,
};
