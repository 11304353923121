import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import "./faq.scss";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { Helmet } from "react-helmet";

const FAQ = () => {
    const [activeAccordion, setActiveAccordion] = useState(0);

    /**
     * Handles setting of active according
     * @param {string} key - The index of the selected item
     */
    const handleSetActiveAccordion = (key) => {
        if (key === activeAccordion) {
            setActiveAccordion(-1);
        } else {
            setActiveAccordion(key);
        }
    };

    return (
        <>
            <Helmet bodyAttributes={{ style: "background-color : #F4F4F4" }} />
            <div className="container py-4 faq-container">
                <div className="mb-4 text-center">
                    <h3 className="fw-bold app-text-title faq-section-title px-0 mb-0">
                        Frequently Asked Questions
                    </h3>
                </div>
                <Accordion defaultActiveKey={0}>
                    {FAQ_ITEMS.map((faq, i) => (
                        <Card className="mb-3" key={i}>
                            <CustomToggle eventKey={i} callback={() => handleSetActiveAccordion(i)}>
                                <span>{faq.question}</span>
                                <span className="toggle-icon">
                                    {activeAccordion === i ? (
                                        <IoChevronUp fontSize={26} />
                                    ) : (
                                        <IoChevronDown fontSize={26} />
                                    )}
                                </span>
                            </CustomToggle>
                            <Accordion.Collapse eventKey={i}>
                                <Card.Body className="accordion-body">{faq.answer}</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))}
                </Accordion>
            </div>
        </>
    );
};

const CustomToggle = ({ children, eventKey, callback }) => {
    // Handles opening of accordion
    const handleOpenAccordion = useAccordionButton(
        eventKey,
        () => callback(eventKey) // Passes event key back to parent
    );

    return (
        <Card.Header className="accordion-title-button" onClick={handleOpenAccordion}>
            {children}
        </Card.Header>
    );
};

// Static FAQ items
const FAQ_ITEMS = [
    {
        question: "How do I challenge a friend?",
        answer: (
            <>
                <p>After you guess one of your daily properties, there are two ways to challenge a friend:</p>
                <ol>
                    <li>Tap the ‘Challenge a Friend’ button in the Results Popup.</li>
                    <li>Tap ‘Send Challenge’ from the property page.</li>
                </ol>
            </>
        ),
    },
    {
        question: "What happens when I challenge a friend?",
        answer: "You’ll be able to share a link that goes directly to the property you just guessed. If your friend beats your score, they get to add those points to their daily total score. If they don’t beat your score, you’ll unlock another guess for that day.",
    },
    {
        question: "How many extra guesses can I unlock by winning challenges?",
        answer: "Up to 3 extra guesses, for a total of 6 guesses per day.",
    },
    {
        question: "Can I send the same challenge to more than one person?",
        answer: "Heck yes. Send it to as many people as you want. This helps improve your odds of unlocking more guesses! Did someone say group chat? 👀",
    },
    {
        question: "What's the most points I can score in a day?",
        answer: "You can score 100 points per property, or up to 300 per day from your normal daily property set. If you unlock all 6 guesses, you can score up to 600 that day.",
    },
    {
        question: "How do I see my score for this week?",
        answer: "You can find your total weekly score on the Start page when you load the app, or any time by tapping the PriceMe logo on the top of the page. Or, you can go to the leaderboard to see your total weekly score.",
    },
    {
        question: "How often does the leaderboard reset?",
        answer: "Every week! Players have from Monday through Sunday to see how many points they can rack up.",
    },
    {
        question:
            " I just signed up in the middle of the week. So am I hopelessly behind now or does the game reset?",
        answer: "Never fear! The leaderboard resets to zero every week.",
    },
    {
        question: "How often do new properties become available?",
        answer: "Every morning at midnight CST",
    },
    {
        question: "I just saw a listing for the second time this month. What gives?",
        answer: "We download every listing that sells in every Area, but sometimes there still aren't enough to serve you 3 properties per day. When this happens, we'll repeat a listing. If you play every day, you'll have a leg up!",
    },
];

export default FAQ;
