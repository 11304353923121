import PropTypes from "prop-types";

const IsRegisteredUser = (isAuthenticated) => {
    // If this is a guest, remove the startup_modal_timestamp in the local storage
    if (!isAuthenticated) {
        localStorage.removeItem('startup_modal_timestamp');
    }

    return true;
};

IsRegisteredUser.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
};

export default IsRegisteredUser;
