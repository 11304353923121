import React from "react";
import "./custom-input.scss";
import { AnimatePresence, motion } from "framer-motion";
import { isNil, isNull } from "lodash";
import { IoClose, IoCloseCircleOutline } from "react-icons/io5";
import PropTypes from "prop-types";

const CustomInput = (props) => {
    const { inputRef, onClear, ...inputProps } = props;

    return (
        <div className="custom-input">
            <AnimatePresence>
                {props.value && (
                    <motion.button
                        id="clear-guess-btn"
                        onClick={() => {
                            onClear();

                            // If the inputRef has the current element, focus on it
                            if (!isNull(inputRef?.current) && !isNil(inputRef?.current)) {
                                inputRef.current.focus();
                            }
                        }}
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0, opacity: 0 }}
                        transition={{ type: "spring", duration: 0.5 }}
                        key="clear-btn"
                        type="button"
                        className="clear-guess-btn"
                    >
                        {inputProps.id === "select-location-input" ? (
                            <IoCloseCircleOutline fontSize={18} />
                        ) : (
                            <IoClose fontSize={14} />
                        )}
                    </motion.button>
                )}
            </AnimatePresence>

            <input ref={inputRef} {...inputProps} />
        </div>
    );
};

CustomInput.propTypes = {
    "data-tooltip-id": PropTypes.string,
    "data-cy": PropTypes.string,
    onClear: PropTypes.func,
    onChange: PropTypes.func,
    id: PropTypes.string,
    inputRef: PropTypes.object,
    disabled: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    tabIndex: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};

export default CustomInput;
