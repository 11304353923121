import React from "react";
import PropTypes from "prop-types";
import { chunk } from "lodash";
import clsx from "clsx";
import "./GuessScores.scss";

const GuessScores = (props) => {
    const chunkSize = 3;
    const rows = chunk(props.scores, chunkSize).map((chunk, chunkIndex) => {
        return chunk.map((score, index) => ({
            score,
            guessNumber: chunkIndex * chunkSize + index + 1,
        }));
    });
    const justifiedCenter = props?.justifyPosition === "justify-center";

    /**
     * Renders an array of element that displays guess score in order.
     *
     * @param {array} scores - The array of scores to be displayed.
     *
     * @param {boolean} isFirstRow - The identifier for the first row and how the extra indicator line should be displayed.
     *
     * @param {boolean} isLastRow - The identifier for the last row and how the extra indicator line should be displayed.
     *
     * @returns {ReactNode} Returns a react element displaying a circle icon for guess order and below it is the guess score.
     */
    const renderScoresRow = (scores = [], isFirstRow = false, isLastRow = false) => {
        return scores.map((item, i) => (
            <React.Fragment key={"score" + i}>
                {!isFirstRow && i === 0 && (
                    <div
                        className={clsx("indicator-line corner-line start", {
                            "short-line": isLastRow && justifiedCenter,
                        })}
                    ></div>
                )}
                <div className="guess-icon">
                    <div
                        className={clsx(
                            "guess-number",
                            { inactive: item.guessNumber > props?.score },
                            "d-flex align-items-center justify-content-center"
                        )}
                    >
                        {item.guessNumber}
                    </div>

                    {props?.hideScore ? null : <span className="guess-score">{item.score}</span>}
                </div>
                {i + 1 !== scores.length ? (
                    <div className="indicator-line"></div>
                ) : (
                    !isLastRow &&
                    i === scores.length - 1 && <div className="indicator-line corner-line end"></div>
                )}
            </React.Fragment>
        ));
    };

    return (
        <React.Fragment>
            {rows.map((row, index) => (
                <div
                    key={`scores-div-${index}`}
                    className="d-flex justify-content-center my-4 position-relative"
                >
                    <div className={clsx("guess-scores-container", props.justifyPosition)}>
                        {renderScoresRow(row, index === 0, index === rows.length - 1)}
                    </div>
                </div>
            ))}
        </React.Fragment>
    );
};

GuessScores.propTypes = {
    scores: PropTypes.array.isRequired,
    score: PropTypes.number,
    hideScore: PropTypes.bool,
    justifyPosition: PropTypes.string,
};

export default GuessScores;
