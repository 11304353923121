import React, { useMemo, useState } from "react";
import ProgressIndicator from "../../../components/common/progress-indicator/progress-indicator";
import { getDayNumber, groupScoresByDate } from "../../../helpers/helpers";
import propTypes from "prop-types";
import { isNil } from "lodash";
import { useUser } from "../../../context/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import InviteFriendsCTA from "../../../components/common/invite-friends-cta/invite-friends-cta";
import useGAEvent from "../../../hooks/useGAEvent";
import { useScore } from "../../../context/ScoreContext";
import clsx from "clsx";

const MINIMUM_NUMBER_RANKINGS = 8;

const StatsTable = ({ type, rankings = [], tableTitle = "", userRanking, scores = [] }) => {
    const { isAuthenticated, isLoading } = useAuth0();
    const { userState } = useUser();
    const daysScored = groupScoresByDate(scores);
    const [hasSentGAEvent, setHasSentGAEvent] = useState(false);
    const { sendEvent } = useGAEvent();
    const { hasFetchedDataState, totalScore } = useScore();

    // Send the event trigger to GA once after the authentication status, user current rank is loaded for the weekly leaderboard, and the total score has been fetched
    useMemo(() => {
        function sendLeaderboardViewEvent() {
            if (type === "weekly" && !hasSentGAEvent && !isLoading && hasFetchedDataState) {
                setHasSentGAEvent(true);
                sendEvent("leaderboard_view", {
                    isAuthenticated,
                    finalScore: totalScore,
                    userRank: !isAuthenticated ? 0 : userRanking.current_rank,
                });
            }
        }

        sendLeaderboardViewEvent();
    }, [hasFetchedDataState, userRanking.current_rank]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <ProgressIndicator
                type="days"
                index={getDayNumber()}
                latestIndex={getDayNumber()}
                progressLength={7}
                daysScored={daysScored}
                progressContent={["M", "Tu", "W", "Th", "F", "Sa", "Su"]}
            />
            <div className={clsx("leaderboard-table", rankings?.length > 10 && "scrollable")}>
                <table cellSpacing="0" cellPadding="0">
                    <thead>
                        <tr>
                            <th>
                                <span className="header-text">Rank</span>
                                <span className="header-border-bottom"></span>
                            </th>
                            <th>
                                <span className="header-text">Player</span>
                                <span className="header-border-bottom"></span>
                            </th>
                            <th>
                                <span className="header-text">Score</span>
                                <span className="header-border-bottom"></span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {rankings?.map((rank, i) => (
                            <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{rank.nickname}</td>
                                <td>{rank.total_points}</td>
                            </tr>
                        ))}
                        {rankings?.length < MINIMUM_NUMBER_RANKINGS ? (
                            <tr>
                                <td colSpan={3}>
                                    <InviteFriendsCTA rank={userRanking.current_rank} />
                                </td>
                            </tr>
                        ) : null}
                        {rankings?.length < MINIMUM_NUMBER_RANKINGS &&
                            [...Array(MINIMUM_NUMBER_RANKINGS - rankings?.length)]?.map((el, i) => (
                                <tr className="empty-ranking" key={i}>
                                    <td>{i + rankings?.length + 1}</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                            ))}
                    </tbody>
                    {isAuthenticated ? (
                        <tfoot>
                            <tr>
                                <th className="pt-4">
                                    {!isNil(userState?.show_score_leaderboard) &&
                                    userState.show_score_leaderboard
                                        ? `${isAuthenticated ? userRanking.current_rank ?? 0 : "??"}/${
                                              userRanking.total_rank ?? 0
                                          }`
                                        : "Hidden"}
                                </th>
                                <th className="pt-4">This player</th>
                                <th className="pt-4">{userRanking.total_score ?? 0}</th>
                            </tr>
                        </tfoot>
                    ) : null}
                </table>
            </div>
        </>
    );
};

StatsTable.propTypes = {
    type: propTypes.string.isRequired,
    rankings: propTypes.array.isRequired,
    tableTitle: propTypes.string,
    userRanking: propTypes.object,
    scores: propTypes.array.isRequired,
};

export default StatsTable;
