import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { isNil } from "lodash";
import "./StepPulseAnimation.scss";
import clsx from "clsx";

const StepPulseAnimation = ({ stepStatus, dotAnimation }) => {
    return (
        <motion.div
            className={clsx(
                "vibration-pulse",
                { "dot-animation": dotAnimation },
                isNil(stepStatus) ? "" : stepStatus === "active" ? "active" : "inactive"
            )}
            initial={{ scale: 1, opacity: 0.8 }}
            animate={{
                scale: [1, 1.1, 1.2, 1.1, 1],
                opacity: [0.8, 0.7, 0.6, 0.7, 0.8],
                transition: { duration: 0.5, repeat: Infinity, repeatType: "reverse" }, // Decreased duration to make it faster
            }}
        />
    );
};

StepPulseAnimation.propTypes = {
    stepStatus: PropTypes.string, // step is currently either active/inactive or null/undefined
};

export default StepPulseAnimation;
