import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import "./google-auto-complete.scss";

const GoogleAutoComplete = ({
    onChange,
    inputValue,
    suggestedAreas,
    onSelect,
    wrapperClassName,
    placeholder,
    isLoading,
}) => {
    const [dirty, setDirty] = useState(false);
    const [firstChange, setFirstChange] = useState(true);
    const selected = suggestedAreas.filter((s) => s.description === inputValue);

    return (
        <div className={clsx("google-form-control", wrapperClassName ?? "")}>
            <Typeahead
                id="select-location-input"
                onChange={(data) => {
                    onSelect(data[0]);
                }}
                onInputChange={(data, event) => {
                    onChange(event);
                    setDirty(true);
                    if (firstChange) {
                        setTimeout(() => {
                            setFirstChange(false);
                        }, 500);
                    }
                }}
                onKeyDown={(e) => {
                    if (e.key === "Enter" && suggestedAreas[0]) {
                        onSelect(suggestedAreas[0], true);
                    }
                }}
                options={suggestedAreas}
                placeholder={placeholder}
                selected={selected}
                labelKey="description"
                disabled={isLoading}
                clearButton={!isLoading}
                renderMenu={(results, menuProps) => {
                    if (!dirty || isLoading || !inputValue.length) {
                        return null;
                    }

                    // For overriding the bug for Menu position on initial display
                    if (firstChange) {
                        return (
                            <Menu {...menuProps}>
                                <MenuItem disabled>No matches found.</MenuItem>
                            </Menu>
                        );
                    }

                    return (
                        <Menu {...menuProps}>
                            {results.map((result, index) => (
                                <MenuItem option={result} position={index} key={index}>
                                    {result.description}
                                </MenuItem>
                            ))}
                        </Menu>
                    );
                }}
            />
        </div>
    );
};

GoogleAutoComplete.propTypes = {
    onChange: PropTypes.func,
    inputValue: PropTypes.string,
    suggestedAreas: PropTypes.array,
    onSelect: PropTypes.func,
    wrapperClassName: PropTypes.string,
    placeholder: PropTypes.string,
    isLoading: PropTypes.bool,
};

export default GoogleAutoComplete;
