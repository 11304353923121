import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useUser } from "../../../context/UserContext";
import useLocalStorage from "../../../hooks/useLocalStorage";

const ProtectedRoute = ({ navigateData, guards, children }) => {
    const [guestPlay] = useLocalStorage("guest_gameplay");
    const { isAuthenticated, isLoading } = useAuth0();
    const userContext = useUser();
    const [canBeRendered, setCanBeRendered] = useState(true);
    const [failedGuard, setFailedGuard] = useState(null);

    /**
     * This effect runs when the `isAuthenticated` value changes.
     * It checks a list of guards to determine if the component can be rendered.
     */
    useEffect(() => {
        async function checkGuards() {
            for (let i = 0; i < guards.length; i++) {
                const guardedResponse = await guards[i](isAuthenticated, userContext, guestPlay);

                if (!guardedResponse) {
                    setFailedGuard(guards[i].name);
                    setCanBeRendered(false);
                    break;
                }
            }
        }

        if (!isLoading) {
            checkGuards();
        }
    }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    // If any of the guards fail, redirect the users instead
    if (!canBeRendered) {
        navigateData.state.failed_guard = failedGuard;
        window.location.href = navigateData.to;
        window.history.pushState(navigateData.state, "", navigateData.to);

        return;
    }

    return children;
};

ProtectedRoute.propTypes = {
    navigateData: PropTypes.any.isRequired,
    children: PropTypes.element,
    guards: PropTypes.array.isRequired,
};

export default ProtectedRoute;
