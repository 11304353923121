import { isEmpty, isNil, isNull } from "lodash";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { TOAST_MESSAGES } from "../lang/toast-messages.lang";
import { getDefaultArea } from "../services/AreaService";

const HasCurrentAreaGuard = async (isAuthenticated, userContext) => {
    const { userState, updateGuestCurrentArea } = userContext;

    let result = true;

    // If this is a guest, just return true
    if (!isAuthenticated) {
        if (isNil(userState?.current_area_id) || isEmpty(userState.current_area_id)) {
            const defaultArea = await getDefaultArea();

            updateGuestCurrentArea(defaultArea.id);
        }

        return result;
    }

    // If there is no saved user state, return false
    if (isNil(userState) || isNull(userState)) {
        result = false;
    }

    // If there is no current area id, return false
    if (isNil(userState?.current_area_id) || isEmpty(userState.current_area_id)) {
        result = false;
    }

    if (!result) {
        toast.error(TOAST_MESSAGES.SELECT_AREA_FIRST, { id: "SELECT_AREA_FIRST" });
    }

    return result;
};

HasCurrentAreaGuard.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    userContext: PropTypes.object.isRequired,
};

export default HasCurrentAreaGuard;
