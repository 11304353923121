import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { X } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation, Pagination } from "swiper";
import clsx from "clsx";
import { uniq } from "lodash";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

import AppButton from "../../common/buttons/app-button";
import CustomSvg from "../../common/custom-svg/custom-svg";
import WinnerIcon from "../../../assets/icons-v2/result-popup/party.png";
import LoserIcon from "../../../assets/icons-v2/challenge-lost.png";
import { useUser } from "../../../context/UserContext";
import { setUserViewedChallengeScores } from "../../../services/UserService";

import "./start-screen-challenger-results-modal.scss";

/**
 * Modal component for displaying challenger results.
 *
 * @param {Object} props
 * @param {Array} props.results - List of results to display in the modal.
 * @param {boolean} props.open - Boolean indicating if the modal is open.
 * @param {function} props.onClose - Function to call when the modal is closed.
 */
export default function StartScreenChallengerResultsModal({ results, open, onClose }) {
    const swiperElRef = useRef(null);
    const { userState, setUserState } = useUser();
    const [showNextBtn, setShowNextBtn] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [viewedScores, setViewedScores] = useState([]);
    const navigate = useNavigate();

    /**
     * Handles the action when the user chooses to close the modal.
     */
    const handleClose = async () => {
        await setUserViewedChallengeScores(uniq([results[0].opponentScoreId, ...viewedScores]));
        onClose();
    };

    /**
     * Handles proceeding to the daily properties page.
     *
     * @param {boolean} isKeepPlaying - Indicates if the user wants to keep playing.
     * @returns {void}
     */
    const handleClickProceed = async (isKeepPlaying) => {
        if (!isKeepPlaying) {
            setUserState({ ...userState, show_scrollability_tooltip: true });
        }

        await setUserViewedChallengeScores(uniq([results[0].opponentScoreId, ...viewedScores]));
        navigate("/daily-properties");
    };

    const handleSeeHistory = async () => {
        const currentResult = results[currentIndex];
        setUserState({
            ...userState,
            challenge_history_selected_user: {
                id: currentResult.opponentId,
                nickname: currentResult.opponentNickname,
            },
            leaderboard_active_tab: { ...userState?.leaderboard_active_tab, tab: 3 },
        });

        await setUserViewedChallengeScores(uniq([results[0].opponentScoreId, ...viewedScores]));
        navigate("/leaderboard");
    };

    if (results.length === 0) return null;

    return (
        <Modal
            show={open}
            onHide={handleClose}
            centered
            keyboard={false}
            backdrop="static"
            data-cy="start-screen-challenger-results-modal"
            className="start-screen-challenger-results-modal"
        >
            <div className="close-icons">
                <X className="close" size={30} onClick={handleClose} />
            </div>
            <Modal.Body className="pt-0 pb-3 px-4">
                <>
                    <Swiper
                        ref={swiperElRef}
                        modules={[Navigation, Pagination, EffectFade]}
                        navigation={{ nextEl: "#nextBtn", prevEl: "#prevBtn" }}
                        className="swiper-container"
                        data-cy="swiper-container"
                        speed={400}
                        effect="fade"
                        fadeEffect={{ crossFade: true }}
                        pagination={{ el: ".swiper-pagination", type: "bullets", clickable: true }}
                        onSlideChange={(obj) => {
                            if (obj.activeIndex !== 0 && obj.activeIndex === obj.slides.length - 1) {
                                setShowNextBtn(false);
                            } else {
                                setShowNextBtn(true);
                            }

                            if (obj.activeIndex !== currentIndex) {
                                setCurrentIndex(obj.activeIndex);
                                setViewedScores(
                                    uniq([...viewedScores, results[obj.activeIndex].opponentScoreId])
                                );
                            }
                        }}
                        scrollbar={{ draggable: true }}
                    >
                        {results.map((result, index) => {
                            const isWinner = result.userScore >= result.opponentScore;

                            return (
                                <SwiperSlide key={`start-screen-challenger-results-modal-slide-${index}`}>
                                    <div className="start-screen-challenger-results-modal-slide-group">
                                        <h5 className="app-text-title text-center">
                                            {isWinner ? "Victory is yours!" : "Can't win 'em all"}
                                        </h5>
                                        <div className="slide-img-wrapper">
                                            <CustomSvg
                                                src={isWinner ? WinnerIcon : LoserIcon}
                                                size={{ height: 90, width: 90 }}
                                            />
                                        </div>
                                        <h2 className="modal-header-text mb-3">
                                            <strong>{isWinner ? "Challenge Won" : "Challenge Lost"}</strong>
                                        </h2>
                                        <p className="mb-0">
                                            {isWinner ? (
                                                <>
                                                    You{" "}
                                                    {result.userScore === result.opponentScore
                                                        ? "tied with"
                                                        : "defeated"}{" "}
                                                    <b>{result.opponentNickname}</b> <b>{result.userScore}</b>{" "}
                                                    to <b>{result.opponentScore}</b> in your challenge and
                                                    unlocked an extra guess for today 🙌
                                                </>
                                            ) : (
                                                <>
                                                    <b>{result.opponentNickname}</b> accepted your challenge
                                                    and defeated you <b>{result.opponentScore}</b> to{" "}
                                                    <b>{result.userScore}</b>. You’ll get ‘em next time!
                                                </>
                                            )}
                                        </p>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                    <div className="swiper-pagination"></div>
                </>
            </Modal.Body>
            <Modal.Footer className="d-flex flex-column gap-3">
                <AppButton
                    id="nextBtn"
                    type="button"
                    className={clsx("sscrm-button mx-auto m-0", !showNextBtn && "d-none")}
                    variant="solid"
                    theme="purple"
                >
                    Next
                </AppButton>

                <AppButton
                    type="button"
                    variant="solid"
                    theme="purple"
                    className="sscrm-button mx-auto m-0"
                    onClick={() =>
                        handleClickProceed(
                            results[currentIndex].userScore > results[currentIndex].opponentScore
                        )
                    }
                >
                    {results[currentIndex].userScore > results[currentIndex].opponentScore
                        ? "Keep playing!"
                        : "Send another challenge"}
                </AppButton>

                <div className="see-history-text" onClick={handleSeeHistory}>
                    See history: you vs {results[currentIndex].opponentNickname}
                </div>
            </Modal.Footer>
        </Modal>
    );
}

StartScreenChallengerResultsModal.propTypes = {
    results: PropTypes.arrayOf(
        PropTypes.shape({
            userScore: PropTypes.number.isRequired,
            opponentScore: PropTypes.number.isRequired,
            opponentScoreId: PropTypes.string.isRequired,
            opponentNickname: PropTypes.string.isRequired,
        })
    ).isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

StartScreenChallengerResultsModal.defaultProps = {
    results: [],
    open: false,
};
