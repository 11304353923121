import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./progress-bar.scss";

const ProgressBar = ({ duration, onComplete, isMouseDown }) => {
    const [progress, setProgress] = useState(100);

    useEffect(() => {
        let interval;

        const updateProgress = () => {
            if (!isMouseDown) {
                setProgress((prevProgress) => {
                    const newProgress = prevProgress - 1;
                    if (newProgress <= 0) {
                        clearInterval(interval);
                        onComplete();
                    }
                    return newProgress;
                });
            }
        };

        interval = setInterval(updateProgress, duration / 100);

        return () => clearInterval(interval);
    }, [duration, isMouseDown, onComplete]);

    return (
        <div className="progress-bar-container">
            <div className="progress-bar" style={{ width: `${progress}%` }}></div>
        </div>
    );
};

ProgressBar.propTypes = {
    duration: PropTypes.number.isRequired,
    onComplete: PropTypes.func.isRequired,
    isMouseDown: PropTypes.bool.isRequired,
};

export default ProgressBar;
