import { toast } from "react-hot-toast";
import { TOAST_MESSAGES } from "../lang/toast-messages.lang";

/**
 * Displays a toast message and redirects the user after a specified timeout.
 *
 * @param {string} type - The type of toast message (e.g., 'success', 'error', 'loading').
 * @param {string} toastMsg - The key of the toast message to be displayed from TOAST_MESSAGES.
 * @param {number} [toastDuration=1000] - The duration in milliseconds for the toast to be visible.
 * @param {number} [redirectTimeout=2000] - The timeout in milliseconds before redirecting the user.
 * @param {string} [url='/'] - The URL to which the user should be redirected.
 * @param {string} [id='toast-and-redirect'] - The ID to assign to the toast message for identification.
 */
export function toastAndRedirect(
    type,
    toastMsg,
    toastDuration = 1000,
    redirectTimeout = 2000,
    url = "/",
    id = "toast-and-redirect"
) {
    // Try to parse the toast message error path if possible
    const message = toastMsg
        .split(".")
        .reduce((prev, curr) => (prev ? prev[curr] : undefined), TOAST_MESSAGES);

    toast[type](message ?? "An error occurred.", {
        id,
        duration: toastDuration,
        style: { padding: "5px" },
    });

    // Redirect after timeout
    setTimeout(() => {
        window.location.href = url;
    }, redirectTimeout);
}
