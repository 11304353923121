import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import { isNil, range, pullAt } from "lodash";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "./property-image-swiper.scss";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

import Block2Locked from "../../../assets/images/block-2-locked.jpg";
import Block3Locked from "../../../assets/images/block-3-locked.jpg";
import CustomButton from "../buttons/custom-button/custom-button";
import ScaleLoader from "react-spinners/ScaleLoader";
import AnimateFade from "../../animations/AnimateFade";

const AUTOPLAY_DELAY = 2000;

export default function PropertyImageSwiper(props) {
    const swiperElRef = useRef(null);
    const [propertySwiper, setPropertySwiper] = useState();
    const [loaded, setLoaded] = useState(false);
    const [loadedImages, setLoadedImages] = useState([false]); // Track which images are loaded
    const isPlusMinus = !isNil(props?.round);
    const numberOfImages = props?.property?.image_urls.length;
    const halfOfImages = Math.floor(numberOfImages / 2);
    let propertyImages = props?.property?.image_urls ?? [];

    if (props?.round === 1) {
        // Round 1: Only show the first image
        propertyImages = [propertyImages[0]];
    } else if (isPlusMinus) {
        // Initialize the array with the first image
        propertyImages = [propertyImages[0]];

        // Handle block 2 (indices from 1 to halfOfImages)
        if (!props?.blurredBlocks.includes(2)) {
            const block2Items = pullAt([...props?.property?.image_urls], range(1, halfOfImages));
            propertyImages = [...propertyImages, ...block2Items];
        } else {
            propertyImages.push(Block2Locked); // Add LockedImage for block 2
        }

        // Handle block 3 (indices from halfOfImages + 1 to numberOfImages)
        if (!props?.blurredBlocks.includes(3)) {
            const block3Items = pullAt([...props?.property?.image_urls], range(halfOfImages, numberOfImages));
            propertyImages = [...propertyImages, ...block3Items];
        } else {
            propertyImages.push(Block3Locked); // Add LockedImage for block 3
        }
    }

    const renderRevealButton = (intIndex, strImageUrl) => {
        let buttonText = "";
        let block = null;
        const isBlock2 = strImageUrl.includes("block-2-locked") && props?.blurredBlocks.includes(2);
        const isBlock3 = strImageUrl.includes("block-3-locked") && props?.blurredBlocks.includes(3);

        if (intIndex === 0 || props?.round === 1) {
            // Don't show button on first image or if round is 1
            return null;
        } else if (isBlock2) {
            buttonText = `Reveal photos 2-${halfOfImages}`;
            block = 2;
        } else if (isBlock3) {
            buttonText = `Reveal photos ${halfOfImages + 1}-${numberOfImages}`;
            block = 3;
        }

        // Don't render the button if there's no text
        if (!buttonText.length) {
            return null;
        }

        return (
            <CustomButton
                className="reveal-btn gold-solid"
                text={buttonText}
                handleClick={(event) => {
                    event.stopPropagation(); // Stop the click event from propagating to the image
                    props?.handleRevealInfo(intIndex === 2 ? 2 : block); // For some reason image two has index of 2 instead of 1
                }}
            />
        );
    };

    /**
     * Handles the image loading state by updating the `loadedImages` array.
     * Marks the current image as loaded and preloads the next image.
     * If the first image is loaded, it starts the autoplay mechanism.
     *
     * @param {number} index - The index of the image that has loaded.
     */
    const handleImageLoad = (index) => {
        setLoadedImages((prev) => {
            const newLoadedImages = [...prev];

            newLoadedImages[index] = true; // Mark current image as loaded
            newLoadedImages[index + 1] = true; // Allow next image to load

            return newLoadedImages;
        });

        if (index === 0) {
            setLoaded(true); // When first image is loaded, start the autoplay
        }
    };

    // Set the swiper progress to 0 when the images are updated (switch property)
    // Fixes: https://app.asana.com/0/1204535088114623/1203873386482929/f
    useEffect(() => {
        if (propertySwiper && !propertySwiper.destroyed) {
            setLoadedImages([]); // Reset the loaded images
            propertySwiper.setProgress(0);

            if (!isPlusMinus) {
                setTimeout(() => propertySwiper.autoplay?.start(), AUTOPLAY_DELAY);
            }
            setLoaded(false);
        }
    }, [props.property?.image_urls]); // eslint-disable-line react-hooks/exhaustive-deps

    // Starts or stops swiper autoplay based on first image loading state
    useEffect(() => {
        if (propertySwiper && !propertySwiper.destroyed) {
            if (loaded) {
                let isAutoplayStopped = false;

                // Initialize the fancybox
                Fancybox.bind("[data-fancybox]", {
                    on: {
                        // When the fancybox is opened, stop the autoplay
                        "Carousel.ready": (fancybox, slide) => {
                            isAutoplayStopped = true;

                            propertySwiper.autoplay.stop();
                        },
                        // When the fancybox is closing, stop the autoplay and slide to the last opened slide
                        shouldClose: (fancybox, slide) => {
                            propertySwiper.autoplay.stop();
                            propertySwiper.slideTo(fancybox.getSlide().index);
                        },
                    },
                    Toolbar: {
                        enabled: false,
                    },
                    Thumbs: false,
                });

                setTimeout(() => {
                    if (!isAutoplayStopped && !isPlusMinus) {
                        propertySwiper.autoplay?.start();
                    }
                }, AUTOPLAY_DELAY);
            } else {
                propertySwiper.autoplay.stop();
            }
        }
    }, [loaded, propertySwiper]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Swiper
            ref={swiperElRef}
            modules={[Autoplay, Navigation, Pagination, EffectFade]}
            autoplay={{
                delay: AUTOPLAY_DELAY,
                disableOnInteraction: true,
            }}
            loop
            navigation={{
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            }}
            pagination={{
                el: ".swiper-pagination",
                type: "fraction",
                renderFraction: function (currentClass, totalClass) {
                    if (props.property?.image_urls.length < 5 && !isPlusMinus) {
                        return `<div class="pagination-fraction-wrapper px-1 insufficient-image-text" data-cy="pagination">
                            <span><i>MLS rules require us to keep the rest of these photos top secret</i></span>
                        </div>`;
                    }

                    return `<div class="pagination-fraction-wrapper px-1" data-cy="pagination">
                                <span class="${currentClass}"></span> of <span class="${totalClass}"></span>
                            </div>`;
                },
            }}
            className="property-image-swiper"
            data-cy="property-image-swiper"
            speed="400"
            effect="fade"
            onSwiper={(swiper) => {
                setPropertySwiper(swiper);
            }}
        >
            {propertyImages.map((strImageUrl, intIndex) => (
                <SwiperSlide
                    key={"property-image-slide-" + intIndex}
                    data-cy="swiper-slide"
                    className="swiper-slide"
                >
                    <div
                        className="image-container d-flex justify-content-center"
                        data-fancybox
                        data-src={strImageUrl}
                    >
                        {!loadedImages[intIndex] || isNil(loadedImages[intIndex]) ? (
                            <div className="tw-w-full tw-absolute tw-bottom-[50%] tw-flex tw-justify-center tw-items-center">
                                <ScaleLoader color="#63c19f" />
                            </div>
                        ) : null}

                        <AnimateFade show={loadedImages[intIndex]}>
                            <img
                                alt={"property-image-slide-" + intIndex}
                                onLoad={() => handleImageLoad(intIndex)} // Sequential loading
                                src={strImageUrl}
                            />
                        </AnimateFade>

                        {isPlusMinus && renderRevealButton(intIndex, strImageUrl)}
                    </div>
                </SwiperSlide>
            ))}
            <div className="swiper-button-prev" data-cy="swiper-button-prev"></div>
            <div className="swiper-button-next" data-cy="swiper-button-next"></div>
            <div className="swiper-pagination"></div>
        </Swiper>
    );
}
