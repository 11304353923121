import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";
import { motion, useAnimation } from "framer-motion";
import Sticky from "react-sticky-el";
import MoonLoader from "react-spinners/MoonLoader";

import PropertyInfo from "../../../../components/common/PropertyInfo";
import CustomButton from "../../../../components/common/buttons/custom-button/custom-button";
import CustomSvg from "../../../../components/common/custom-svg/custom-svg";
import PlusMinusResultModal from "../../../../components/modals/PlusMinusResultModal";

import { getRandomProperty } from "../../../../services/UserService";
import { formatPrice } from "../../../../helpers/helpers";
import useGAEvent from "../../../../hooks/useGAEvent";

import DownArrow from "../../../../assets/icons-v2/thick-down-arrow.png";
import UpArrow from "../../../../assets/icons-v2/thick-up-arrow.png";

import "./plus-minus3.scss";

const AnimatedNumber = ({ value, className }) => {
    const controls = useAnimation();
    const [displayValue, setDisplayValue] = useState(0);

    useEffect(() => {
        const animation = controls.start({
            value: [0, value], // Animate from 0 to the final value
            transition: { duration: 1, ease: "easeOut" },
        });

        animation.then(() => setDisplayValue(value)); // Set final value to state
        return () => controls.stop();
    }, [value, controls]);

    return (
        <motion.span
            className={className}
            animate={controls}
            initial={{ value: 0 }}
            onUpdate={(latest) => setDisplayValue(latest.value)} // Update displayValue on each frame
        >
            {formatPrice(displayValue)}
        </motion.span>
    );
};

const slideVariants = {
    hidden: { y: 0, opacity: 1 }, // Start position
    slideUp: { y: "-100%", opacity: 1, transition: { duration: 0.5 } }, // Slide up animation
    slideIn: { y: 0, opacity: 1, transition: { duration: 0 } }, // End
};

/**
 * PlusMinus3 game component that allows users to guess whether the price of Property A is higher or lower than of property B.
 *
 * @component
 * @param {object} props - The component props.
 * @param {string} props.version - The version of the PlusMinus game.
 * @returns {JSX.Element} The PlusMinus game interface.
 */
export default function PlusMinus3({ version }) {
    const bodyRef = useRef(null);
    const [points, setPoints] = useState(0);
    const [properties, setProperties] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isSliding, setIsSliding] = useState(false);
    const [dirty, setDirty] = useState(false);
    const { sendEvent } = useGAEvent();

    const dailyProperties = useQuery({
        queryKey: ["plus-minus-random-property"],
        queryFn: getRandomProperty,
        enabled: true,
        cacheTime: 0,
        onSuccess: (data) => {
            const property = get(data, "data.property", null);
            if (property?.price) {
                sendEvent("plus_minus_start", { plusMinusVersion: version });

                if (dirty) {
                    setIsSliding(true);

                    setTimeout(() => {
                        setIsSliding(false);

                        // Move previous Property B to Property A and add new Property B
                        setProperties([properties[1], property]);
                    }, 700); // Wait for the animation to complete
                } else {
                    setProperties([...properties, property]);
                }
            }
        },
    });

    const isLoading = dailyProperties?.isLoading || dailyProperties?.isFetching || properties.length < 2;
    const propertyAPrice = properties[0]?.price;
    const propertyBPrice = properties[1]?.price;

    /**
     * Handles the user's guess for whether the price is higher or lower.
     *
     * @param {string} buttonType - The type of guess ("lower" or "higher").
     */
    const handleClick = (buttonType) => {
        let correct = true;

        if (
            (buttonType === "lower" && propertyBPrice < propertyAPrice) ||
            (buttonType === "higher" && propertyBPrice > propertyAPrice)
        ) {
            setPoints((prevPoints) => prevPoints + 1);
            dailyProperties.refetch();
        } else {
            correct = false;
            setShowModal(true);
        }

        setDirty(true);

        sendEvent("plus_minus_guess", {
            plusMinusGuess: buttonType,
            plusMinusCorrect: correct,
            plusMinusVersion: version,
        });
    };

    useEffect(() => {
        if (properties.length < 2 && !dirty) {
            dailyProperties.refetch();
        }
    }, [properties]); // eslint-disable-line react-hooks/exhaustive-deps

    return isLoading ? (
        <div className="d-flex align-items-center justify-content-center h-75">
            <MoonLoader size={40} color="#63c19f" />
        </div>
    ) : (
        <div className="property-container" ref={bodyRef}>
            {properties.map((property, index) => (
                <>
                    <motion.div
                        initial="hidden"
                        animate={isSliding ? "slideUp" : "slideIn"}
                        variants={slideVariants}
                    >
                        <PropertyInfo property={property} showMoreDetails={false} />
                    </motion.div>
                    {index === 0 ? (
                        <>
                            {!isSliding && (
                                <>
                                    <div className="first-property-price-container">
                                        {dirty ? (
                                            <AnimatedNumber
                                                value={property?.price}
                                                className="first-property-price"
                                            />
                                        ) : (
                                            <span className="first-property-price">
                                                {formatPrice(property?.price)}
                                            </span>
                                        )}
                                    </div>
                                    <div className="versus-icon-container">
                                        <span className="versus-icon">VS</span>
                                    </div>
                                    <br />
                                </>
                            )}
                        </>
                    ) : null}
                </>
            ))}
            <Sticky className="guess-input-sticky z-1" mode="bottom" positionRecheckInterval={50}>
                <div className="guess-input-container overflow-hidden px-3 py-3 guess-input-container-shadow">
                    <div className="flex-wrap d-flex align-items-center justify-content-between gap-1">
                        <div style={{ width: 150 }}>
                            <CustomButton
                                className="d-flex w-100 align-items-center justify-content-center purple-solid app-button"
                                handleClick={() => handleClick("lower")}
                                text="Lower"
                                leftIcon={<CustomSvg src={DownArrow} size={{ height: 30, width: 30 }} />}
                            />
                        </div>
                        <div style={{ width: 150 }}>
                            <CustomButton
                                className="d-flex w-100 align-items-center justify-content-center gold-solid app-button"
                                handleClick={() => handleClick("higher")}
                                text="Higher"
                                leftIcon={<CustomSvg src={UpArrow} size={{ height: 30, width: 30 }} />}
                            />
                        </div>
                    </div>
                </div>
            </Sticky>
            {showModal && (
                <PlusMinusResultModal
                    show={showModal}
                    handleClose={() => setShowModal(false)}
                    score={points}
                    actualPrice={formatPrice(propertyBPrice)}
                    version={version}
                />
            )}
        </div>
    );
}

PlusMinus3.propTypes = {
    version: PropTypes.string.isRequired,
};
