/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useMemo, useCallback } from "react";
import "./profile-drawer.scss";
import { useUser } from "../../../context/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import useLogout from "../../../hooks/useLogout";
import toast from "react-hot-toast";
import { syncUser } from "../../../services/UserService";
import Switch from "../switch/switch";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";
import { GrClose } from "react-icons/gr";
import ClipLoader from "react-spinners/ClipLoader";
import { X } from "react-bootstrap-icons";
import { Check } from "react-bootstrap-icons";
import NotificationSetting from "./notification-setting";
import { clsx } from "clsx";
import { getDefaultSetting } from "../../constants/notification-settings.constants";

const ProfileDrawer = (props) => {
    const { userState, isInvalidLogin, setInvalidLogin, fetchUserData } = useUser();
    const { user, isAuthenticated, isLoading } = useAuth0();
    const { logoutUser } = useLogout();
    const [isEditingNickname, setIsEditingNickname] = useState(false);
    const [profileValues, setProfileValues] = useState({
        data: {
            nickname: "",
            email: "",
            showScoreLeaderboard: false,
            notifyDailyProperty: false,
            notificationSetting: getDefaultSetting(),
            isPro: false,
            showStartupModal: false,
            useEmailAccountPicture: true
        },
        submit: false,
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Handles updating of user values
    const handleUpdateUser = useCallback(async () => {
        try {
            if (!profileValues.submit) return;

            toast.loading("Updating profile...", { id: "update-profile" });

            setIsSubmitting(true);

            await syncUser({
                id: userState?.id,
                nickname: profileValues.data.nickname,
                email: profileValues.data.email,
                show_score_leaderboard: profileValues.data.showScoreLeaderboard,
                notify_daily_property: profileValues.data.notifyDailyProperty,
                notification_settings: profileValues.data.notificationSetting,
                is_pro: profileValues.data.isPro,
                show_startup_modal: profileValues.data.showStartupModal,
                current_location: userState?.current_location,
                use_email_account_picture: profileValues.data.useEmailAccountPicture
            });

            await fetchUserData(userState?.id);

            if (isEditingNickname) setIsEditingNickname(false);

            setProfileValues((prev) => ({ ...prev, submit: false }));

            // This adds a bit of leeway for the animation to be visible for the toast message
            setTimeout(() => {
                toast.success("Profile successfully updated!", { id: "update-profile" });

                setIsSubmitting(false);
            }, 250);
        } catch (error) {
            // Can't reproduce error on changing nickname
            // Added this to explicitly display the actual error object for PM-740
            console.error('Error updating profile settings:', error);

            toast.error(error?.response?.data?.message ?? "Oopps...something went wrong.", {
                id: "update-profile",
            });

            setIsSubmitting(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditingNickname, profileValues, userState?.id]);

    /**
     * React hook to handle invalid login scenarios.
     * If the user is authenticated but has an invalid login (`isInvalidLogin` is true), it logs an error message and initiates a logout process after a 3-second delay.
     *
     * @param {boolean} isAuthenticated - Indicates if the user is authenticated (logged in).
     * @param {boolean} isInvalidLogin - Indicates if the login is invalid (user object in the User context is missing or invalid).
     * @param {Function} logout - The function to initiate the logout process.
     */
    useMemo(() => {
        if (isAuthenticated && isInvalidLogin) {
            console.error("You don't have an user object in your User context! Logging out...");

            setTimeout(() => {
                setInvalidLogin(false);
                logoutUser();
            }, 3000);
        }
    }, [isInvalidLogin]); // eslint-disable-line react-hooks/exhaustive-deps

    // Executes user update when submit is true
    useEffect(() => {
        handleUpdateUser();
    }, [handleUpdateUser]);

    // Sets the initial state for the profile values from userState/auth0
    useEffect(() => {
        setProfileValues((prev) => ({
            ...prev,
            data: {
                ...prev.data,
                nickname: userState?.nickname ?? user?.nickname ?? "No nickname",
                email: userState?.email_address ?? "No email",
                showScoreLeaderboard: userState?.show_score_leaderboard ?? false,
                notifyDailyProperty: userState?.notify_daily_property ?? false,
                notificationSetting: userState?.notification_settings ?? [],
                isPro: userState?.is_pro,
                showStartupModal: userState?.show_startup_modal,
                useEmailAccountPicture: userState?.use_email_account_picture ?? false
            },
        }));
    }, [
        user?.nickname,
        userState?.email_address,
        userState?.is_pro,
        userState?.nickname,
        userState?.notify_daily_property,
        userState?.notification_settings,
        userState?.show_score_leaderboard,
        userState?.show_startup_modal,
        userState?.use_email_account_picture
    ]);

    if (isLoading) {
        return <div></div>;
    }
    return (
        isAuthenticated &&
        !isEmpty(userState?.id) && (
            <>
                {props.isOpen && (
                    <div
                        onClick={props.onClose}
                        className={clsx("profile-backdrop", props.isOpen && "open")}
                    />
                )}
                <div className={clsx("profile-drawer", props.isOpen && "open")}>
                    <div className="container profile py-4 profile-container">
                        <div className="border-b profile-section-header pb-2 mb-3 d-flex justify-content-between align-items-center flex-wrap gap-2">
                            <h3 className="app-text-title profile-section-title px-0 mb-0">Profile</h3>
                            <Link onClick={props.onClose} className="close-btn">
                                <GrClose fontSize={18} />
                            </Link>
                        </div>

                        <div className="section-group pb-2 mb-3 border-b">
                            <p className="fw-bold mb-2 section-label">Nickname</p>
                            {!isEditingNickname ? (
                                <div className="d-flex justify-content-between flex-wrap gap-2">
                                    <p>{userState.nickname ?? user.nickname}</p>
                                    <button
                                        className="app-text-link"
                                        onClick={() => setIsEditingNickname(true)}
                                    >
                                        Change nickname
                                    </button>
                                </div>
                            ) : (
                                <div className="profile-setting-container flex-grow-2">
                                    <form className="profile-form">
                                        <input
                                            type="text"
                                            placeholder="Enter nickname"
                                            value={profileValues.data.nickname}
                                            onChange={(e) =>
                                                setProfileValues((prev) => ({
                                                    ...prev,
                                                    data: { ...prev.data, nickname: e.target.value },
                                                }))
                                            }
                                        />
                                        <div className="btn-group">
                                            <button
                                                type="button"
                                                className="undo-btn"
                                                onClick={() => {
                                                    setIsEditingNickname(false);
                                                    setProfileValues((prev) => ({
                                                        ...prev,
                                                        data: {
                                                            ...prev.data,
                                                            nickname:
                                                                userState.nickname ??
                                                                user.nickname ??
                                                                "No nickname",
                                                        },
                                                    }));
                                                }}
                                            >
                                                <X />
                                            </button>
                                            <button
                                                onClick={() =>
                                                    setProfileValues((prev) => ({ ...prev, submit: true }))
                                                }
                                                type="button"
                                                className="save-btn"
                                            >
                                                {isSubmitting ? (
                                                    <ClipLoader
                                                        size={12}
                                                        aria-label="Loading Spinner"
                                                        data-testid="loader"
                                                        color="white"
                                                    />
                                                ) : (
                                                    <Check />
                                                )}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            )}
                        </div>

                        <div className="section-group pb-2 mb-3 border-b">
                            <div className="d-flex justify-content-between flex-wrap gap-2">
                                <p className="fw-bold mb-2 section-label">Use email account profile picture</p>
                                <Switch
                                    checked={profileValues?.data?.useEmailAccountPicture}
                                    onChange={(e) => {
                                        setProfileValues((prev) => ({
                                            ...prev,
                                            submit: true,
                                            data: {
                                                ...prev.data,
                                                useEmailAccountPicture: !prev.data.useEmailAccountPicture,
                                            },
                                        }));
                                    }}
                                />
                            </div>
                        </div>

                        <div className="section-group pb-2 mb-3 border-b">
                            <p className="fw-bold mb-2 section-label">Email</p>
                            <div className="d-flex justify-content-between flex-wrap gap-2">
                                <p>{userState.email_address}</p>
                            </div>
                            <div className="d-flex justify-content-between flex-wrap gap-2">
                                <span className="logout" onClick={() => logoutUser()} >Log out</span>
                            </div>
                        </div>

                        <div className="section-group pb-2 mb-3 border-b">
                            <div className="d-flex justify-content-between flex-wrap gap-2">
                                <p className="fw-bold mb-2 section-label">Show my score on the leaderboard</p>
                                <Switch
                                    checked={profileValues?.data?.showScoreLeaderboard}
                                    onChange={(e) => {
                                        setProfileValues((prev) => ({
                                            ...prev,
                                            submit: true,
                                            data: {
                                                ...prev.data,
                                                showScoreLeaderboard: !prev.data.showScoreLeaderboard,
                                            },
                                        }));
                                    }}
                                />
                            </div>
                        </div>

                        <NotificationSetting
                            notificationSetting={profileValues?.data?.notificationSetting}
                            notifyDailyProperty={profileValues?.data?.notifyDailyProperty}
                            setProfileValues={setProfileValues}
                        />
                    </div>
                </div>
            </>
        )
    );
};

export default ProfileDrawer;
