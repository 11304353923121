import React, { useState } from "react";
import AppButton from "../../common/buttons/app-button";
import Modal from "react-bootstrap/Modal";
import useModalState from "../../../hooks/useModalState";
import bonusGuessIcon from "../../../assets/icons-v2/bonus-property.png";
import "./bonus-property.scss";
import ProgressBar from "../../common/progress-bar/progress-bar";

const BonusPropertyModal = () => {
    const { isOpen, setModalId } = useModalState();
    const [isMouseDown, setIsMouseDown] = useState(false);

    return (
        <div onTouchStart={() => setIsMouseDown(true)} onTouchEnd={() => setIsMouseDown(false)}>
            <Modal
                centered
                onHide={() => setModalId("")}
                show={isOpen("BONUS_PROPERTY_MODAL")}
                backdrop="static"
                className="bonus-property-modal"
            >
                <Modal.Body className="pb-0">
                    <div>
                        <h5 className="mb-0 app-text-title text-center">Here comes your bonus guess!</h5>

                        <div className="bonus-guess-icon py-3">
                            <img src={bonusGuessIcon} alt="bonus-guess-icon" />
                        </div>
                        <p className="mb-0">You get one extra property to guess today - make it count!</p>
                    </div>
                </Modal.Body>
                <Modal.Footer className="pb-4 pt-3">
                    <AppButton
                        onClick={() => setModalId("")}
                        className="w-75 mx-auto"
                        variant="solid"
                        theme="purple"
                    >
                        Let's go!
                    </AppButton>
                </Modal.Footer>
                <ProgressBar
                    duration={3000}
                    onComplete={() => {
                        setModalId("");
                    }}
                    isMouseDown={isMouseDown}
                />
            </Modal>
        </div>
    );
};

export default BonusPropertyModal;
