import { get } from "lodash";

/**
 * Sets Open Graph metadata for a challenge link.
 *
 * @param {Object} property - The property object containing details about the property.
 * @param {number} [property.bedrooms=0] - The number of bedrooms in the property.
 * @param {number} [property.bathrooms=0] - The number of bathrooms in the property.
 * @param {string} [property.neighborhood=null] - The neighborhood where the property is located.
 * @param {string} [property.property_type=''] - The type of property.
 * @param {Array<string>} [property.image_urls=[]] - An array of image URLs for the property.
 * @param {string} challengeId - The unique identifier for the challenge.
 * @param {boolean} [inChallengeRoute=false] - Whether the link is in a challenge route.
 */
export function setChallengeLinkMetadata(property = {}, challengeId, inChallengeRoute = false) {
    if (property && challengeId) {
        const bedrooms = get(property, "bedrooms", 0);
        const bathrooms = get(property, "bathrooms", 0);
        const neighborhood = get(property, "neighborhood", null);
        const propertyType = get(property, "property_type", "");
        const imageUrls = get(property, "image_urls", []);

        const ogTitle = "Can you guess the price?";
        const ogDescription = `${bedrooms}br/${bathrooms}ba ${propertyType} ${
            neighborhood ? `in ${neighborhood}` : ""
        }`;
        const ogImage = imageUrls.length > 0 ? imageUrls[0] : "";
        const baseUrl = window.location.origin;
        const ogUrl = `${baseUrl}${inChallengeRoute ? "/challenge" : ""}?challenge_id=${challengeId}`;

        const setMetaTag = (property, content) => {
            const metaTag = document.querySelector(`meta[property="${property}"]`);
            if (metaTag) {
                metaTag.setAttribute("content", content);
            }
        };

        setMetaTag("og:title", ogTitle);
        setMetaTag("og:description", ogDescription);
        setMetaTag("og:image", ogImage);
        setMetaTag("og:url", ogUrl);
    }
}
