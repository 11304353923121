module.exports = {
    AWARDS: {
        WEEKLY_REGIONAL_FIRST_PLACE_TROPHY: {
            name: "WEEKLY_REGIONAL_FIRST_PLACE_TROPHY",
            label: "Regional Weekly 1st Place",
            type: "trophy",
            icon: "trophy-1.png",
            placement: 1,
        },
        WEEKLY_REGIONAL_SECOND_PLACE_TROPHY: {
            name: "WEEKLY_REGIONAL_SECOND_PLACE_TROPHY",
            label: "Regional Weekly 1st Place",
            type: "trophy",
            icon: "trophy-2.png",
            placement: 2,
        },
        WEEKLY_REGIONAL_THIRD_PLACE_TROPHY: {
            name: "WEEKLY_REGIONAL_THIRD_PLACE_TROPHY",
            label: "Regional Weekly 1st Place",
            type: "trophy",
            icon: "trophy-3.png",
            placement: 3,
        },
        DAILY_REGIONAL_FIRST_PLACE_MEDAL: {
            name: "DAILY_REGIONAL_FIRST_PLACE_MEDAL",
            label: "Regional Daily 1st Place",
            type: "medal",
            icon: "medal-1.png",
            placement: 1,
        },
        DAILY_REGIONAL_SECOND_PLACE_MEDAL: {
            name: "DAILY_REGIONAL_SECOND_PLACE_MEDAL",
            label: "Regional Daily 2nd Place",
            type: "medal",
            icon: "medal-2.png",
            placement: 2,
        },
        DAILY_REGIONAL_THIRD_PLACE_MEDAL: {
            name: "DAILY_REGIONAL_THIRD_PLACE_MEDAL",
            label: "Regional Daily 3rd Place",
            type: "medal",
            icon: "medal-3.png",
            placement: 3,
        },
    },
};
