import React from "react";
import useModalState from "../../../hooks/useModalState";
import WarningModal from "../warning-modal/warning-modal";
import ChallengedModal from "../ChallengedModal";
import AwardsModal from "../awards-modal/awards-modal";
import StartupModal from "../startup-modal/startup-modal";
import BonusEarnedModal from "../bonus-earned-modal";
import BonusPropertyModal from "../bonus-property-modal";
import { AddToHomeScreenCta } from "../../../pages/leaderboard/components";
import SelectLocationModal from "../select-location-modal/select-location-modal";

/**
 * NEW GLOBAL MODALS SHOULD BE ADDED HERE
 * USE useModalState HOOK FOR GLOBAL MODALS
 */

const RootModals = () => {
    const { isOpen } = useModalState();
    return (
        <>
            {/** Warning Modal KEY: WARNING_MODAL */}
            {isOpen("WARNING_MODAL") && <WarningModal />}

            {/** Awards Modal KEY: AWARDS_MODAL */}
            {isOpen("AWARDS_MODAL") && <AwardsModal />}

            {/** Challenged Modal KEY: CHALLENGED_MODAL */}
            {isOpen("CHALLENGED_MODAL") && <ChallengedModal />}

            {/** Startup Modal KEY: STARTUP_MODAL */}
            {isOpen("STARTUP_MODAL") && <StartupModal />}

            {/** Add To Home Screen Modal KEY: ADD_TO_HOME_SCREEN_MODAL */}
            {isOpen("ADD_TO_HOME_SCREEN_MODAL") && <AddToHomeScreenCta />}

            {/** Bonus earned modal KEY: BONUS_EARNED_MODAL */}
            {isOpen("BONUS_EARNED_MODAL") && <BonusEarnedModal />}

            {/** Bonus property modal KEY: BONUS_PROPERTY_MODAL */}
            {isOpen("BONUS_PROPERTY_MODAL") && <BonusPropertyModal />}

            {isOpen("SELECT_LOCATION_MODAL") && <SelectLocationModal />}
        </>
    );
};

export default RootModals;
