import React from "react";
import PropTypes from "prop-types";
import { Modal as BootstrapModal } from "react-bootstrap";
import Drawer from "@mui/material/Drawer";
import { isMobile } from "react-device-detect";

/**
 * ResponsiveWrapper component that conditionally renders either a modal or a drawer
 * based on the device type (mobile or desktop).
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.show - Boolean flag to control the visibility of the modal/drawer.
 * @param {Function} props.onHide - Function to handle the hide action for the modal/drawer.
 * @param {React.ReactNode} props.children - The content to be displayed within the modal/drawer.
 * @param {String} props.className - Classname to be used also in PaperProps.
 * @returns {JSX.Element} The rendered component.
 */
const ResponsiveWrapper = ({ show, onHide, children, className, ...props }) => {
    return isMobile ? (
        <Drawer
            anchor="bottom"
            open={show}
            onClose={onHide}
            PaperProps={{ sx: { height: "90vh" }, className }}
            {...props}
        >
            {children}
        </Drawer>
    ) : (
        <BootstrapModal show={show} onHide={onHide} className={className} {...props}>
            {children}
        </BootstrapModal>
    );
};

ResponsiveWrapper.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default ResponsiveWrapper;
