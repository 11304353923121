import axios from "axios";

/**
 * Retrieves the notification settings for a specific user.
 *
 * @param {string} userId - The unique identifier of the user.
 * @returns {Promise<Object>} - A Promise that resolves to an object representing the user's notification settings.
 *
 */
export const getUserNotificationSettings = async (userId) => {
    return (await axios.get(`/api/notifications/user/${userId}`)).data;
};
