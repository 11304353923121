import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import { get, sum, initial } from "lodash";
import Sticky from "react-sticky-el";
import MoonLoader from "react-spinners/MoonLoader";

import PropertyInfo from "../../../../components/common/PropertyInfo";
import CustomButton from "../../../../components/common/buttons/custom-button/custom-button";
import CustomSvg from "../../../../components/common/custom-svg/custom-svg";
import ProgressIndicator from "../../../../components/common/progress-indicator/progress-indicator";
import PlusMinusResultModal from "../../../../components/modals/PlusMinusResultModal";

import { PLUS_MINUS_VERSIONS } from "../../../../constants/plus-minus-versions";
import { getRandomProperty } from "../../../../services/UserService";
import { formatPrice } from "../../../../helpers/helpers";
import useGAEvent from "../../../../hooks/useGAEvent";
import UndoIcon from "../../../../assets/icons-v2/undo.png";

import "./plus-minus4.scss";

const increments = [
    {
        label: "$1K",
        value: 1000,
    },
    {
        label: "$10K",
        value: 10000,
    },
    {
        label: "$100K",
        value: 100000,
    },
    {
        label: "$1M",
        value: 1000000,
    },
];

/**
 * PlusMinus game component that allows users to guess whether the price is higher or lower.
 *
 * @component
 * @param {object} props - The component props.
 * @param {string} props.version - The version of the PlusMinus game.
 * @returns {JSX.Element} The PlusMinus game interface.
 */
export default function PlusMinus4({ version }) {
    const bodyRef = useRef(null);
    const [points, setPoints] = useState(0);
    const [property, setProperty] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedIncrements, setSelectedIncrements] = useState([]);
    const [busted, setBusted] = useState(false);
    const [dirty, setDirty] = useState(false);
    const [unBlurAll, setUnBlurAll] = useState(false);
    const { sendEvent } = useGAEvent();
    const currentVersion = PLUS_MINUS_VERSIONS[version];
    const maxScore = currentVersion?.max_score ?? null;
    const totalGuessPrice = sum(selectedIncrements);
    const currentRound = points + 1;

    const dailyProperties = useQuery({
        queryKey: ["plus-minus-random-property"],
        queryFn: getRandomProperty,
        enabled: true,
        cacheTime: 0,
        onSuccess: (data) => {
            const property = get(data, "data.property", null);
            if (property?.price) {
                setProperty(property);
                sendEvent("plus_minus_start", { plusMinusVersion: version });
            }
        },
    });

    const isLoading = dailyProperties?.isLoading || dailyProperties?.isFetching || !property;
    const actualPrice = property?.price;

    /**
     * Handles the user's guess for whether the price is higher or lower.
     *
     * @param {string} increment - Increments the guess price.
     */
    const handleClick = (increment) => {
        setDirty(true);
        setSelectedIncrements([...selectedIncrements, increment]);
    };

    const handleUndo = () => {
        // Remove the last item
        const newIncrements = initial(selectedIncrements);
        setSelectedIncrements(newIncrements);
    };

    const handleSubmit = () => {
        let correct = true;
        const newPoints = points + 1;
        const actualPricePercent = actualPrice * currentVersion?.price_percent; // 5% of the actual price
        const lowerBound = actualPrice - actualPricePercent; // 5% below the actual price
        const upperBound = actualPrice + actualPricePercent; // 5% above the actual price
        const solved = totalGuessPrice >= lowerBound && totalGuessPrice <= upperBound;

        if (totalGuessPrice > actualPrice || (newPoints >= maxScore && !solved)) {
            setBusted(true);
            setShowModal(true);
            setUnBlurAll(true);
            correct = false;
        } else if (solved) {
            setShowModal(true);
            setPoints(newPoints);
            setUnBlurAll(true);
        } else {
            setPoints(newPoints);
        }

        setDirty(false);

        sendEvent("plus_minus_guess", {
            plusMinusGuess: totalGuessPrice,
            plusMinusCorrect: correct,
            plusMinusVersion: version,
        });
    };

    return isLoading ? (
        <div className="d-flex align-items-center justify-content-center h-75">
            <MoonLoader size={40} color="#63c19f" />
        </div>
    ) : (
        <div className="property-container" ref={bodyRef}>
            <PropertyInfo property={property} round={currentRound} unBlurAll={unBlurAll} />
            <Sticky className="guess-input-sticky" mode="bottom" positionRecheckInterval={50}>
                <div className="guess-input-container overflow-hidden px-3 py-3 guess-input-container-shadow">
                    <div className="flex-wrap d-flex align-items-center justify-content-center">
                        {totalGuessPrice > 0 ? (
                            <div className="d-flex align-items-center justify-content-between gap-1">
                                <CustomButton
                                    className="undo-btn gold-solid"
                                    handleClick={handleUndo}
                                    isDisabled={!dirty}
                                    text={<CustomSvg src={UndoIcon} size={{ height: 20, width: 20 }} />}
                                />
                                <div className="app-text-title text-center" style={{ fontSize: 18 }}>
                                    Your guess: {formatPrice(totalGuessPrice)}
                                </div>
                                <CustomButton
                                    className="submit-btn gold-solid"
                                    handleClick={handleSubmit}
                                    isDisabled={!dirty}
                                    text="Submit"
                                />
                            </div>
                        ) : (
                            <div className="text-center">
                                <div className="subtitle" style={{ fontSize: 20, fontWeight: "normal" }}>
                                    Make your first guess:
                                </div>
                                <div>Add $1K to $1M - remember not to go over!</div>
                            </div>
                        )}
                        <div className="score-to-beat mw-100 mb-3 px-4">
                            <ProgressIndicator
                                type="plus-minus"
                                index={points}
                                latestIndex={points}
                                progressLength={maxScore}
                            />
                        </div>
                    </div>
                    <div className="flex-wrap d-flex align-items-center justify-content-between">
                        {increments.map((increment) => (
                            <div style={{ width: "22%" }}>
                                <CustomButton
                                    className="w-100 d-flex align-items-center justify-content-center light-purple-solid rounded"
                                    handleClick={() => handleClick(increment.value)}
                                    text={increment.label}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </Sticky>
            {showModal && (
                <PlusMinusResultModal
                    show={showModal}
                    handleClose={() => setShowModal(false)}
                    score={points}
                    actualPrice={formatPrice(actualPrice)}
                    version={version}
                    busted={busted}
                />
            )}
        </div>
    );
}

PlusMinus4.propTypes = {
    version: PropTypes.string.isRequired,
};
