import clsx from "clsx";

const Mailto = ({
  email = "",
  subject = "",
  body = "",
  children,
  className = "",
  id = "",
}) => {
  let params = subject || body ? "?" : "";
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

  return (
    <a
      id={id}
      className={clsx("text-decoration-none", className)}
      href={`mailto:${email}${params}`}
    >
      {children}
    </a>
  );
};

export default Mailto;
