import React from "react";
import "./app-button.scss";
import PropTypes from "prop-types";
import Loader from "../../loader/loader";

const AppButton = ({
    id = "",
    className = "",
    onClick = () => {},
    children = null,
    variant = "solid", // solid or bordered
    theme = "gold", // purple or gold
    type = "button",
    isDisabled = false,
    showLoader = false,
}) => {
    return (
        <button
            id={id}
            disabled={isDisabled}
            className={`app-button ${className} ${theme}-${variant} ${isDisabled ? "disabled" : ""}`}
            onClick={onClick}
            type={type}
        >
            {showLoader ? (
                isDisabled ? (
                    <Loader isInline size={18.67} loading={isDisabled} />
                ) : (
                    children
                )
            ) : (
                children
            )}
        </button>
    );
};

AppButton.propTypes = {
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    showLoader: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    variant: PropTypes.string,
    theme: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string,
};

export default AppButton;
