import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import AppButton from "../../common/buttons/app-button";
import Modal from "react-bootstrap/Modal";
import { motion, useAnimation } from "framer-motion";
import Soldout from "../../../assets/icons-v2/soldout.png";
import CustomSvg from "../../common/custom-svg/custom-svg";
import "./soldout-modal.scss";
import useGAEvent from "../../../hooks/useGAEvent";
import config from "../../../config";

const duration = 0.5;
const delay = 2;

const swingAnimation = {
    rotate: [-10, 10, -8, 8, -6, 6, -4, 4, -2, 2, 0],
    transition: {
        duration: 1.5,
        ease: "easeInOut",
        repeat: 0,
    },
};

const textAnimationIn = {
    x: ["100%", "0%"],
    transition: {
        duration,
        ease: "easeInOut",
    },
};

const textAnimationOut = {
    x: ["0%", "-100%"],
    transition: {
        duration,
        ease: "easeInOut",
        delay,
    },
};

const SoldoutModal = ({ show, handleClose, orderedScores }) => {
    const { isAuthenticated } = useAuth0();
    const { sendEvent } = useGAEvent();
    const [headerText, setHeaderText] = useState(null);
    const controls = useAnimation();
    const swingControls = useAnimation();

    const handleSendEvent = (eventName) => {
        sendEvent(eventName, {
            isAuthenticated,
            numberOfGuesses: orderedScores.length,
        });
    };

    const handleSendAnother = () => {
        handleClose(false);
        handleSendEvent("sold_out_send_another");
    };

    const handleDoneForNow = () => {
        handleClose(true);
        handleSendEvent("sold_out_done");
    };

    useEffect(() => {
        handleSendEvent("sold_out_view");
        if (!headerText) {
            setTimeout(() => {
                setHeaderText("first");
            }, 500);
        } else {
            controls.start(textAnimationIn).then(() => {
                if (headerText === "first") {
                    const animationDuration = duration * 1000;
                    const animationDelay = delay * 1000;

                    controls.start(textAnimationOut);
                    setTimeout(() => {
                        setHeaderText("second");
                        swingControls.stop();
                    }, animationDelay + animationDuration);
                }
            });
        }

        swingControls.start(swingAnimation);
    }, [headerText]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Modal show={show} onHide={handleClose} centered backdrop="static" className="soldout-modal">
            <div className="close-icon-header">
                <motion.div className="header-icon" animate={swingControls}>
                    <CustomSvg src={Soldout} size={{ height: 100, width: 100 }} />
                </motion.div>
            </div>
            <Modal.Body className="modal-text-container py-0">
                <div>
                    {headerText && (
                        <motion.h5
                            key={headerText}
                            className="mb-0 app-text-title text-center"
                            animate={controls}
                        >
                            {headerText === "first" ? (
                                `You’ve guessed ${orderedScores.length} out of 6 daily properties...`
                            ) : (
                                <>
                                    ...unlock all {config.MAXIMUM_GUESS} for a <i>complete game</i>!
                                </>
                            )}
                        </motion.h5>
                    )}
                    <motion.p
                        className="mb-0"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 4.5 }}
                    >
                        Pick a property, then{" "}
                        <a
                            href={`${window.location.origin}/faq`}
                            title="FAQ address"
                            class="to-faq-text-link"
                            target="_blank"
                            rel="noreferrer"
                        >
                            challenge a friend
                        </a>{" "}
                        to beat your score. Unlock additional properties when you win.
                    </motion.p>
                    <motion.p
                        className="mb-0"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 5 }}
                    >
                        Your goal is to unlock all 6 properties for today.
                    </motion.p>
                    <motion.p
                        className="mb-0"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 5.5 }}
                    >
                        You’ve got {config.MAXIMUM_GUESS - orderedScores.length} more to go!
                    </motion.p>
                </div>
            </Modal.Body>
            <Modal.Footer className="pb-4 pt-3">
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 6 }}
                    className="w-100 mx-auto text-center"
                >
                    <AppButton
                        onClick={handleDoneForNow}
                        className="w-75 mx-auto send-challenge"
                        variant="bordered"
                        theme="purple"
                    >
                        See Final Score
                    </AppButton>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 6.5 }}
                    className="w-100 mx-auto text-center"
                >
                    {isAuthenticated && (
                        <AppButton
                            onClick={handleSendAnother}
                            className="w-75 mx-auto send-challenge"
                            variant="solid"
                            theme="gold"
                        >
                            Pick a Property
                        </AppButton>
                    )}
                </motion.div>
            </Modal.Footer>
        </Modal>
    );
};

SoldoutModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    orderedScores: PropTypes.array.isRequired,
};

export default SoldoutModal;
