import { useLocation } from "react-router-dom";

/**
 * A custom hook that tracks a specified parameter in the URL and invokes a callback when the parameter is present.
 *
 * @hook
 */
const useCustomParamTracker = () => {
    const location = useLocation(); 

    const trackCustomParam = (paramName, callback) => {
        const searchParams = new URLSearchParams(location.search);
        const paramValue = searchParams.get(paramName);

        if (paramValue === "true") {
            callback();

            // Remove the parameter and reset the flag
            searchParams.delete(paramName);
            const newUrl = `${location.pathname}${searchParams.toString()}`;
            window.history.replaceState({}, "", newUrl);
        }
    };

    return { trackCustomParam };
};

export default useCustomParamTracker;
