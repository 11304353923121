import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { getStartAndEndOfWeek } from "../../../helpers/helpers";
import moment from "moment";
import PropTypes from "prop-types";
import "react-datepicker/dist/react-datepicker.css";
import "./custom-date-picker.scss";

const CustomDatePicker = ({ onSelect = (value) => {}, selected }) => {
  const [localSelected, setLocalSelected] = useState(new Date());
  const [isPickingDate, setIsPickingDate] = useState(false);
  const currentDate = new Date().toLocaleDateString();
  const { startOfWeek } = getStartAndEndOfWeek(currentDate);

  return (
    <div className="date-picker-container">
      {isPickingDate ? (
        <DatePicker
          popperPlacement="bottom"
          popperClassName="date-picker-popper"
          wrapperClassName="date-picker-wrapper"
          onClickOutside={() => setIsPickingDate(false)}
          onCalendarClose={() => setIsPickingDate(false)}
          onSelect={(value) => {
            onSelect(value);
            setLocalSelected(value);
          }}
          selected={localSelected}
          placeholderText={localSelected}
          autoFocus
        />
      ) : (
        <div className="dropdown-text" onClick={() => setIsPickingDate(true)}>
          <h5 className="fw-bold mb-0">
            {selected ?? `Week of ${moment(startOfWeek).format("MMMM DD")}`}
          </h5>
        </div>
      )}
      <div
        onClick={() => setIsPickingDate(!isPickingDate)}
        className="dropdown-button"
      >
        {isPickingDate ? (
          <FaAngleUp size={19} className="dropdown-icon" />
        ) : (
          <FaAngleDown size={19} className="dropdown-icon" />
        )}
      </div>
    </div>
  );
};

CustomDatePicker.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
};

export default CustomDatePicker;
