import React, { useEffect, useCallback, useState } from "react";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { useUser } from "../../../context/UserContext";
import OneSignal from "react-onesignal";
import config from "../../../config";
import { getUserGuessesPerPeriod } from "../../../services/UserService";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { getUserNotificationSettings } from "../../../services/NotificationService";

const OneSignalWrapper = ({ children }) => {
    const { isAuthenticated } = useAuth0();
    const [value] = useLocalStorage("guest_gameplay"); // eslint-disable-line no-unused-vars
    const [userData] = useLocalStorage("user_data");
    const { userState } = useUser();
    const [isInitializedOneSignal, setIsInitializedOneSignal] = useState(false);

    // Get users notification settings
    const userNotifSettings = useQuery({
        queryKey: ["user-notif-settings", userState?.id],
        queryFn: () => getUserNotificationSettings(userState?.id),
        enabled: !!userState?.id && isAuthenticated,
    });

    /**
     * Function that sets the frequency tag of a user
     */
    const setPushFrequency = useCallback(async () => {
        try {
            // Don't execute if user is not logged in
            if (!isAuthenticated) return;

            if (userNotifSettings?.data?.message?.push_frequency === "daily") {
                OneSignal.User.addTag("frequency", "daily");
            } else if (userNotifSettings?.data?.message?.push_frequency === "weekly") {
                OneSignal.User.addTag("frequency", "weekly");
            } else {
                OneSignal.User.addTag("frequency", "never");
            }
        } catch (error) {
            console.log(error);
        }
    }, [isAuthenticated, userNotifSettings?.data?.message]);

    /**
     * Function to initialize OneSignal
     */
    const initOneSignal = useCallback(async () => {
        // Check if OneSignal is already initialized
        if (isInitializedOneSignal) {
            return;
        }

        // Initialize OneSignal
        await OneSignal.init({
            appId: config.ONESIGNAL_ID,
            allowLocalhostAsSecureOrigin: true,
            promptOptions: {
                customlink: {
                    enabled: true /* Required to use the Custom Link */,
                    style: "button" /* Has value of 'button' or 'link' */,
                    size: "medium" /* One of 'small', 'medium', or 'large' */,
                    color: {
                        button: "#E12D30" /* Color of the button background if style = "button" */,
                        text: "#FFFFFF" /* Color of the prompt's text */,
                    },
                    text: {
                        subscribe: "Subscribe" /* Prompt's text when not subscribed */,
                        unsubscribe: "Unsubscribe" /* Prompt's text when subscribed */,
                        explanation:
                            "Almost done! Get notified when the next batch of properties is ready." /* Optional text appearing before the prompt button */,
                    },
                    unsubscribeEnabled: true /* Controls whether the prompt is visible after subscription */,
                },
            },
        });

        setIsInitializedOneSignal(true);
    }, [isInitializedOneSignal]);

    // Sets has played tag for OneSignal
    const setHasPlayed = useCallback(async () => {
        try {
            // Check if OneSignal is initialized
            if (isInitializedOneSignal) {
                // If isAuthenticated count user guesses from API, else get from local storage
                if (!!userData?.created_at) {
                    if (userState?.id) {
                        const userGuesses = await getUserGuessesPerPeriod({
                            user_id: userState?.id,
                        });
                        // hasPlayed tag setter
                        if (userGuesses?.data?.count?.total > 0) {
                            OneSignal.User.addTag("hasPlayed", "true");
                        } else {
                            OneSignal.User.addTag("hasPlayed", "false");
                        }
                    }
                } else {
                    // hasPlayed tag setter
                    if (value?.length > 0) {
                        OneSignal.User.addTag("hasPlayed", "true");
                    } else {
                        OneSignal.User.addTag("hasPlayed", "false");
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }, [
        isInitializedOneSignal,
        userData?.created_at,
        userState?.id,
        value?.length,
    ]);

    // Initialize One Signal
    useEffect(() => {
        void initOneSignal();
    }, [initOneSignal]);

    // Set has played tag for OneSignal
    useEffect(() => {
        void setHasPlayed();
    }, [setHasPlayed]);

    // Set push frequence tag for OneSignal
    useEffect(() => {
        void setPushFrequency();
    }, [setPushFrequency]);

    // Set OneSignal user id tag and alias
    useEffect(() => {
        if (!!userState?.id) {
            OneSignal.User.addAlias("userId", userState?.id);
            OneSignal.User.addTag("userId", userState?.id);
        }
    }, [userState?.id]);

    return <>{children}</>;
};

export default OneSignalWrapper;
