import React, { createContext, useState, useContext } from "react";

// Modal provider for global modals
export const ModalContext = createContext({
    modalId: "",
    setModalId: () => {},
});

const ModalProvider = ({ children }) => {
    const [modalContent, setModalContent] = useState({
        title: "",
        message: "",
        ctaTitle: "",
        cb: () => ({}),
        icon: "",
    });
    const [modalId, setModalId] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    return (
        <ModalContext.Provider
            value={{ modalId, setModalId, modalContent, setModalContent, isLoading, setIsLoading }}
        >
            {children}
        </ModalContext.Provider>
    );
};

export const useModal = () => useContext(ModalContext);

export default ModalProvider;
