import React, { useEffect, useState } from "react";
import "./total-score.scss";
import { useScore } from "../../../context/ScoreContext";
import { useUser } from "../../../context/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import { isNil } from "lodash";
import clsx from "clsx";

const TotalScore = () => {
    const { isAuthenticated, isLoading } = useAuth0();
    const { userState } = useUser();
    const [animate, setAnimate] = useState(false);
    const { totalScore, fetchUserDailyScore } = useScore();

    /**
     * Fetch the user's daily score when the user is authenticated, has user state and the daily score is displayed.
     *
     * @param {Function} fetchUserDailyScore - The function to fetch the user's daily score.
     * @param {boolean} isAuthenticated - A boolean indicating whether the user is authenticated.
     * @param {Object|null} userState - The user's state, which can be null if the user is not authenticated.
     */
    useEffect(() => {
        if (!isLoading && !isNil(userState)) {
            fetchUserDailyScore(isAuthenticated, userState.id, userState.current_area_id);
        }
    }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (totalScore) {
            setAnimate(true);
        }
    }, [totalScore]);

    return (
        <div className="total-score d-flex justify-content-end">
            <div>
                <div>
                    <span>Total Score: </span>
                    <span
                        className={clsx("app-text-title score", animate && "score-explode")}
                        onAnimationEnd={() => setAnimate(false)}
                    >
                        {totalScore ?? 0}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default TotalScore;
