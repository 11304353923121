import React from "react";
import PropTypes from "prop-types";
import { IoCheckmarkCircle, IoCheckmarkCircleOutline } from "react-icons/io5";
import { isEmpty } from "lodash";
import clsx from "clsx";

const BtnSubmit = ({ rowValue, rowIndex, gameIndex, handleSubmit }) => {
    const enableSubmitButton = rowValue.every((row) => !isEmpty(row));
    const showButton = rowIndex >= gameIndex;

    return (
        <div
            className={clsx(
                enableSubmitButton ? "tw-bg-black" : "tw-bg-gray-300",
                !showButton && "tw-opacity-0",
                "tw-w-[22px] tw-h-[22px] tw-rounded-[50%] tw-ml-1"
            )}
        >
            {showButton &&
                (enableSubmitButton ? (
                    <IoCheckmarkCircle
                        className="hover:tw-cursor-pointer tw-text-[#f69a29] tw--ml-[20%] tw--mt-[20%]"
                        fontSize={30}
                        onClick={handleSubmit}
                    />
                ) : (
                    <IoCheckmarkCircleOutline
                        className="hover:tw-cursor-not-allowed tw-text-gray-400 tw--ml-[20%] tw--mt-[20%]"
                        fontSize={30}
                        fontWeight={200}
                        onClick={(e) => e.stopPropagation()}
                    />
                ))}
        </div>
    );
};

BtnSubmit.propTypes = {
    rowValue: PropTypes.array.isRequired,
    rowIndex: PropTypes.number.isRequired,
    gameIndex: PropTypes.number.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default BtnSubmit;
