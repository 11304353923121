import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { filter, findIndex, some, split, toUpper, isEmpty, isNil, trim, toLower, without } from "lodash";
import TextTruncate from "react-text-truncate";
import { Tooltip } from "react-tooltip";
import { useAuth0 } from "@auth0/auth0-react";
import clsx from "clsx";

import CustomMap from "../custom-map/custom-map";
import PropertyImageSwiper from "../property-image-swiper/property-image-swiper";
import SalesInfo from "../../../pages/daily-properties/sales-info/sales-info";
import SimilarProperties from "../../../pages/daily-properties/similar-properties/similar-properties";

import { PROPERTY_TYPE } from "../../../lang/property-type.lang";
import { TOOLTIP_TEXT } from "../../../lang/tooltip-text.lang";
import { isZero } from "../../../helpers/helpers";

import "./property-info.scss";

/**
 * Component for displaying property information.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.property - The property data.
 * @param {Array} props.arrProperties - The array of properties.
 * @returns {JSX.Element} Property information component.
 */
const PropertyInfo = ({ property, arrProperties, showMoreDetails = true, round, unBlurAll }) => {
    const [showMore, setShowMore] = useState(false);
    const [blurredBlocks, setBlurredBlocks] = useState([2, 3, 4, 5, 6, 7, 8]); // block 1 is always revealed initially
    const [hasUnblurredTheRound, setHasUnblurredTheRound] = useState(true);
    const { isAuthenticated } = useAuth0();
    const propertyAddress = split(property.street_address, ",")[0];
    const isPlusMinus = !isNil(round);

    /**
     * Function to check and update property address if duplicates are found
     *
     * @param {Object} property - The property data.
     * @param {Array} arrProperties - The array of properties.
     * @returns {string} The unique property address.
     */
    const makeAddressUnique = (property, arrProperties) => {
        const normalizedStreetAddress = toLower(trim(propertyAddress));
        const normalizedNeighborhood = toLower(trim(property.neighborhood));

        if (!arrProperties) {
            return propertyAddress;
        }

        const otherProperties = filter(arrProperties, (p) => p.id !== property.id);
        const hasDuplicate = some(
            otherProperties,
            (p) =>
                split(toLower(trim(p.street_address)), ",")[0] === normalizedStreetAddress &&
                toLower(trim(p.neighborhood)) === normalizedNeighborhood
        );

        if (hasDuplicate) {
            const currentPropertyIndex = findIndex(arrProperties, { id: property.id });
            return `#${currentPropertyIndex + 1} ${propertyAddress}`;
        }

        return propertyAddress;
    };

    const uniquePropertyAddress = makeAddressUnique(property, arrProperties);

    const isCommonLotDimension =
        !isNil(property?.lot_dimensions) &&
        !isEmpty(property.lot_dimensions) &&
        toUpper(property.lot_dimensions) === "COMMON";

    const isUnknownLaundryFeature =
        !isNil(property?.laundry_features) &&
        !isEmpty(property.laundry_features) &&
        toUpper(property.laundry_features) === "UNKNOWN";

    const handleRevealInfo = (block) => {
        const expectedUnblurredBlocks = round - 1; // How many should have been unblurred
        const actualUnblurredBlocks = 7 - blurredBlocks.length; // How many are actually unblurred

        // Check if user has already unblurred info this round
        if (
            (!hasUnblurredTheRound || expectedUnblurredBlocks > actualUnblurredBlocks) &&
            isPlusMinus &&
            round !== 1
        ) {
            setBlurredBlocks((prev) => without(prev, block));
            setHasUnblurredTheRound(true); // Mark that info has been unblurred for this round
        }
    };

    // Track round changes and reset `hasUnblurredTheRound` when a new round starts
    useEffect(() => {
        if (round > 1) {
            setHasUnblurredTheRound(false);
        }
    }, [round]);

    useEffect(() => {
        if (unBlurAll) {
            setBlurredBlocks([]);
        }
    }, [unBlurAll]);

    return (
        <div className="position-relative">
            <PropertyImageSwiper
                property={property}
                round={round}
                blurredBlocks={blurredBlocks}
                handleRevealInfo={handleRevealInfo}
                hasUnblurredTheRound={hasUnblurredTheRound}
            />

            <div className="property-details property-name d-flex flex-column px-2">
                <div className="property-details-key">
                    <div
                        className={clsx({
                            "property-details-blur": isPlusMinus && blurredBlocks.includes(4),
                        })}
                        onClick={() => handleRevealInfo(4)}
                    >
                        <h2 id="address">
                            {uniquePropertyAddress} | {property.neighborhood}
                        </h2>
                    </div>

                    <div
                        className={clsx("main-info-row px-0", {
                            "property-details-blur": isPlusMinus && blurredBlocks.includes(5),
                        })}
                        onClick={() => handleRevealInfo(5)}
                    >
                        <div className="main-info-column bed-column">
                            <p>Beds</p>
                            <span id="bedrooms">{property.bedrooms}</span>
                        </div>
                        <div className="main-info-column bath-column">
                            <p>Baths</p>
                            <span id="bathrooms">{property.bathrooms}</span>
                        </div>
                        <div className="main-info-column sq-column">
                            <p>Sq. Ft</p>
                            <span
                                className={clsx(!isZero(property.square_footage) && "has-tooltip")}
                                id="square-footage"
                                data-tooltip-id="square-footage"
                            >
                                {isZero(property.square_footage) ? "Unknown" : property.square_footage}
                            </span>
                            {!isZero(property.square_footage) &&
                                isPlusMinus &&
                                !blurredBlocks.includes(5) && (
                                    <Tooltip
                                        id="square-footage"
                                        anchorSelect="[data-tooltip-id='square-footage']"
                                        content={TOOLTIP_TEXT.SQUARE_FOOTAGE}
                                        place="top"
                                    />
                                )}
                        </div>
                        <div className="main-info-column type-column">
                            <p>Type</p>
                            <span id="property-type">
                                {PROPERTY_TYPE[property.property_type] || property.property_type}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={clsx("property-map-container px-0 px-sm-2", !showMoreDetails && "minimized-map", {
                    "property-details-blur": isPlusMinus && blurredBlocks.includes(4),
                    unclickable: isPlusMinus && hasUnblurredTheRound,
                })}
                onClick={() => handleRevealInfo(4)}
            >
                <CustomMap property={property} mapOverlayed={!showMoreDetails} />
            </div>

            {showMoreDetails ? (
                <div className="property-details px-3">
                    <div className="property-details-key">
                        <div className="about-listing-row pb-3">
                            <div className="px-0">
                                <h3 className="mt-2">
                                    <strong className="subtitle">About this listing</strong>
                                </h3>
                                {!showMore ? (
                                    <div
                                        className={clsx({
                                            "property-details-blur": isPlusMinus && blurredBlocks.includes(7),
                                            unclickable: isPlusMinus && hasUnblurredTheRound,
                                        })}
                                        onClick={() => handleRevealInfo(7)}
                                    >
                                        <TextTruncate
                                            line={3}
                                            element="p"
                                            truncateText="…"
                                            text={property.public_remarks}
                                            containerClassName="truncated-public-remarks-container"
                                            textTruncateChild={
                                                <button
                                                    onClick={() => setShowMore(true)}
                                                    className="show-more-btn mt-2"
                                                >
                                                    Show More
                                                </button>
                                            }
                                        />
                                    </div>
                                ) : (
                                    <p>
                                        <span className="public-remarks">{property.public_remarks}</span>{" "}
                                        <span>
                                            <button
                                                onClick={() => setShowMore(false)}
                                                className="show-more-btn mt-2"
                                            >
                                                Show less
                                            </button>
                                        </span>
                                    </p>
                                )}
                            </div>
                        </div>

                        <div
                            className={clsx({
                                "property-details-blur": isPlusMinus && blurredBlocks.includes(6),
                                unclickable: isPlusMinus && hasUnblurredTheRound,
                            })}
                            onClick={() => handleRevealInfo(6)}
                        >
                            <SimilarProperties property={property} isAuthenticated={isAuthenticated} />
                        </div>

                        <div id="additional-details">
                            <div className="pt-3 pb-4" onClick={() => handleRevealInfo(8)}>
                                <h3 className="mb-2">
                                    <strong className="subtitle">Additional Details</strong>
                                </h3>
                                <div className="details-item d-flex justify-content-between">
                                    <span>Parking Spots:</span>
                                    <p
                                        className={clsx({
                                            "property-details-blur": isPlusMinus && blurredBlocks.includes(8),
                                        })}
                                    >{`${property.parking_spots} ${property.parking_type}`}</p>
                                </div>
                                <div className="details-item d-flex justify-content-between">
                                    <span>Year Built:</span>
                                    <p
                                        className={clsx({
                                            "property-details-blur": isPlusMinus && blurredBlocks.includes(8),
                                        })}
                                    >
                                        {isZero(property.year_built) ? "Unknown" : property.year_built}
                                    </p>
                                </div>
                                <div className="details-item d-flex justify-content-between">
                                    <span>Exterior Features:</span>
                                    <p
                                        className={clsx({
                                            "property-details-blur": isPlusMinus && blurredBlocks.includes(8),
                                        })}
                                    >
                                        {property.exterior_features}
                                    </p>
                                </div>
                                <div className="details-item d-flex justify-content-between">
                                    <span>Interior Features:</span>
                                    <p
                                        className={clsx({
                                            "property-details-blur": isPlusMinus && blurredBlocks.includes(8),
                                        })}
                                    >
                                        {property.interior_features}
                                    </p>
                                </div>
                                <div className="details-item d-flex justify-content-between">
                                    <span>Heating:</span>
                                    <p
                                        className={clsx({
                                            "property-details-blur": isPlusMinus && blurredBlocks.includes(8),
                                        })}
                                    >
                                        {property.heating}
                                    </p>
                                </div>
                                <div className="details-item d-flex justify-content-between">
                                    <span>AC:</span>
                                    <p
                                        className={clsx({
                                            "property-details-blur": isPlusMinus && blurredBlocks.includes(8),
                                        })}
                                    >
                                        {property.cooling}
                                    </p>
                                </div>
                                <div className="details-item d-flex justify-content-between">
                                    <span>Laundry Features:</span>
                                    <p
                                        className={clsx({
                                            "property-details-blur": isPlusMinus && blurredBlocks.includes(8),
                                        })}
                                    >
                                        <span
                                            className={clsx(isUnknownLaundryFeature && "has-tooltip")}
                                            data-tooltip-id="laundry-features"
                                        >
                                            {property.laundry_features}
                                        </span>
                                    </p>
                                    {isUnknownLaundryFeature && (
                                        <Tooltip
                                            id="laundry-features"
                                            anchorSelect="[data-tooltip-id='laundry-features']"
                                            content={TOOLTIP_TEXT.LAUNDRY_FEATURES}
                                            place="top"
                                        />
                                    )}
                                </div>
                                <div className="details-item d-flex justify-content-between">
                                    <span>Lot Dimensions:</span>
                                    <p
                                        className={clsx({
                                            "property-details-blur": isPlusMinus && blurredBlocks.includes(8),
                                        })}
                                    >
                                        <span
                                            className={clsx(isCommonLotDimension && "has-tooltip")}
                                            data-tooltip-id="lot-dimension"
                                        >
                                            {property.lot_dimensions}
                                        </span>
                                    </p>
                                    {isCommonLotDimension && (
                                        <Tooltip
                                            id="lot-dimension"
                                            anchorSelect="[data-tooltip-id='lot-dimension']"
                                            content={TOOLTIP_TEXT.LOT_DIMENSIONS}
                                            place="top-start"
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                        <SalesInfo property_id={property.id} />
                    </div>
                </div>
            ) : null}
        </div>
    );
};

PropertyInfo.propTypes = {
    property: PropTypes.object.isRequired,
    arrProperties: PropTypes.array,
    showMoreDetails: PropTypes.bool,
    round: PropTypes.number,
    unBlurAll: PropTypes.bool,
};

export default PropertyInfo;
