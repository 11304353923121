import { useCallback } from "react";
import { useModal } from "../context/ModalContext";

// Setting the modal state of global modals
const useModalState = () => {
    const { setModalId, modalId, modalContent, setModalContent, isLoading, setIsLoading } = useModal();

    /**
     * Function to set global modal
     *
     * @param {string} id Id of the global modal to be opened
     * @returns {boolean} returns true/false
     *
     */
    const isOpen = useCallback((id) => id === modalId, [modalId]);

    return { isOpen, setModalId, modalId, modalContent, setModalContent, isLoading, setIsLoading };
};

export default useModalState;
