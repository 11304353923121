import React from "react";
import "./sidemenu.scss";
import { SIDEMENU_ITEMS } from "../../constants/sidemenu-items.constants";
import { filter, isNil } from "lodash";
import PropTypes from "prop-types";
import { useNonInitialEffect } from "../../../hooks/useNonInitialEffect";
import SideMenuItem from "./sidemenu-item/sidemenu-item";
import { useAuth0 } from "@auth0/auth0-react";
import "react-social-icons/facebook";
import "react-social-icons/instagram";
import CustomSvg from "../custom-svg/custom-svg";
import FbIcon from "../../../assets/icons-v2/facebook.png";
import Insta from "../../../assets/icons-v2/insta.png";
import clsx from "clsx";

const Sidemenu = (props) => {
    const { isAuthenticated } = useAuth0();

    const filteredMenuItems = filter(SIDEMENU_ITEMS, (item) =>
        !isNil(item.isRegistered) ? item.isRegistered === isAuthenticated : true
    );

    /**
     * Custom React hook to add an event listener to handle clicks outside the SideMenu component.
     * The event listener will be added only when the menu is open.
     */
    useNonInitialEffect(() => {
        // Add event listener to handle clicks outside the SideMenu component
        const handleOutsideClick = (event) => {
            const sideMenu = document.querySelector(".side-menu");
            const menuIcons = document.querySelector(".menu-icons");

            if (sideMenu && !sideMenu.contains(event.target) && !menuIcons.contains(event.target)) {
                props.onClose();
            }
        };

        if (props.isOpen) {
            document.addEventListener("click", handleOutsideClick);
        }

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [props.isOpen, props.onClose]);

    return (
        <div className={clsx("side-menu", props.isOpen && "open")}>
            <ul className="menu-items">
                {filteredMenuItems.map((item, index) => (
                    <SideMenuItem
                        key={"menu-items" + index}
                        item={item}
                        onProfileToggle={props.onProfileToggle}
                        onClose={props.onClose}
                    />
                ))}
            </ul>
            <div className="follow">
                <center>Follow Us</center>
            </div>
            <div className="social-media-links">
                <center>
                    <CustomSvg
                        src={FbIcon}
                        size={{ height: 35, width: 35 }}
                        onClick={() => window.open("https://www.facebook.com/groups/pricemeapp/", "_blank")}
                    />
                    <CustomSvg
                        src={Insta}
                        size={{ height: 35, width: 35 }}
                        onClick={() => window.open("https://www.instagram.com/pricemeapp/", "_blank")}
                    />
                </center>
            </div>
        </div>
    );
};

Sidemenu.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default Sidemenu;
