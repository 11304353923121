/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import Modal from "react-bootstrap/Modal";
import "./awards-modal.scss";
import useModalState from "../../../hooks/useModalState";
import { useAwards } from "../../../context/AwardsContext";
import AppButton from "../../common/buttons/app-button";
import { IoCloseSharp } from "react-icons/io5";

const AwardsModal = () => {
    const { isOpen, setModalId, modalContent } = useModalState();
    const { handleNextAward } = useAwards();

    // Gets the placement string
    const placement = modalContent.message.split(" ").slice(0, 2).join(" ");
    // Gets the date string
    const date = modalContent.message.split(" ").slice(2, modalContent.message?.length).join(" ");

    return (
        <Modal
            dialogClassName="awards-modal-dialog"
            contentClassName="awards-modal-content"
            className="awards-modal"
            show={isOpen("AWARDS_MODAL")}
            onHide={() => setModalId("")}
            centered
        >
            <a className="close-btn" onClick={handleNextAward}>
                <IoCloseSharp fontSize={24} />
            </a>
            <Modal.Body className="awards-modal-body">
                <h2 className="app-text-title">{modalContent.title}</h2>
                <div className="award-container">
                    <div className="award-icon">
                        <img src={`/icons/awards/${modalContent.icon}`} alt="placement-icon" />
                    </div>
                    <div className="text-center">
                        <h4 className="mb-1">{placement}</h4>
                        <h5 className="mb-0">{date}</h5>
                    </div>
                </div>
                <AppButton className="w-100" variant="solid" theme="purple" onClick={handleNextAward}>
                    {modalContent.ctaTitle}
                </AppButton>
            </Modal.Body>
        </Modal>
    );
};

export default AwardsModal;
