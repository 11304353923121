import { isEmpty, isUndefined } from "lodash";
import PropTypes from "prop-types";
import { RiExternalLinkLine } from "react-icons/ri";
import useGAEvent from "../../../hooks/useGAEvent";

const REALTOR_SEARCH_URL = "https://www.realtor.com/realestateandhomes-search";

const SimilarProperties = ({ property, isAuthenticated }) => {
    const { sendEvent } = useGAEvent();

    /**
     * Generates a URL to search for similar properties based on the provided property object.
     *
     * @param {object} property - The property object containing details of the current property.
     *
     * @returns {string} - The URL for searching similar properties.
     */
    const generateSimilarPropertiesURLQuery = (property) => {
        // Guard clause for empty property
        if (!property) return REALTOR_SEARCH_URL;

        let type = "";

        const zipcode =
            !isUndefined(property?.zip_code) && !isEmpty(property.zip_code) ? `${property.zip_code}/` : "";

        const bedrooms =
            !isUndefined(property?.bedrooms) && !isEmpty(property.bedrooms.toString())
                ? `beds-${property.bedrooms}-${property.bedrooms}/`
                : "";

        const bathrooms =
            !isUndefined(property?.bathrooms) && !isEmpty(property.bathrooms)
                ? `baths-${property.bathrooms}-${property.bathrooms}/`
                : "";

        const priceFrom = parseInt(`${property?.price ?? 0}`) * 0.8;
        const priceTo = parseInt(`${property?.price ?? 0}`) * 1.2;
        const priceRange = `price-${priceFrom}-${priceTo}`;

        if (property?.property_type === "Condominium") {
            type = "type-condo";
        } else if (property?.property_type === "Single Family Residential") {
            type = "type-single-family-home";
        } else {
            type = "type-condo";
        }

        return `${REALTOR_SEARCH_URL}/${zipcode}${type}/${bedrooms}${bathrooms}${priceRange}`;
    };

    /**
     * Handle similar properties button click
     */
    const handleClick = () => {
        sendEvent("similar_property_click", {
            isAuthenticated,
            referralCode: localStorage.getItem("referral_code"),
            mlsId: property.mls_id ?? null,
        });
    };

    return (
        <>
            <a
                id="see-similar-properties-btn"
                onClick={handleClick}
                href={generateSimilarPropertiesURLQuery(property)}
                target="_blank"
                rel="noopener noreferrer"
                className="app-button d-flex justify-content-center align-items-center gap-1 p-0 w-100 py-1 px-4 purple-bordered "
            >
                <span>See similar homes for sale now</span> <RiExternalLinkLine fontSize={18} />
            </a>
        </>
    );
};

SimilarProperties.propTypes = {
    property: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
};

export default SimilarProperties;
