import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { isEmpty } from "lodash";

const backgroundColors = {
    default: "#FFFFFF",
    correct: "#AD6BFF",
    incorrect: "#D9D9D9",
    close: "#FAB85F",
};

const animationVariants = {
    idle: {
        rotateX: 0, // Start with no vertical rotation
    },
    flip: ({ delay, backgroundColor }) => ({
        rotateX: 360, // Rotate vertically halfway to create the "flip" effect
        backgroundColor,
        transition: {
            duration: 0.5,
            ease: [0.4, 0.0, 0.2, 1], // Wordle-like easing
            delay, // Apply the delay dynamically
        },
    }),
    strobe: {
        scale: [1, 1.15, 1],
        transition: "spring",
    },
};

const Tile = ({ mode = "default", value, onClick, animationDelay, onAnimationComplete }) => {
    const animateFlip = mode !== "default" ? "flip" : !isEmpty(value) ? "strobe" : "idle";
    const customProperties = {
        delay: animationDelay,
        backgroundColor: backgroundColors[mode],
    };

    return (
        <motion.div
            initial="idle"
            className="tw-flex tw-h-[30px] tw-w-[30px] tw-justify-center tw-items-center tw-text-2xl tw-font-medium tw-rounded tw-border tw-border-gray-400"
            variants={animationVariants}
            custom={customProperties}
            animate={animateFlip}
            onClick={onClick}
            onAnimationComplete={onAnimationComplete}
        >
            {value ?? ""}
        </motion.div>
    );
};

Tile.propTypes = {
    startAnimation: PropTypes.bool.isRequired,
    animationDelay: PropTypes.number,
    onClick: PropTypes.func,
    mode: PropTypes.string,
    value: PropTypes.string,
};

export default Tile;
