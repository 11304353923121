import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "./bonus-reminder-modal.scss";
import AppButton from "../../common/buttons/app-button";
import Placeholder from "../../../assets/icons-v2/megaphone.png";
import { IoCloseSharp } from "react-icons/io5";
import { triggerShare } from "../../../helpers/triggerShare";
import { useUser } from "../../../context/UserContext";
import config from "../../../config";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import useGAEvent from "../../../hooks/useGAEvent";
import useModalState from "../../../hooks/useModalState";

const VISIBLE_DELAY = 10000;

const BonusReminderModal = ({ showModal, handleClose }) => {
    const { userState } = useUser();
    const { isAuthenticated } = useAuth0();
    const { sendEvent } = useGAEvent();

    const [isVisible, setIsVisible] = useState(false);
    const { isOpen } = useModalState();

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, VISIBLE_DELAY);

        return () => clearTimeout(timer);
    }, []);

    let bonusReminderTitle = "Help spread the word about PriceMe";
    let bonusReminderText = [
        "Tell your friends about the new home price guessing game - it’s more fun with friends! ",
    ];

    /**
     * Handles the "Tell a Friend" button click event, triggering the share functionality.
     *
     * This function generates share data for both web and mobile platforms, including referral information.
     *
     * @async
     * @throws {Error} Throws an error if there is an issue with the share functionality.\
     */
    const handleTellFriendButton = async () => {
        sendEvent("invite_friend", {
            isAuthenticated,
            referral_code: localStorage.getItem("referral_code"),
        });

        await triggerShare({
            web: {
                text: `${config.BASE_URL}/?ref=${userState?.referral_code}`,
            },
            mobile: {
                title: "PriceMe",
                text: "Check out PriceMe! It's a home price guessing game. It shows you homes that recently sold near you, and you guess the price:",
                url: `${config.BASE_URL}/?ref=${userState?.referral_code}`,
            },
        });
    };

    return (
        <Modal
            centered
            show={showModal && isVisible && !isOpen("ADD_TO_HOME_SCREEN_MODAL")}
            onHide={() => handleClose()}
            dialogClassName="bonus-reminder-modal__dialog"
            contentClassName="bonus-reminder-modal__content"
            className="bonus-reminder-modal"
        >
            <span className="close-btn" onClick={() => handleClose()}>
                <IoCloseSharp fontSize={24} />
            </span>
            <Modal.Body className="bonus-reminder-modal__body">
                <h5 className="app-text-title pt-4 bonus-reminder-modal__body--title">
                    {bonusReminderTitle}
                </h5>
                <div className="slide-img-wrapper py-2">
                    <img
                        className="bonus-reminder-modal__body--icon"
                        src={Placeholder}
                        alt="bonus-reminder-icon"
                    />
                </div>
                {bonusReminderText.map((text, index) => (
                    <p
                        key={`bonus-reminder-modal_body:${index}`}
                        className="bonus-reminder-modal__body--text"
                    >
                        {text}
                    </p>
                ))}
            </Modal.Body>
            <Modal.Footer className="bonus-reminder-modal__footer d-flex flex-column gap-2 pt-0 pb-4">
                <AppButton
                    type="button"
                    variant="solid"
                    theme="gold"
                    className="w-75 tell-friend-button"
                    onClick={() => handleTellFriendButton()}
                >
                    Tell a friend!
                </AppButton>

                <AppButton
                    type="button"
                    variant="no-border"
                    theme="purple"
                    className="remind-later-button pb-0"
                    onClick={() => handleClose()}
                >
                    Remind me later
                </AppButton>

                <div className="footer-text">*Limited time offer. While supplies last.</div>
            </Modal.Footer>
        </Modal>
    );
};

BonusReminderModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default BonusReminderModal;
