import React from 'react';
import './data-query.scss';
import PropTypes from 'prop-types';

const DataQuery = ({ data, isLoading, isFetching, error, renderedData }) => {
    if (isLoading || isFetching) {
        return (<div>Loading...</div>);
    }

    if (error) {
        return (<div>Error: {error.message}</div>);
    }

    return (
        <div className="data-query">
            {renderedData(data)}
        </div>
    );
}

DataQuery.propTypes = {
    data: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.object,
    renderedData: PropTypes.func.isRequired,
}

export default DataQuery;