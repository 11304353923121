import "./privacy-policy.scss";

export default function PrivacyPolicy() {
    return (
        <div className="container privacy-policy pb-4">
            <h1 className="px-0 pt-4 app-text-title text-center">Privacy Policy for PriceMe (Jul 2 2024)</h1>
            <p>
                <b>Effective Date: July 1, 2024</b>
            </p>
            <p>
                Welcome to PriceMe. We respect your privacy and want to protect your personal information.
                This Privacy Policy explains how we collect, use, and disclose your personal information.
            </p>

            <h2>Information Collection</h2>

            <h3>1. Personal Data</h3>
            <p>
                When you register for our service, we may ask you to provide certain personal information such
                as your nickname, full name, and email address.
            </p>

            <h3>2. Usage Data</h3>
            <p>
                We also collect information about how you use our service. This includes data about your
                gameplay like guess history, scores, dates played, etc.
            </p>

            <h3>3. Local Storage</h3>
            <p>We may use local storage to track your usage information if you haven't registered yet.</p>

            <h3>4. Third-Party Services</h3>
            <p>
                We use third-party services to help understand how our visitors use the site and provide
                additional services to our users. These services may collect or store information such as your
                name, nickname, email address, IP address, browser type, pages visited, and other usage data.
                For example, we use Google Analytics, Mailjet, Auth0, and Google Cloud services.
            </p>
            <p>
                Please note that we do not control the privacy practices of these third-party services. We
                encourage you to review their respective privacy policies to understand how they handle your
                information.
            </p>

            <h2>Use of Your Information</h2>
            <h3>1. We use the information we collect from you to:</h3>
            <ul>
                <li>Provide, maintain, and improve our service</li>
                <li>Personalize your experience</li>
                <li>Communicate with you, including responding to your comments, questions, and requests</li>
                <li>Conduct research and analysis</li>
            </ul>

            <h3>2. Information Sharing and Disclosure</h3>
            <p>
                We do not share your personal information with third parties without your consent, except as
                described in this policy or when we believe in good faith that the disclosure is necessary to:
            </p>
            <ul>
                <li>Protect our rights</li>
                <li>Protect your safety or the safety of others</li>
                <li>Comply with the law</li>
            </ul>

            <h3>3. Security</h3>
            <p>
                We implement a variety of security measures to maintain the safety of your personal
                information. Your data is protected by secure networks and is only accessible by a limited
                number of persons who have special access rights to such systems and are required to keep the
                information confidential.
            </p>

            <h3>4. Your Rights</h3>
            <p>
                Depending on your location, you may have the following rights regarding your personal
                information:
            </p>
            <ul>
                <li>Access your personal data</li>
                <li>Request correction of inaccurate data</li>
                <li>Request deletion of your data</li>
                <li>Object to or restrict processing of your data</li>
                <li>Withdraw consent at any time</li>
            </ul>

            <h3>5. Children's Privacy</h3>
            <p>
                Our service is not directed to individuals under the age of 13. We do not knowingly collect
                personal information from children under 13. If we become aware that a child under 13 has
                provided us with personal information, we will take steps to delete such information.
            </p>

            <h3>6. Changes to This Policy</h3>
            <p>
                We may update this Privacy Policy from time to time. If we make any changes, we will notify
                you by revising the "Effective Date" at the top of this Privacy Policy. We encourage you to
                review this Privacy Policy periodically to stay informed about our practices.
            </p>

            <h2>Contact Us</h2>
            <p>
                If you have any questions or concerns about this Privacy Policy or our practices, please
                contact us at <a href="mailto:admin@priceme.game">admin@priceme.game</a>.
            </p>
        </div>
    );
}
