/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
import { X } from "react-bootstrap-icons";
import "./header.scss";
import Sidemenu from "../sidemenu/sidemenu";
import ProfileDrawer from "../profile-drawer/profile-drawer";
import { useAuth0 } from "@auth0/auth0-react";
import { isBoolean, get, isEqual } from "lodash";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../assets/icons/WePrice-Secondaryicon-w.png";
import useCustomParamTracker from "../../../hooks/useCustomParamTracker";
import toast from "react-hot-toast";
import { TOAST_MESSAGES } from "../../../lang/toast-messages.lang";
import clsx from "clsx";
import UserAvatar from "../UserAvatar";
import { useUser } from "../../../context/UserContext";

const Header = (props) => {
    const location = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
    const { trackCustomParam } = useCustomParamTracker();
    const { userState, setUserState } = useUser();
    const defaultActiveIndex = { tab: 0, button: 0 };
    const activeIndex = get(userState, "leaderboard_active_tab", defaultActiveIndex);

    // Use the useCustomParamTracker hook to trigger the handleShowProfileFlag function
    useEffect(() => {
        trackCustomParam("show_profile", handleShowProfileFlag);
    }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    // Since header will always be mounted, we will check router changes and do the necessary updates
    useEffect(() => {
        if (
            !isLoading &&
            isAuthenticated &&
            location.pathname !== "/leaderboard" &&
            !isEqual(activeIndex, defaultActiveIndex)
        ) {
            setUserState({
                ...userState,
                leaderboard_active_tab: { tab: 0, button: 0 },
            });
        }
    }, [location, isLoading, userState]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleMenuToggle = () => {
        setIsMenuOpen((prevState) => !prevState);
    };

    /**
     * Toggles the visibility of the user profile, closing the menu if open.
     *
     * @param {boolean|null|object} profileFlag - The desired visibility state of the user profile. If provided, sets the profile visibility accordingly.
     */
    const handleProfileToggle = (profileFlag = null) => {
        if (isMenuOpen) {
            setIsMenuOpen(false);
        }

        if (isBoolean(profileFlag)) {
            setIsProfileOpen(profileFlag);
        } else {
            setIsProfileOpen((prev) => !prev);
        }
    };

    /**
     * Handles the action of displaying the user profile after a short delay, triggering a login for guests if necessary.
     *
     * @throws {Error} Throws an error if the login redirection fails.
     *
     * @returns {void}
     */
    const handleShowProfileFlag = () => {
        if (!isAuthenticated) {
            toast.error(TOAST_MESSAGES.GUEST_PROFILE_ACCESS, { id: "GUEST_PROFILE_ACCESS" });

            setTimeout(
                () =>
                    loginWithRedirect({
                        redirectUri: `${window.location.href}`,
                        appState: {
                            toggleProfile: true,
                        },
                    }),
                2000
            );
        } else {
            setTimeout(() => handleProfileToggle(true), 500);
        }
    };

    return (
        <>
            <header>
                <div className="header-container">
                    <div className="menu-icons" data-cy="menu-icons">
                        <X
                            id="menu-icon"
                            className={clsx("x-icon", !isMenuOpen && "hidden")}
                            size={30}
                            onClick={handleMenuToggle}
                        />
                        <MenuIcon
                            className={clsx("list-icon", isMenuOpen && "d-none")}
                            onClick={handleMenuToggle}
                        />
                    </div>

                    <div className="title">
                        <Link to={"/"} reloadDocument className="text-decoration-none logo-link">
                            <img className="priceme-logo" src={logo} alt="priceme-logo" />
                            <span>PriceMe</span>
                        </Link>
                    </div>

                    <UserAvatar onClickProfile={() => setIsProfileOpen((prev) => !prev)} />
                </div>
                <Sidemenu
                    onProfileToggle={handleProfileToggle}
                    isOpen={isMenuOpen}
                    onClose={handleMenuToggle}
                />
            </header>
            <ProfileDrawer isOpen={isProfileOpen} onClose={handleProfileToggle} />
        </>
    );
};

function MenuIcon({ width = 18, height = 16, className, onClick, id }) {
    return (
        <div className={className} onClick={onClick} id="menu-icon">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 18 16"
            >
                <path stroke="#fff" strokeLinecap="round" strokeWidth="1.2" d="M1 1h12M1 8h16M1 15h12"></path>
            </svg>
        </div>
    );
}

export default Header;
