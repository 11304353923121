import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const useCustomLoginWithRedirect = () => {
    const value = localStorage.getItem("referral_code");
    const [referralCode, setReferralCode] = React.useState(value);
    const { loginWithRedirect } = useAuth0();

    /**
     * Customized login function that redirects to the authentication provider.
     *
     * @param {Object} props - The login configuration and options. { appState, authorizationParams, fragment, openUrl }
     * @returns {void} - Redirects the user to the authentication provider.
     */
    const customLoginWithRedirect = (props) => {
        let customProps = props;

        // Guard clause for empty props
        if (!props) return loginWithRedirect();

        // Check if referral code exists before overriding authorizationParams
        if (referralCode) {
            // If auth params exists, override with ref code else create auth params property
            if (props?.authorizationParams) {
                customProps = {
                    ...props,
                    authorizationParams: { ...props.authorizationParams, referralCode },
                };
            } else {
                customProps = {
                    ...props,
                    authorizationParams: { referralCode },
                };
            }
        }

        return loginWithRedirect(customProps);
    };

    /**
     * Listen for local storage changes for referral code and set to state
     */
    React.useEffect(() => {
        /**
         * Handles setting of referral code to state from local storage
         */
        const handleStorage = () => {
            const refreshedRefCode = localStorage.getItem("referral_code");
            setReferralCode(refreshedRefCode);
        };

        window.addEventListener("storage", handleStorage());
        return () => window.removeEventListener("storage", handleStorage());
    }, []);

    return {
        customLoginWithRedirect,
    };
};

export default useCustomLoginWithRedirect;
