import React from "react";
import "./custom-button.scss";
import PropTypes from "prop-types";
import Loader from "../../loader/loader";
import { isNil } from "lodash";

const CustomButton = ({
    className,
    isLoading,
    id,
    dataCy,
    isDisabled,
    text,
    handleClick,
    rightIcon,
    leftIcon,
}) => {
    return (
        <>
            <button
                className={className ?? ""}
                onClick={handleClick}
                id={id}
                data-cy={dataCy}
                disabled={isLoading ? true : isDisabled}
            >
                {!isNil(leftIcon) ? leftIcon : null}
                {isLoading ? <Loader isInline size={15} loading={isLoading} /> : text}
                {!isNil(rightIcon) ? rightIcon : null}
            </button>
        </>
    );
};

CustomButton.propTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
    dataCy: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    handleClick: PropTypes.func,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    leftIcon: PropTypes.element,
    rightIcon: PropTypes.element,
};

export default CustomButton;
