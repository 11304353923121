import { useState } from "react";

// Custom hook to get and set data in localStorage
const useLocalStorage = (key, initialValue) => {
    // Retrieve the value from localStorage on initial load
    const storedValue = localStorage.getItem(key);
    const initial = storedValue ? JSON.parse(storedValue) : initialValue;

    // Create a state variable to hold the current value
    const [value, setValue] = useState(initial);

    // Function to update the value in state and localStorage
    const updateValue = (newValue) => {
        setValue(newValue);
        localStorage.setItem(key, JSON.stringify(newValue));
    };

    // Function to clear the value from state and localStorage
    const clearValue = () => {
        localStorage.removeItem(key);
    };

    return [value, updateValue, clearValue];
};

export default useLocalStorage;
