import React, { Fragment } from "react";
import { SECTIONS } from "../../../constants/about-priceme-values";
import "./about-priceme.scss";

const AboutPriceMe = () => {
    return (
        <div className="about-priceme-section mb-4">
            <div className="about-priceme-container pt-4 pb-2 px-3">
                {SECTIONS.map((section, sectionIndex) => (
                    <Fragment key={`section-${sectionIndex}`}>
                        {section.header && <h3 className="app-text-title text-left mb-2 fw-bold">{section.header}</h3>}
                        {section.sub_header && <h4>{section.sub_header}</h4>}
                        {section.paragraphs && section.paragraphs.map((paragraph, pIndex) => <p key={`key-${pIndex}`}>{paragraph}</p>)}
                        {section.custom_paragraph && (
                            <div dangerouslySetInnerHTML={{ __html: section.custom_paragraph }} />
                        )}
                        {section.ordered_list && (
                            <ol>
                                {section.ordered_list.map((list, listIndex) => <li key={`ordered-list-${listIndex}`}>{list}</li>)}
                                {section.nested_list && (
                                    <ol className="nested-list">
                                        {section.nested_list.map((nestedList, nestedListIndex) => <li key={`nested-list-${nestedListIndex}`}>{nestedList}</li>)}
                                    </ol>
                                )}
                            </ol>
                        )}
                        {section.paragraph && <p>{section.paragraph}</p>}
                        {section.unordered_list && <ul>{section.unordered_list.map((list, listIndex) => <li key={`unordered-list-${listIndex}`}>{list}</li>)}</ul>}
                        <br />
                    </Fragment>
                ))}
            </div>
        </div>
    );
};

export default AboutPriceMe;
