import axios from "axios";

/**
 * Creates a referred user instance.
 *
 * @async
 * @function createReferredUser
 * @param {string} params.userEmail - The email of the user to be referred.
 * @param {string} params.referralId - The ID of the referral.
 * @returns {Promise<Object>} The response data from the server.
 * @throws {Error} Throws an error if the request fails.
 */
export const createReferredUser = async ({ userEmail, referralId }) => {
    return await axios.post(`/api/referrals/${referralId}/users`, { referredUserEmail: userEmail });
};

/**
 * Retrieves referral bonus information associated with the specified user ID.
 * @param {Object} options - An object containing options.
 * @param {string} options.userId - The ID of the user.
 * @returns {Promise} A promise that resolves with the referral bonus information.
 */
export const getReferralBonus = async ({ userId, date = null }) => {
    return await axios.get(`/api/properties/bonus/users/${userId}`, {
        params: {
            date,
        },
    });
};

/**
 * Retrieves referred users associated with the specified user ID.
 * @param {Object} options - An object containing options.
 * @param {string} options.userId - The ID of the user.
 * @returns {Promise} A promise that resolves with the referred users information.
 */
export const getReferredUsers = async ({ userId }) => {
    return await axios.get(`/api/referrals/user-referrals/${userId}`);
};

/**
 * Updates the viewed status for referred users associated with the specified user ID.
 * @param {Object} options - An object containing options.
 * @param {string} options.userId - The ID of the user.
 * @param {Array} options.referralIds - An array containing the IDs of the referred users to update.
 * @returns {Promise} A promise that resolves when the referred users are successfully updated.
 */
export const updateReferredUsers = async ({ userId, referralIds }) => {
    return await axios.put(`/api/referrals/user-referrals/${userId}`, { referralIds });
};
