import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Modal } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import useCustomLoginWithRedirect from "../../../hooks/useCustomLoginWithRedirect";
import useModalState from "../../../hooks/useModalState";
import { useChallenge } from "../../../context/ChallengeContext";
import ChallengedModalBody from "./components/ChallengedModalBody";
import "./challenged-modal.scss";

/**
 * The ChallengedModal component displays a modal with challenge details.
 * It provides options for accepting the challenge or registering to participate.
 *
 * @component
 * @returns {JSX.Element} The rendered modal component.
 */
export default function ChallengedModal() {
    const { isAuthenticated } = useAuth0();
    const { setModalId, isOpen } = useModalState();
    const { customLoginWithRedirect } = useCustomLoginWithRedirect();
    const { challengeProperties, challengeId } = useChallenge();
    const navigate = useNavigate();

    /**
     * Handles the closing of the challenged modal.
     */
    const handleChallengedModalClose = () => {
        setModalId("");
        navigate("/");
    };

    /**
     * Handles the click on the register link, initiating the registration process.
     * This function uses custom login with redirect to start the registration process.
     *
     * @returns {void}
     */
    const handleRegisterLinkClick = () =>
        customLoginWithRedirect({
            authorizationParams: {
                redirect_uri: `${window.location.origin}/?challenge_id=${challengeId}`,
            },
            appState: {
                toggleProfile: true,
                event: {
                    name: "challenged_modal_register_click",
                    referral_code: localStorage.getItem("referral_code"),
                },
            },
        });

    return (
        <Modal
            show={isOpen("CHALLENGED_MODAL")}
            onHide={handleChallengedModalClose}
            centered
            keyboard={false}
            backdrop="static"
            data-cy="challenged-modal"
            fullscreen="sm-down"
            className={clsx("challenged-modal", !isAuthenticated && "guest-modal")}
        >
            <ChallengedModalBody
                challengeData={challengeProperties}
                challengeId={challengeId}
                isAuthenticated={isAuthenticated}
                handleRegisterLinkClick={handleRegisterLinkClick}
            />
        </Modal>
    );
}

/**
 * PropTypes for the ChallengedModal component.
 */
ChallengedModal.propTypes = {
    isAuthenticated: PropTypes.bool,
    handleRegisterLinkClick: PropTypes.func,
    challengeData: PropTypes.object,
    challengeId: PropTypes.string,
};
