import React, { createContext, useContext } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { useUser } from "./UserContext";
import { filter } from "lodash";
import { getGuestPlaysToday as helperGuestPlayTodays, getStartAndEndOfWeek } from "../helpers/helpers";
import { isEmpty } from "lodash";
import { isNull } from "lodash";
import { isNil } from "lodash";

const GuestContext = createContext();

export const GuestProvider = ({ children }) => {
    const [guestPlay, setGuestPlay] = useLocalStorage("guest_gameplay");
    const { userState } = useUser();

    /**
     * Get the guest plays that occurred today for the current user.
     *
     * This function filters the guest play data to find plays that occurred today
     * in the same area as the current user.
     *
     * @returns {Array} An array of guest play records that occurred today in the user's current area.
     */
    const getGuestPlaysToday = () => {
        return helperGuestPlayTodays(guestPlay, userState);
    };

    /**
     * Get the guest plays that occurred within the current week for the user's current area.
     *
     * This function filters the guest play data to find plays that occurred within the current week
     * and in the same area as the current user.
     *
     * @param {Date} [date=new Date()] - The date to use for calculating the week. Defaults to the current date.
     * @param {("local"|"regional"|"all")} [areaScope="local"] - The area scope to filter guest plays. Defaults to "local".
     *
     * @returns {Array} An array of guest play records that occurred within the current week in the user's current area.
     */
    const getGuestPlaysWeek = (date = new Date(), areaScope = "local") => {
        const currentDate = new Date(date);

        return filter(guestPlay, (gameplay) => {
            const gameplayDate = new Date(gameplay.created_at);
            const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(gameplayDate.toLocaleDateString());
            const isWithinWeek = currentDate >= new Date(startOfWeek) && currentDate <= new Date(endOfWeek);

            // The default filter should be 'local', the gameplay area is equal to the user's current area
            let isSameArea = gameplay.area_id === userState?.current_area_id;

            // TODO: Add filter for regional areas

            // If the filter is 'all', just make sure that the gameplay has an area
            if (areaScope === "all") {
                isSameArea =
                    !isEmpty(gameplay.area_id) && !isNil(gameplay?.area_id) && !isNull(gameplay.area_id);
            }

            return isWithinWeek && isSameArea;
        });
    };

    /**
     * Calculates the total score from guest plays for the current day in a specified area scope.
     *
     * @returns {number} - The total guest play score for the day in the specified area scope.
     */
    const totalGuestPlayScoreDaily = () =>
        getGuestPlaysToday()
            ?.map((guest) => guest.score)
            ?.reduce((prev, curr) => prev + curr, 0);

    /**
     * Calculates the total score from guest plays for the current week in a specified area scope.
     *
     * @param {Date} [date] - The area scope for which to calculate the total guest play score.
     * @param {string} [areaScope="local"] - The area scope for which to calculate the total guest play score.
     *
     * @returns {number} - The total guest play score for the week in the specified area scope.
     */
    const totalGuestPlayScoreWeek = (date, areaScope = "local") =>
        getGuestPlaysWeek(date, areaScope)
            ?.map((guest) => guest.score)
            ?.reduce((prev, curr) => prev + curr, 0);

    return (
        <GuestContext.Provider
            value={{
                guestPlay,
                setGuestPlay,
                getGuestPlaysToday,
                getGuestPlaysWeek,
                totalGuestPlayScoreDaily,
                totalGuestPlayScoreWeek,
            }}
        >
            {children}
        </GuestContext.Provider>
    );
};

export const useGuestContext = () => useContext(GuestContext);
